import { createSlice } from "@reduxjs/toolkit";
import { GameFeeds } from "@platform/stream-client";

export interface IFeedDownState
{
    live: {
        br: boolean;
        ls: boolean;
        dc: boolean;
    };
    prematch: {
        br: boolean;
        ls: boolean;
        dc: boolean;
    };
    premiumCricket: boolean;
    betPal: boolean;
}

const initialState: IFeedDownState = {
    live: {
        br: false,
        ls: false,
        dc: false
    },
    prematch: {
        br: false,
        ls: false,
        dc: false
    },
    premiumCricket: false,
    betPal: false
};

export const feedDownSlice = createSlice({
    name: "feedDown",
    initialState,
    reducers: {
        initFeedDown: (state, action) =>
        {
            state.live = action.payload.live;
            state.prematch = action.payload.prematch;
            state.premiumCricket = action.payload.premiumCricket;
            state.betPal = action.payload.betPal;
        },
        setFeedDown: (state, action) =>
        {
            switch (action.payload.producer)
            {
                case GameFeeds.LIVE:
                    state.live[action.payload.source] = action.payload.is_down;
                    break;
                case GameFeeds.PREMATCH:
                    state.prematch[action.payload.source] = action.payload.is_down;
                    break;
                case "premium_cricket":
                    state.premiumCricket = action.payload.is_down;
                    break;
                case "bet_pal":
                    state.betPal = action.payload.is_down;
                    break;
            }
        },
    }
});

export const { initFeedDown, setFeedDown } = feedDownSlice.actions;

export default feedDownSlice.reducer;
