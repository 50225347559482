import { createSlice } from "@reduxjs/toolkit";
import { IBetTicketData } from "../../../typings/my-bets/IBetTicketData";

export interface IPrintTicketState
{
    type: string;
    ticket: IBetTicketData;
}

const initialState: IPrintTicketState = {
    type: "",
    ticket: null
};

export const printTicketSlice = createSlice({
    name: "printTicket",
    initialState,
    reducers: {
        setTicket: (state, action) =>
        {
            state.type = action.payload.type;
            state.ticket = action.payload.ticket;
        }
    }
});

export const {
    setTicket
} = printTicketSlice.actions;

export default printTicketSlice.reducer;
