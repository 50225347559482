import { createSlice } from "@reduxjs/toolkit";
import { clone, findIndex } from "lodash-es";
import RegionData from "../../data/sport/RegionData";
import TournamentData from "../../data/sport/TournamentData";
import itemIndexInOrderHelper from "../../utils/helpers/ItemIndexInOrderHelper";

interface IPrematchCurrentSportState
{
    regions: RegionData[];
}

const initialState: IPrematchCurrentSportState = {
    regions: null
};

export const prematchCurrentSportSlice = createSlice({
    name: "prematchCurrentSport",
    initialState,
    reducers: {
        setPrematchSportRegions: (state, action) =>
        {
            state.regions = action.payload;
        },
        updatePrematchSportTournament: (state, action) =>
        {
            const regionIndex = findIndex(state.regions, { id: action.payload.region.id });

            if (regionIndex === -1)
            {
                const regionData = new RegionData(action.payload.region);
                regionData.sport = action.payload.sport;
                regionData.gamesCount = action.payload.game;
                const tournamentData = new TournamentData(action.payload.tournament);
                tournamentData.sport = action.payload.sport;
                tournamentData.region = action.payload.region;
                tournamentData.gamesCount = action.payload.game;

                regionData.pushTournament(tournamentData);

                state.regions.splice(itemIndexInOrderHelper(regionData.order, state.regions), 0, regionData);
            }
            else
            {
                const regionData = state.regions[regionIndex];
                const tournamentIndex = findIndex(regionData.tournaments, { id: action.payload.tournament.id });

                if (tournamentIndex === -1)
                {
                    if (action.payload.game !== 0)
                    {
                        const tournamentData = new TournamentData(action.payload.tournament);
                        tournamentData.sport = action.payload.sport;
                        tournamentData.region = action.payload.region;
                        tournamentData.gamesCount = action.payload.game;

                        regionData.tournaments.splice(itemIndexInOrderHelper(tournamentData.order, regionData.tournaments), 0, tournamentData);
                        regionData.gamesCount += action.payload.game;

                        state.regions[regionIndex] = clone(regionData);
                    }
                }
                else
                {
                    if (action.payload.game === 0)
                    {
                        state.regions[regionIndex].tournaments.splice(tournamentIndex, 1);

                        if (state.regions[regionIndex].tournaments.length === 0)
                        {
                            state.regions.splice(regionIndex, 1);
                        }
                    }
                    else
                    {
                        const gamesCountDiff: number = action.payload.game - regionData.tournaments[tournamentIndex].gamesCount;
                        regionData.gamesCount = regionData.gamesCount + gamesCountDiff;
                        regionData.tournaments[tournamentIndex].gamesCount = action.payload.game;

                        state.regions[regionIndex] = regionData;
                    }
                }
            }
        }
    }
});

export const {
    setPrematchSportRegions,
    updatePrematchSportTournament
} = prematchCurrentSportSlice.actions;

export default prematchCurrentSportSlice.reducer;
