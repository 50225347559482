import axios from "axios";
import { DOMAIN, HOST } from "../../../utils/constants/Config";

const urlPrefix: string = `${DOMAIN}/${HOST}/bonus/info`;

export function getExpressBonus(siteId: string)
{
    return axios.get(`${urlPrefix}/express?site_id=${siteId}`).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}

export function getCashoutConfig(siteId: string)
{
    return axios.get(`${urlPrefix}/cashout?site_id=${siteId}`).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}

export function getUserBonuses(reqObj: {})
{
    return axios.post(`${DOMAIN}/${HOST}/bonus/api/v1/user_bonus/external`, reqObj, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": SportWidgetOptions.token
        }
    }).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}

export function updateUserBonus(bonusId: string, data: {})
{
    return axios.put(`${DOMAIN}/${HOST}/bonus/api/v1/user_bonus/external/${bonusId}`, data, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": SportWidgetOptions.token
        }
    }).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}