import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";

import { findIndex, get } from "lodash-es";
import { getCurrencyConfig, getSiteConfig, getSportConfig } from "../services/rest/config/ConfigService";
import { BetTypes } from "@platform/stream-client";
import { useAppSelector } from "../redux/hooks";
import { getUserConfig } from "../services/rest/user/UserService";
import NotificationService from "../services/notification/NotificationService";
import { NotificationServiceEvent } from "../services/notification/events/NotificationServiceEvent";
import {
    RequestCommandEvent,
    RequestParam,
    UserRequest,
    UserRequestFields
} from "@platform/notification-client";
import { getCashoutConfig, getUserBonuses } from "../services/rest/bonus/BonusService";
import { CashoutCompensationTypes, CashoutLadderTypes } from "../utils/static/CashoutTypes";
import { BonusRequest } from "@platform/notification-client";
import Loader from "../components/base/loader/mine/Loader";
import TranslationEnum from "../utils/translate/TranslationEnum";
import { LocaleContext } from "./LocaleContext";

export interface IBetConfig
{
    bet_max: boolean;
    bet_order: boolean;
    max_bet: number;
    max_odd: number;
    max_sum_bet: number;
    max_win: number;
    min_bet: number;
    favorite_amounts: string[];
}

export interface ISportConfig
{
    bet?: { [type: string]: IBetConfig };
    bet_delay?: number;
    cashout_delay?: number;
    bet_types?: {
        default: BetTypes;
        supported: BetTypes[];
    };
}

export interface ICurrencyFormat
{
    code: string;
    decimal_digits: number;
    decimal_separator: string;
    name: string;
    name_plural: string;
    rounding: number;
    symbol: string;
    symbol_native: string;
    thousands_separator: string;
}

export interface ISiteConfig
{
    currency?: string[];
    features?: string[];
    source?: string;
    sport?: ISportConfig;
}

export interface ICashoutLadder
{
    factor: number;
    deduction: number;
}

export interface ICashoutConfig
{
    active?: boolean;
    compensation?: CashoutCompensationTypes;
    compensation_percent?: number;
    created_at?: number;
    ignore_prematch?: boolean;
    ladder?: CashoutLadderTypes;
    site_id?: string;
    _id?: string;
}

export interface IUserConfig
{
    user_id?: string;
    limit?: {
        bet_max: boolean;
        bet_order: boolean;
        cashout_delay: number;
        bet_delay: number;
        max_bet: number;
        max_odd: number;
        max_sum_bet: number;
        max_win: number;
        min_bet: number;
    };
}

export interface IBonusConditions
{
    bet_types: string[];
    closed_amount: number;
    feeds: string[];
    markets: any[];
    markets_included: boolean;
    max_odd: number;
    min_odd: number;
    max_payout: number;
    min_selection: number;
    min_stake: number;
    sports: any[];
    sports_included: boolean;
    tournaments: any[];
    tournaments_included: boolean;
    events: any[];
    events_included: boolean;
    wager?: number;
}

export interface IUserBonusConfig
{
    alias: string;
    amount: number;
    bonus_amount: number;
    bonus_id: string;
    claimed_at: number;
    created_at: number;
    closed_at: number;
    currency: string;
    deposit_amount: number;
    expired_at: number;
    init_amount: number;
    min_amount: number;
    name: Record<string, string>;
    needed_amount: number;
    payout_amount: number;
    site_id: string;
    status: string;
    type: string;
    user_id: string;
    username: string;
    wager_report: {
        payout: {
            amount: number;
            count: number;
        };
        placed: {
            amount: number;
            count: number;
        }
        settled: {
            amount: number;
            count: number;
        }
    };
    wagering_period: number;
    _id: string;
    free_bet: IBonusConditions;
    sport_bonus: IBonusConditions;
}

export interface ICashoutLadderList
{
    low?: ICashoutLadder[];
    mid?: ICashoutLadder[];
    high?: ICashoutLadder[];
}

export interface IConfigContext
{
    siteConfig: ISiteConfig;
    userConfig: IUserConfig;
    userBonuses: IUserBonusConfig[];
    cashoutConfig: ICashoutConfig;
    currenciesConfig: Record<string, ICurrencyFormat>;
    currentCurrencyConfig: ICurrencyFormat;
    cashoutLadder: ICashoutLadderList;
    changeBonusAmount: (bonusId: string, amount: number) => void;
}

export interface IConfigProvider
{
    children?: ReactNode;
}

export interface ICurrencyConfig
{
    code: string;
    name: string;
    name_plural: string;
    symbol: string;
    symbol_native: string;
    decimal_digits: number;
    rounding: number;
    decimal_separator: string;
    thousands_separator: string;
}

export const ConfigContext = createContext<IConfigContext>(null);

export const ConfigProvider: React.FC<IConfigProvider> = ({ children }): React.ReactElement =>
{
    const userId = useAppSelector(state => state.sportOptions.userId);
    const token = useAppSelector(state => state.sportOptions.token);
    const currency = useAppSelector(state => state.sportOptions.currency);
    const { translations } = useContext(LocaleContext);
    const [lightConfigReady, setLightConfigReady] = useState(false);
    const [siteConfig, setSiteConfig] = useState<ISiteConfig>({});
    const [userConfig, setUserConfig] = useState({});
    const [userBonuses, setUserBonuses] = useState([]);
    const [cashoutConfig, setCashoutConfig] = useState();
    const [cashoutLadder, setCashoutLadder] = useState<ICashoutLadderList>();
    const [userBonusesLoaded, setUserBonusesLoaded] = useState(false);
    const [currenciesConfig, setCurrenciesConfig] = useState<Record<string, ICurrencyFormat>>(null);
    const [currentCurrencyConfig, setCurrentCurrencyConfig] = useState<ICurrencyFormat>(null);
    const userCommandRef = useRef<UserRequest>();
    const bonusesCommandRef = useRef<BonusRequest>();

    const onUserCommandUpdateHandler = useCallback((event) =>
    {
        if (!event.fullUpdate)
        {
            if (userId)
            {
                getUserConfig(SportWidgetOptions.externalSiteId, userId).then(resp =>
                {
                    for (let i: number = 0; i < resp.data.length; ++i)
                    {
                        if (resp.data[i].type === "sportsbook")
                        {
                            setUserConfig(resp.data[i]);
                            break;
                        }
                    }
                }).catch(error =>
                {
                    console.log("error", error);
                });
            }
        }
    }, [userId]);

    const onBonusCommandUpdateHandler = useCallback((event) =>
    {
        if (!event.fullUpdate)
        {
            if (userId)
            {
                getUserBonuses({
                    status: ["open", "active", "pre-active"],
                    sort: "-_id",
                    site_id: SportWidgetOptions.externalSiteId,
                    user_id: SportWidgetOptions.userId,
                    currency: SportWidgetOptions.currency
                }).then(resp =>
                {
                    setUserBonuses(resp.data);
                }).catch(error =>
                {
                    console.log("error", error);
                });
            }
        }
    }, [userId]);

    const sendUserRequest = useCallback(() =>
    {
        if (userId)
        {
            userCommandRef.current = new UserRequest();
            userCommandRef.current.addParam(new RequestParam(UserRequestFields.USER_ID, `${SportWidgetOptions.externalSiteId}-${userId}`));
            userCommandRef.current.addEventListener(RequestCommandEvent.DATA, onUserCommandUpdateHandler);
            NotificationService.getInstance().sendRequest(userCommandRef.current);
        }
    }, [userId]);

    const sendBonusRequest = useCallback(() =>
    {
        if (userId)
        {
            bonusesCommandRef.current = new BonusRequest(SportWidgetOptions.externalSiteId, `${SportWidgetOptions.externalSiteId}-${userId}`);
            bonusesCommandRef.current.addEventListener(RequestCommandEvent.DATA, onBonusCommandUpdateHandler);
            NotificationService.getInstance().sendRequest(bonusesCommandRef.current);
        }
    }, [userId]);

    const notificationServerReady = useCallback(() =>
    {
        NotificationService.getInstance().removeEventListener(NotificationServiceEvent.READY, notificationServerReady);
        sendUserRequest();
        sendBonusRequest();
    }, [userId]);

    const changeBonusAmount: (bonusId: string, amount: number) => void = (bonusId: string, amount: number) =>
    {
        const bonusIndex: number = findIndex(userBonuses, { _id: bonusId });

        if (bonusIndex !== -1)
        {
            setUserBonuses([...userBonuses.slice(0, bonusIndex), {
                ...userBonuses[bonusIndex],
                amount: userBonuses[bonusIndex].amount - amount
            }, ...userBonuses.slice(bonusIndex + 1)]);
        }
    };

    useEffect(() =>
    {
        if (SportWidgetOptions.externalSiteId)
        {
            getSiteConfig(SportWidgetOptions.externalSiteId).then(resp =>
            {
                const getTypeOfLayoutOption: string = localStorage.getItem("layoutOption");
                const config: ISiteConfig = {
                    features: resp.data.features,
                    source: resp.data.source,
                    sport: {}
                };

                // StreamService.getInstance().setDataSource(resp.data.source);
                setLightConfigReady(true);

                if (resp.data.currency)
                {
                    getCurrencyConfig(resp.data.currency.join(",")).then(resp =>
                    {
                        const currencies: Record<string, ICurrencyFormat> = {};

                        for (const format of resp.data)
                        {
                            currencies[format.code] = format;
                        }

                        setCurrenciesConfig(currencies);
                    }).catch(error =>
                    {
                        setCurrenciesConfig(null);
                        console.log("error", error);
                    });
                }

                if (resp.data.features?.indexOf("stats-score") !== -1)
                {
                    const statScoreScript = function ()
                    {
                        const d = "STATSCOREWidgetsEmbederScript";
                        if (!window.document.getElementById(d))
                        {
                            window.STATSCOREWidgets = {};
                            window.STATSCOREWidgets.onLoadCallbacks = [];
                            window.STATSCOREWidgets.onLoad = function (d)
                            {
                                window.STATSCOREWidgets.onLoadCallbacks.push(d);
                            };
                            var n = window.document.createElement("script");
                            n.src = "https://wgt-s3-cdn.statscore.com/bundle/Embeder.js";
                            n.async = !0;
                            n.id = d;
                            n.addEventListener("error", function (d)
                            {
                                for (var n = 0; n < window.STATSCOREWidgets.onLoadCallbacks.length; n++) window.STATSCOREWidgets.onLoadCallbacks[n](d);
                            });
                            window.document.body.appendChild(n);
                        }
                    };

                    statScoreScript();
                }

                if (resp.data.features?.indexOf("cashout") !== -1)
                {
                    getCashoutConfig(SportWidgetOptions.externalSiteId).then(resp =>
                    {
                        if (resp.data[0])
                        {
                            setCashoutConfig(resp.data[0]);

                            setCashoutLadder({
                                low: [
                                    { factor: 0.0000001, deduction: 1.05 },
                                    { factor: 0.0001, deduction: 1.05 },
                                    { factor: 0.001, deduction: 1.05 },
                                    { factor: 0.01, deduction: 1.05 },
                                    { factor: 0.02, deduction: 1.05 },
                                    { factor: 0.05, deduction: 1.05 },
                                    { factor: 0.1, deduction: 1.05 },
                                    { factor: 0.2, deduction: 1.05 },
                                    { factor: 0.5, deduction: 1.025 },
                                    { factor: 0.7, deduction: 1.015 },
                                    { factor: 0.8, deduction: 1.005 },
                                    { factor: 1, deduction: 1.005 },
                                    { factor: 1.2, deduction: 1.015 },
                                    { factor: 1.5, deduction: 1.025 },
                                    { factor: 2, deduction: 1.05 },
                                    { factor: 5, deduction: 1.075 },
                                    { factor: 10, deduction: 1.125 },
                                    { factor: 20, deduction: 1.175 },
                                    { factor: 50, deduction: 1.25 },
                                    { factor: 100, deduction: 1.5 },
                                    { factor: 1000, deduction: 2 },
                                    { factor: 10000, deduction: 2.5 },
                                    { factor: 9999999, deduction: 3 }
                                ],
                                mid: [
                                    { factor: 0.0000001, deduction: 1.1 },
                                    { factor: 0.0001, deduction: 1.1 },
                                    { factor: 0.001, deduction: 1.1 },
                                    { factor: 0.01, deduction: 1.1 },
                                    { factor: 0.02, deduction: 1.1 },
                                    { factor: 0.05, deduction: 1.1 },
                                    { factor: 0.1, deduction: 1.1 },
                                    { factor: 0.2, deduction: 1.1 },
                                    { factor: 0.5, deduction: 1.05 },
                                    { factor: 0.7, deduction: 1.03 },
                                    { factor: 0.8, deduction: 1.01 },
                                    { factor: 1, deduction: 1.01 },
                                    { factor: 1.2, deduction: 1.03 },
                                    { factor: 1.5, deduction: 1.05 },
                                    { factor: 2, deduction: 1.1 },
                                    { factor: 5, deduction: 1.15 },
                                    { factor: 10, deduction: 1.25 },
                                    { factor: 20, deduction: 1.35 },
                                    { factor: 50, deduction: 1.5 },
                                    { factor: 100, deduction: 2 },
                                    { factor: 1000, deduction: 3 },
                                    { factor: 10000, deduction: 4 },
                                    { factor: 9999999, deduction: 5 }
                                ],
                                high: [
                                    { factor: 0.0000001, deduction: 1.2 },
                                    { factor: 0.0001, deduction: 1.2 },
                                    { factor: 0.001, deduction: 1.2 },
                                    { factor: 0.01, deduction: 1.2 },
                                    { factor: 0.02, deduction: 1.2 },
                                    { factor: 0.05, deduction: 1.2 },
                                    { factor: 0.1, deduction: 1.2 },
                                    { factor: 0.2, deduction: 1.2 },
                                    { factor: 0.5, deduction: 1.1 },
                                    { factor: 0.7, deduction: 1.06 },
                                    { factor: 0.8, deduction: 1.02 },
                                    { factor: 1, deduction: 1.02 },
                                    { factor: 1.2, deduction: 1.06 },
                                    { factor: 1.5, deduction: 1.1 },
                                    { factor: 2, deduction: 1.2 },
                                    { factor: 5, deduction: 1.3 },
                                    { factor: 10, deduction: 1.5 },
                                    { factor: 20, deduction: 1.7 },
                                    { factor: 50, deduction: 2 },
                                    { factor: 100, deduction: 3 },
                                    { factor: 1000, deduction: 5 },
                                    { factor: 10000, deduction: 7 },
                                    { factor: 9999999, deduction: 9 }
                                ]
                            });
                        }
                    }).catch(error =>
                    {
                        console.log("error", error);
                    });
                }

                getSportConfig(SportWidgetOptions.externalSiteId).then(resp =>
                {
                    config.sport.bet = resp.data.sportsbook.bet;
                    config.sport.bet_delay = resp.data.sportsbook.bet_delay;
                    config.sport.cashout_delay = resp.data.sportsbook.cashout_delay;
                    config.sport.bet_types = resp.data.sportsbook.bet_types;

                    for (const currency in config.sport.bet)
                    {
                        if (resp.data?.sportsbook?.bet[currency]?.suggest)
                        {
                            config.sport.bet[currency].favorite_amounts = resp.data?.sportsbook?.bet[currency]?.suggest?.split(",").slice(0, 4).map(e => e.trim());
                        }
                        else
                        {
                            config.sport.bet[currency].favorite_amounts = ["10", "50", "100", "500"];
                        }
                    }

                    setSiteConfig(config);
                }).catch(error =>
                {
                    console.log("error", error);
                });

                if (localStorage.hasOwnProperty("layoutOption"))
                {
                    (window as any).sportWidgetLazyOptions = {
                        roundedView: getTypeOfLayoutOption === "rounded",
                        horizontalView: getTypeOfLayoutOption === "rounded",
                        singleView: getTypeOfLayoutOption === "main",
                        vbttopView: config.features.indexOf("vbttop-view") !== -1
                    };
                }
                else
                {
                    (window as any).sportWidgetLazyOptions = {
                        roundedView: config.features.indexOf("odds-rounded-view") !== -1 || (SportWidgetOptions.externalSiteId === "f1flj7" && SportWidgetOptions.source === "ls"),
                        horizontalView: config.features.indexOf("odds-rounded-view") !== -1 || (SportWidgetOptions.externalSiteId === "f1flj7" && SportWidgetOptions.source === "ls"),
                        singleView: config.features.indexOf("desktop-single-view") !== -1,
                        vbttopView: config.features.indexOf("vbttop-view") !== -1
                    };

                    if ((window as any).sportWidgetLazyOptions?.roundedView)
                    {
                        localStorage.setItem("layoutOption", "rounded");
                    }
                    else if ((window as any).sportWidgetLazyOptions?.singleView)
                    {
                        localStorage.setItem("layoutOption", "main");
                    }
                    else
                    {
                        localStorage.setItem("layoutOption", "asian");
                    }
                }

                // for (const func of (window as any).sportWidgetlazyComponents)
                // {
                //     func((window as any).sportWidgetLazyOptions);
                // }

                for (let i: number = 0; i < (window as any).sportWidgetlazyComponents.length; ++i)
                {
                    (window as any).sportWidgetlazyComponents[i]();
                }
            }).catch(error =>
            {
                console.log("error", error);
            });
        }
    }, []);

    useEffect(() =>
    {
        if (userId && token)
        {
            NotificationService.getInstance();

            getUserConfig(SportWidgetOptions.externalSiteId, userId).then(resp =>
            {
                for (let i: number = 0; i < resp.data.length; ++i)
                {
                    if (resp.data[i].type === "sportsbook")
                    {
                        setUserConfig(resp.data[i]);

                        if (NotificationService.getInstance().ready)
                        {
                            sendUserRequest();
                        }
                        else
                        {
                            NotificationService.getInstance().addEventListener(NotificationServiceEvent.READY, notificationServerReady);
                        }

                        break;
                    }
                }
            }).catch(error =>
            {
                console.log("error", error);
            });
        }
        else
        {
            if (userCommandRef.current)
            {
                NotificationService.getInstance().removeRequest(userCommandRef.current);
                userCommandRef.current.removeEventListener(RequestCommandEvent.DATA, onUserCommandUpdateHandler);
                userCommandRef.current = null;
            }

            if (bonusesCommandRef.current)
            {
                NotificationService.getInstance().removeRequest(bonusesCommandRef.current);
                bonusesCommandRef.current.removeEventListener(RequestCommandEvent.DATA, onBonusCommandUpdateHandler);
                bonusesCommandRef.current = null;
            }

            setUserBonusesLoaded(false);
        }
    }, [userId, token]);

    useEffect(() =>
    {
        if (userId && token && currency && siteConfig.features && !userBonusesLoaded)
        {
            if (siteConfig.features.indexOf("bonus") !== -1)
            {
                getUserBonuses({
                    status: ["open", "active", "pre-active"],
                    sort: "-_id",
                    site_id: SportWidgetOptions.externalSiteId,
                    user_id: SportWidgetOptions.userId,
                    currency: SportWidgetOptions.currency
                }).then(resp =>
                {
                    setUserBonuses(resp.data);

                    if (NotificationService.getInstance().ready)
                    {
                        sendBonusRequest();
                    }
                    else
                    {
                        NotificationService.getInstance().addEventListener(NotificationServiceEvent.READY, notificationServerReady);
                    }
                }).catch(error =>
                {
                    console.log("error", error);
                });
            }

            setUserBonusesLoaded(true);
        }
    }, [userId, token, currency, siteConfig.features, userBonusesLoaded]);

    useEffect(() =>
    {
        if (currency && userBonusesLoaded)
        {
            if (siteConfig.features.indexOf("bonus") !== -1)
            {
                getUserBonuses({
                    status: ["open", "active", "pre-active"],
                    sort: "-_id",
                    site_id: SportWidgetOptions.externalSiteId,
                    user_id: SportWidgetOptions.userId,
                    currency: SportWidgetOptions.currency
                }).then(resp =>
                {
                    setUserBonuses(resp.data);
                }).catch(error =>
                {
                    console.log("error", error);
                });
            }
        }
    }, [currency]);

    useEffect(() =>
    {
        if (currency && currenciesConfig)
        {
            setCurrentCurrencyConfig(currenciesConfig[currency]);
        }
    }, [currency, currenciesConfig]);

    return <ConfigContext.Provider
        value={{
            siteConfig,
            userConfig,
            userBonuses,
            cashoutConfig,
            currenciesConfig,
            currentCurrencyConfig,
            cashoutLadder,
            changeBonusAmount
        }}>{lightConfigReady ? children : (SportWidgetOptions.externalLoaderEnabled ? null : <Loader
        style={SportWidgetOptions.desktop ? {} : { height: `${window.innerHeight - 56}px` }}/>)}</ConfigContext.Provider>;
};
