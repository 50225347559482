import { immerable } from "immer";
import RegionData from "./RegionData";
import GameData from "./GameData";
import TournamentData from "./TournamentData";
import { ISportData } from "../../../typings/sport-data/ISportData";

class SportData
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    [immerable] = true;

    private _id: string;
    private _alias: string;
    private _name: string;
    private _order: number;
    private _games: GameData[];
    private _tournaments: TournamentData[];
    private _regions: RegionData[];
    private _gamesCount: number;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(sport: ISportData)
    {
        this._id = sport.id;
        this._alias = sport.alias;
        this._name = sport.name;
        this._order = sport.order;
        this._games = [];
        this._tournaments = [];
        this._regions = [];
        this._gamesCount = 0;
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    public get id(): string
    {
        return this._id;
    }

    public set id(value: string)
    {
        this._id = value;
    }

    public get alias(): string
    {
        return this._alias;
    }

    public set alias(value: string)
    {
        this._alias = value;
    }

    public get name(): string
    {
        return this._name;
    }

    public set name(value: string)
    {
        this._name = value;
    }

    public get order(): number
    {
        return this._order;
    }

    public set order(value: number)
    {
        this._order = value;
    }

    public get games(): GameData[]
    {
        return this._games;
    }

    public set games(value: GameData[])
    {
        this._games = value;
    }

    public get tournaments(): TournamentData[]
    {
        return this._tournaments;
    }

    public set tournaments(value: TournamentData[])
    {
        this._tournaments = value;
    }

    public get regions(): RegionData[]
    {
        return this._regions;
    }

    public set regions(value: RegionData[])
    {
        this._regions = value;
    }

    public get gamesCount(): number
    {
        return this._gamesCount;
    }

    public set gamesCount(value: number)
    {
        this._gamesCount = value;
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------

    public pushGame(game: GameData): void
    {
        this._games.push(game);
    }

    public pushTournament(tournament: TournamentData): void
    {
        this._tournaments.push(tournament);
    }

    public pushRegion(region: RegionData): void
    {
        this._regions.push(region);
    }
}

export default SportData;