import React, { lazy, Suspense, useContext, useEffect } from "react";
import {
    changeBalance,
    changeDesktop,
    changeOddType,
    changeToken, changeVideoEnabled,
    setInitOptions, setUserData
} from "../redux/slice/sportOptionsSlice";
import { getSiteDirection, getSiteLanguage } from "../utils/helpers/SportHelpers";
import StreamService from "../services/sport-data/StreamService";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ConfigContext } from "../context/ConfigContext";
import { LocaleContext } from "../context/LocaleContext";

const RouterMobile = lazy(() => import("./../router/mobile/RouterMobile"));
const RouterDesktop = lazy(() => import("./../router/desktop/RouterDesktop"));

const RouterContainer = () =>
{
    const dispatch = useAppDispatch();
    const { siteConfig } = useContext(ConfigContext);
    const { setLocale, setType } = useContext(LocaleContext);
    const videoEnabled: boolean = siteConfig.features && siteConfig.features.indexOf("live-tv") !== -1;
    const isDesktop = useAppSelector(state => state.sportOptions.isDesktop);

    useEffect(() =>
    {
        dispatch(setInitOptions({
            token: SportWidgetOptions.token,
            balance: SportWidgetOptions.balance,
            currency: SportWidgetOptions.currency,
            userId: SportWidgetOptions.userId,
            oddType: SportWidgetOptions.oddType,
            isDesktop: SportWidgetOptions.desktop
        }));

        window.changeBalance = (balance, currency) =>
        {
            SportWidgetOptions.balance = balance;
            SportWidgetOptions.currency = currency;

            dispatch(changeBalance({
                balance: balance,
                currency: currency
            }));
        };

        window.changeLanguage = (locale) =>
        {
            SportWidgetOptions.locale = locale;

            const mainContainer = document.getElementById("sport-main-container");

            if (mainContainer)
            {
                mainContainer.setAttribute("style", `font-family: ${SportWidgetOptions.locale}-font, default-font, Inter;`);
            }

            setLocale(getSiteLanguage());
            setType(getSiteDirection());
            StreamService.getInstance().updateLocale(getSiteLanguage());
        };

        window.changeToken = (token) =>
        {
            SportWidgetOptions.token = token;
            dispatch(changeToken({
                token: token
            }));
        };

        window.changeOddType = (oddType) =>
        {
            SportWidgetOptions.oddType = oddType;
            dispatch(changeOddType({
                oddType: oddType
            }));
        };

        window.changeViewType = (isDesktop: boolean) =>
        {
            SportWidgetOptions.desktop = isDesktop;
            dispatch(changeDesktop(isDesktop));
        };
    }, []);

    useEffect(() =>
    {
        if (videoEnabled)
        {
            if (!SportWidgetOptions.token && SportWidgetOptions.externalSiteId === "2qwk5m")
            {
                dispatch(changeVideoEnabled(false));
            }
            else if (!SportWidgetOptions.token || ["IRT", "TND", "TRY"].indexOf(SportWidgetOptions.currency) === -1)
            {
                if (SportWidgetOptions.externalSiteId === "in2p")
                {
                    dispatch(changeVideoEnabled(false));
                }
                else
                {
                    dispatch(changeVideoEnabled(videoEnabled));
                }
            }
            else
            {
                dispatch(changeVideoEnabled(videoEnabled));
            }
        }
        else
        {
            dispatch(changeVideoEnabled(videoEnabled));
        }

        window.setUserData = (token, userId) =>
        {
            SportWidgetOptions.token = token;
            SportWidgetOptions.userId = userId;

            dispatch(setUserData({
                token: token,
                userId: userId,
                videoEnabled: videoEnabled
            }));
        };
    }, [videoEnabled]);

    return <Suspense fallback={null}>
        {isDesktop ? <RouterDesktop/> : <RouterMobile/>}
    </Suspense>;
};

export default RouterContainer;
