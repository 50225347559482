import { combineReducers, configureStore, createAction } from "@reduxjs/toolkit";
import sportOptionsReducer from "./slice/sportOptionsSlice";
import betSlipReducer from "./slice/betSlipSlice";
import feedDownReducer from "./slice/feedDownSlice";
import favoriteReducer from "./slice/favoriteSlice";
import liveSportsReducer from "./slice/liveSportsSlice";
import allLiveSportsReducer from "./slice/allLiveSportsSlice";
import liveCurrentSportReducer from "./slice/liveCurrentSportSlice";
import liveCurrentGameReducer from "./slice/liveCurrentGameSlice";
import prematchSportsReducer from "./slice/prematchSportsSlice";
import prematchCurrentSportReducer from "./slice/prematchCurrentSportSlice";
import prematchCurrentTournamentReducer from "./slice/prematchCurrentTournamentSlice";
import prematchCurrentGameReducer from "./slice/prematchCurrentGameSlice";
import todaySportsReducer from "./slice/todaySportsSlice";
import todayCurrentSportReducer from "./slice/todayCurrentSportSlice";
import calendarSportsReducer from "./slice/calendarSportsSlice";
import calendarCurrentSportReducer from "./slice/calendarCurrentSportSlice";
import highlightsSportsReducer from "./slice/highlightsSportsSlice";
import highlightsCurrentSportReducer from "./slice/highlightsCurrentSportSlice";
import printTicketReducer from "./slice/printTicketSlice";
import myOpenBetsCountReducer from "./slice/myOpenBetsCountSlice";

export const resetStore = createAction<undefined>("resetStore");

export const store = configureStore({
    reducer: (state, action) =>
    {
        if (action.type === "resetStore")
        {
            state = undefined;
        }

        return combineReducers({
            sportOptions: sportOptionsReducer,
            betSlip: betSlipReducer,
            feedDown: feedDownReducer,
            favorite: favoriteReducer,
            liveSports: liveSportsReducer,
            allLiveSports: allLiveSportsReducer,
            liveCurrentSport: liveCurrentSportReducer,
            liveCurrentGame: liveCurrentGameReducer,
            prematchSports: prematchSportsReducer,
            prematchCurrentSport: prematchCurrentSportReducer,
            prematchCurrentTournament: prematchCurrentTournamentReducer,
            prematchCurrentGame: prematchCurrentGameReducer,
            todaySports: todaySportsReducer,
            todayCurrentSport: todayCurrentSportReducer,
            calendarSports: calendarSportsReducer,
            calendarCurrentSport: calendarCurrentSportReducer,
            highlightsSports: highlightsSportsReducer,
            highlightsCurrentSport: highlightsCurrentSportReducer,
            printTicket: printTicketReducer,
            myOpenBetsCount: myOpenBetsCountReducer
        })(state, action);
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export const storeDispatch = store.dispatch;
export const getStoreState = store.getState;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
