import React, { useRef } from "react";
import { debounce } from "lodash-es";
import ErrorBoundary from "../../error-boundary/ErrorBoundary";
import parseShadowValues from "../functions/parseShadowValues";
import stringifyShadowValues from "../functions/stringifyShadowValues";

import styles from "../colorpicker.module.scss";

const properties = ["sbBrand", "sbDarkest", "sbDark", "sbMedium", "sbRegular", "sbSemiLight", "sbLight", "sbUltraLight", "sbLightest", "sbWhite", "sbWarning", "sbSuccess", "sbError"];

interface ICustomProps
{
    shadow: any;
    innerShadow: any;
    values: any;
    updateValues: any;
}

const Custom: React.FC<ICustomProps> = ({ shadow, innerShadow, values, updateValues }): React.ReactElement =>
{
    const shadowValueHolder = useRef<string>();
    const innerShadowValueHolder = useRef<string>();

    const changeValue = (index, value) =>
    {
        document.documentElement.style.setProperty("--" + properties[index], value);
        updateValues();
    };

    const setInnerShadowProperty: (name, value) => void = debounce((name, value): void =>
    {
        const root = document.querySelector(":root");

        const shadowString = getComputedStyle(root).getPropertyValue("--sbInsetShadow");
        const newValue = "inset " + stringifyShadowValues({ ...parseShadowValues(shadowString), [name]: value });
        document.documentElement.style.setProperty("--sbInsetShadow", newValue);
        updateValues();
    }, 10);

    const setShadowProperty: (name, value) => void = debounce((name, value): void =>
    {
        const root = document.querySelector(":root");

        const shadowString = getComputedStyle(root).getPropertyValue("--sbShadow");
        const newValue = stringifyShadowValues({ ...parseShadowValues(shadowString), [name]: value });
        document.documentElement.style.setProperty("--sbShadow", newValue);
        updateValues();
    }, 10);

    const setShadowValue = ({ target: { value, name } }, type = "normal") =>
    {
        if (type === "inner")
        {
            if (name !== "exists")
            {
                setInnerShadowProperty(name, value);
            }
            else
            {
                if (value)
                {
                    if (innerShadowValueHolder.current)
                    {
                        document.documentElement.style.setProperty("--sbInsetShadow", innerShadowValueHolder.current);
                        updateValues();
                    }
                }
                else
                {
                    const root = document.querySelector(":root");
                    innerShadowValueHolder.current = getComputedStyle(root).getPropertyValue("--sbInsetShadow");
                    document.documentElement.style.setProperty("--sbInsetShadow", "none");
                    updateValues();
                }
            }
        }
        else
        {
            if (name !== "exists")
            {
                setShadowProperty(name, value);
            }
            else
            {
                if (value)
                {
                    if (shadowValueHolder.current)
                    {
                        document.documentElement.style.setProperty("--sbShadow", shadowValueHolder.current);
                        updateValues();
                    }
                }
                else
                {
                    const root = document.querySelector(":root");

                    shadowValueHolder.current = getComputedStyle(root).getPropertyValue("--sbShadow");
                    document.documentElement.style.setProperty("--sbShadow", "none");
                    updateValues();
                }
            }
        }
    };

    return <ErrorBoundary>
        <div className={styles.colors}>
            <b>Colors</b>
            <hr/>
            {
                properties.map((prop, index) =>
                {
                    return (
                        <div key={index} className={styles.propInput}>
                            <p>{prop}:</p>
                            {
                                values[index] ?
                                    <input defaultValue={values[index]}
                                           onChange={e => changeValue(index, e.target.value)}
                                           type="color"/>
                                    :
                                    ""
                            }
                        </div>
                    );
                })
            }
        </div>
        <div className={styles.shadows}>
            <div className={styles.shadow}>
                <div className={styles.shadowProp}>
                    <b>Shadow:</b>
                    <input defaultChecked={shadow.exists} onChange={e => setShadowValue({
                        target: {
                            value: e.target.checked,
                            name: "exists"
                        }
                    })} type="checkbox"/>
                </div>
                <hr/>
                <div className={styles.shadowProp}>
                    <p>h-offset:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e)} defaultValue={shadow.h_offset} name="h_offset"
                               type="text"/>
                        <p>px</p>
                    </div>
                </div>
                <div className={styles.shadowProp}>
                    <p>v-offset:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e)} defaultValue={shadow.v_offset} name="v_offset"
                               type="text"/>
                        <p>px</p>
                    </div>
                </div>
                <div className={styles.shadowProp}>
                    <p>blur:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e)} defaultValue={shadow.blur} name="blur"
                               type="text"/>
                        <p>px</p>
                    </div>
                </div>
                <div className={styles.shadowProp}>
                    <p>spread:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e)} defaultValue={shadow.spread} name="spread"
                               type="text"/>
                        <p>px</p>
                    </div>
                </div>
                <div className={styles.shadowProp}>
                    <p>color:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e)} defaultValue={shadow.color} name="color"
                               type="color"/>
                    </div>
                </div>
                <div className={styles.shadowProp}>
                    <p>opacity:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e)} defaultValue={shadow.opacity} name="opacity"
                               type="text"/>
                        <p></p>
                    </div>
                </div>
            </div>
            <div className={styles.innerShadow}>
                <div className={styles.shadowProp}>
                    <b>Inner shadow:</b>
                    <input defaultChecked={innerShadow.exists} onChange={e => setShadowValue({
                        target: {
                            value: e.target.checked,
                            name: "exists"
                        }
                    }, "inner")} type="checkbox"/>
                </div>
                <hr/>
                <div className={styles.shadowProp}>
                    <p>h-offset:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e, "inner")} defaultValue={innerShadow.h_offset}
                               name="h_offset" type="text"/>
                        <p>px</p>
                    </div>
                </div>
                <div className={styles.shadowProp}>
                    <p>v-offset:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e, "inner")} defaultValue={innerShadow.v_offset}
                               name="v_offset" type="text"/>
                        <p>px</p>
                    </div>
                </div>
                <div className={styles.shadowProp}>
                    <p>blur:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e, "inner")} defaultValue={innerShadow.blur}
                               name="blur" type="text"/>
                        <p>px</p>
                    </div>
                </div>
                <div className={styles.shadowProp}>
                    <p>spread:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e, "inner")} defaultValue={innerShadow.spread}
                               name="spread" type="text"/>
                        <p>px</p>
                    </div>
                </div>
                <div className={styles.shadowProp}>
                    <p>color:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e, "inner")} defaultValue={innerShadow.color}
                               name="color" type="color"/>
                    </div>
                </div>
                <div className={styles.shadowProp}>
                    <p>opacity:</p>
                    <div className={styles.input}>
                        <input onChange={e => setShadowValue(e, "inner")} defaultValue={innerShadow.opacity}
                               name="opacity" type="text"/>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </ErrorBoundary>;
};

export default Custom;