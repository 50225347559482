import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { resetStore, store } from "./redux/store";
import { LocaleProvider } from "./context/LocaleContext";
import { ConfigProvider } from "./context/ConfigContext";
import ErrorBoundary from "./components/base/error-boundary/ErrorBoundary";
import "./App.scss";
import AllLiveSportsModel from "./models/sport-data/AllLiveSportsModel";
import LiveSportsModel from "./models/sport-data/LiveSportsModel";
import PrematchSportsModel from "./models/sport-data/PrematchSportsModel";
import StreamService from "./services/sport-data/StreamService";
import PrematchCurrentSportModel from "./models/sport-data/PrematchCurrentSportModel";
import ColorPicker from "./components/base/color-picker/ColorPicker";
import RouterContainer from "./router/RouterContainer";

const App = () =>
{
    const [shown, setShown] = useState<boolean>(false);
    const [init, setInit] = useState(SportWidgetOptions.init);

    useEffect(() =>
    {
        window.removeWidget = (keepUrl: boolean) =>
        {
            setInit(false);

            SportWidgetOptions = {};
            SportWidgetOptions.init = false;

            const container = document.getElementById("sport-container");

            if (container)
            {
                container.innerHTML = "";
            }

            const mainJs = document.getElementById("widget-main-js");

            if (mainJs)
            {
                mainJs.parentNode.removeChild(mainJs);
            }

            const mainCss = document.getElementById("widget-main-css");

            if (mainCss)
            {
                mainCss.parentNode.removeChild(mainCss);
            }

            const fontCss = document.getElementById("widget-font-css");

            if (fontCss)
            {
                fontCss.parentNode.removeChild(fontCss);
            }

            AllLiveSportsModel.getInstance().clearRequest();
            LiveSportsModel.getInstance().clearRequest();
            PrematchSportsModel.getInstance().clearRequest();
            StreamService.getInstance().close();
            PrematchCurrentSportModel.getInstance().currentSportId = null;

            store.dispatch(resetStore(null));

            if (!SportWidgetOptions.browserHistoryEnabled && !keepUrl)
            {
                history.replaceState("", document.title, window.location.pathname
                    + window.location.search);
            }
        };

        function handleKeyDown(event)
        {
            const { key, ctrlKey, altKey, shiftKey } = event;
            if ((key === "C" || key === "Ç") && ctrlKey && altKey && shiftKey)
            {
                setShown(!shown);
            }
        }

        window.addEventListener("keydown", handleKeyDown);

        StreamService.getInstance().setDataSource(SportWidgetOptions?.source || null);

        setTimeout(() =>
        {
            localStorage.setItem("reloadCount", "0");
        }, 5000);

        return () =>
        {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    return init ? <Provider store={store}>
        <LocaleProvider>
            <ConfigProvider>
                <ErrorBoundary>
                    <RouterContainer/>
                    {shown && <ColorPicker/>}
                </ErrorBoundary>
            </ConfigProvider>
        </LocaleProvider>
    </Provider> : null;
};

export default App;
