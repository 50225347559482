export const IS_DEV = import.meta.env.DEV;
export const DOMAIN = import.meta.env.REACT_APP_DOMAIN;
export const HOST = import.meta.env.REACT_APP_HOST;
export const STREAM_DOMAIN = import.meta.env.REACT_APP_STREAM_DOMAIN;
export const STREAM_PATH = import.meta.env.REACT_APP_STREAM_PATH;
export const NOTIFICATION_PATH = import.meta.env.REACT_APP_NOTIFICATION_PATH;
export const STORAGE_PATH = "https://cdn.playerms.com/uploads/";
export const LIVE_SCORE_PATH = "https://smt.scoresfinder.com/index.php";
export const DECIMAL_WIDGET_STAGING_PATH = "https://widget-staging.decimalcricket.net/";
export const DECIMAL_WIDGET_PATH = "https://widget.decimalcricket.net/";
export const BUILD_VERSION = import.meta.env.REACT_APP_BUILD_VERSION || "Unknown";