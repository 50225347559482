import MarketData from "../../data/sport/MarketData";

function marketSortHelper(m1: MarketData, m2: MarketData): number
{
    if (m1.priority && m2.priority && m1.order === m2.order)
    {
        const priorities1: string[] = m1.priority.split("/");
        const priorities2: string[] = m2.priority.split("/");

        for (let i: number = 0; i < priorities1.length; ++i)
        {
            if (priorities1[i] !== priorities2[i])
            {
                return parseFloat(priorities1[i]) - parseFloat(priorities2[i]);
            }
        }
    }

    return m1.order - m2.order;
}

export default marketSortHelper;