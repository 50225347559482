import { findIndex } from "lodash-es";
import {
    DateFields,
    GameChannels,
    GameFeeds,
    GameFields,
    GameRequest,
    RegionFields,
    RequestCommandEvent,
    RequestCriteriaField,
    RequestCriteriaLevel, RequestCriteriaNumericOperator,
    RequestGamesCount,
    RequestSelector,
    SportFields,
    TournamentFields
} from "@platform/stream-client";
import StreamService from "../../services/sport-data/StreamService";
import { StreamServiceEvent } from "../../services/sport-data/events/StreamServiceEvent";
import RegionData from "../../data/sport/RegionData";
import TournamentData from "../../data/sport/TournamentData";
import itemIndexInOrderHelper from "../../utils/helpers/ItemIndexInOrderHelper";
import { getStoreState, storeDispatch } from "../../redux/store";
import { setPrematchSportRegions, updatePrematchSportTournament } from "../../redux/slice/prematchCurrentSportSlice";

class PrematchCurrentSportModel
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    private static _instance: PrematchCurrentSportModel;
    private _currentSportId: string;
    private _sportCommand: GameRequest;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(cl: Enforce)
    {

    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------
    public set currentSportId(value: string)
    {
        this._currentSportId = value;
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public static getInstance(): PrematchCurrentSportModel
    {
        if (this._instance == null)
        {
            this._instance = new PrematchCurrentSportModel(new Enforce());
        }

        return this._instance;
    }

    public setSportId(sportId: string): void
    {
        if (this._currentSportId !== sportId)
        {
            this._currentSportId = sportId;
            this.clear();

            if (StreamService.getInstance().ready)
            {
                this.sendSportCommand();
            }
            else
            {
                StreamService.getInstance().addEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
            }
        }
    }

    public clear(): void
    {
        // storeDispatch(setPrematchSportRegions(null));

        if (this._sportCommand)
        {
            StreamService.getInstance().removeRequest(this._sportCommand);
            this._sportCommand.removeEventListener(RequestCommandEvent.DATA, this.onSportCommandUpdateHandler);
            this._sportCommand = null;
        }
    }

    // --------------------------------------------------------------------------
    //
    // Private methods
    //
    // --------------------------------------------------------------------------
    public sendSportCommand(): void
    {
        this._sportCommand = new GameRequest(GameChannels.ACTIVE, true, "prematch sport tournaments");

        const sportSelector: RequestSelector = new RequestSelector(GameFields.SPORT);
        sportSelector.add(SportFields.ID, SportFields.ALIAS, SportFields.NAME);

        const regionSelector: RequestSelector = new RequestSelector(GameFields.REGION);
        regionSelector.add(RegionFields.ID, RegionFields.ALIAS, RegionFields.NAME, RegionFields.ORDER, RegionFields.IMAGE);

        const tournamentSelector: RequestSelector = new RequestSelector(GameFields.TOURNAMENT);
        tournamentSelector.add(TournamentFields.ID, TournamentFields.ALIAS, TournamentFields.NAME, TournamentFields.ORDER, TournamentFields.IMAGE);

        const sportCriteria: RequestCriteriaLevel = new RequestCriteriaLevel(GameFields.SPORT);
        sportCriteria.addSubCriteria(new RequestCriteriaField(SportFields.ID, this._currentSportId));

        const gameCriteria: RequestCriteriaLevel = new RequestCriteriaLevel();
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.FEED, GameFeeds.PREMATCH));
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.ACTIVE, true));

        if (getStoreState().prematchSports.selectedFilter)
        {
            const dateCriteria: RequestCriteriaLevel = new RequestCriteriaLevel(GameFields.DATE);
            dateCriteria.addSubCriteria(new RequestCriteriaNumericOperator(DateFields.START, RequestCriteriaNumericOperator.LOWER_OR_EQUAL, Math.round(new Date().getTime() / 1000) + getStoreState().prematchSports.selectedFilter * 60 * 60));
            this._sportCommand.addCriteria(dateCriteria);
        }

        this._sportCommand.addSelector(sportSelector, regionSelector, tournamentSelector, new RequestGamesCount());
        this._sportCommand.addCriteria(sportCriteria, gameCriteria);

        this._sportCommand.addEventListener(RequestCommandEvent.DATA, this.onSportCommandUpdateHandler);

        StreamService.getInstance().sendRequest(this._sportCommand);
    }

    private onStreamServiceReadyHandler: (event: StreamServiceEvent) => void = (event: StreamServiceEvent): void =>
    {
        StreamService.getInstance().removeEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        this.sendSportCommand();
    };

    private onSportCommandUpdateHandler: (event: RequestCommandEvent) => void = (event: RequestCommandEvent): void =>
    {
        if (event.fullUpdate)
        {
            const regions: RegionData[] = [];

            for (const game of event.data)
            {
                const regionIndex: number = findIndex(regions, { id: game.region.id });
                let tournamentIndex: number;
                let regionData: RegionData;
                let tournamentData: TournamentData;

                if (regionIndex === -1)
                {
                    regionData = new RegionData(game.region);
                    regionData.sport = game.sport;
                    regionData.gamesCount = game.game;
                    tournamentData = new TournamentData(game.tournament);
                    tournamentData.sport = game.sport;
                    tournamentData.region = game.region;
                    tournamentData.gamesCount = game.game;

                    regionData.pushTournament(tournamentData);

                    regions.splice(itemIndexInOrderHelper(regionData.order, regions), 0, regionData);
                }
                else
                {
                    regionData = regions[regionIndex];
                    tournamentIndex = findIndex(regionData.tournaments, { id: game.tournament.id });

                    tournamentData = new TournamentData(game.tournament);
                    tournamentData.sport = game.sport;
                    tournamentData.region = game.region;
                    tournamentData.gamesCount = game.game;

                    regionData.tournaments.splice(itemIndexInOrderHelper(tournamentData.order, regionData.tournaments), 0, tournamentData);
                    regionData.gamesCount += game.game;
                }
            }

            storeDispatch(setPrematchSportRegions(regions));
        }
        else
        {
            if (event.data[0])
            {
                storeDispatch(updatePrematchSportTournament(event.data[0]));
            }
        }
    };
}

class Enforce
{

}

export default PrematchCurrentSportModel;