import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash-es";
import SportData from "../../data/sport/SportData";
import itemIndexInOrderHelper from "../../utils/helpers/ItemIndexInOrderHelper";

export interface IHighlightsSportsState
{
    sports: SportData[];
}

const initialState: IHighlightsSportsState = {
    sports: null,
};

export const highlightsSportsSlice = createSlice({
    name: "highlightsSports",
    initialState,
    reducers: {
        setHighlightsSports: (state, action) =>
        {
            state.sports = action.payload;
        },
        updateHighlightsSport: (state, action) =>
        {
            const sportIndex = findIndex(state.sports, { id: action.payload.sport.id });

            if (sportIndex === -1 && action.payload.game !== 0)
            {
                const sportData: SportData = new SportData(action.payload.sport);
                sportData.gamesCount = action.payload.game;
                state.sports.splice(itemIndexInOrderHelper(sportData.order, state.sports), 0, sportData);
            }
            else if (sportIndex !== -1)
            {
                if (action.payload.game === 0)
                {
                    state.sports.splice(sportIndex, 1);
                }
                else
                {
                    state.sports[sportIndex].gamesCount = action.payload.game;
                }
            }
        }
    }
});

export const { setHighlightsSports, updateHighlightsSport } = highlightsSportsSlice.actions;

export default highlightsSportsSlice.reducer;
