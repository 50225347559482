import {
    GameChannels,
    GameFields,
    GameRequest,
    RequestCommandEvent, RequestCriteriaField,
    RequestCriteriaLevel,
    RequestSelector
} from "@platform/stream-client";
import { StreamServiceEvent } from "../../../services/sport-data/events/StreamServiceEvent";
import StreamService from "../../../services/sport-data/StreamService";
import { storeDispatch } from "../../../redux/store";
import { removeFavoriteGameId } from "../../../redux/slice/favoriteSlice";

export class FavoriteGameModel
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------
    private _gameCommand: GameRequest;
    private _gameId: string = "";

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------
    constructor(gameId: string)
    {
        this._gameId = gameId;

        if (StreamService.getInstance().ready)
        {
            this.sendGameCommand();
        }
        else
        {
            StreamService.getInstance().addEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        }
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public clearRequest(): void
    {
        this._gameId = "";

        if (this._gameCommand)
        {
            StreamService.getInstance().removeRequest(this._gameCommand);
            this._gameCommand.removeEventListener(RequestCommandEvent.DATA, this.onGamesCommandUpdateHandler);
            this._gameCommand = null;
        }
    }

    // --------------------------------------------------------------------------
    //
    // Private methods
    //
    // --------------------------------------------------------------------------
    private sendGameCommand(): void
    {
        this._gameCommand = new GameRequest(GameChannels.ACTIVE, true, "favorite game");
        const gameSelector: RequestSelector = new RequestSelector();
        gameSelector.add(GameFields.ID);

        const gameCriteria: RequestCriteriaLevel = new RequestCriteriaLevel();
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.ID, this._gameId));
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.ACTIVE, true));

        this._gameCommand.addSelector(gameSelector);
        this._gameCommand.addCriteria(gameCriteria);

        this._gameCommand.addEventListener(RequestCommandEvent.DATA, this.onGamesCommandUpdateHandler);
        StreamService.getInstance().sendRequest(this._gameCommand);
    }

    private onStreamServiceReadyHandler: (event: StreamServiceEvent) => void = (event: StreamServiceEvent): void =>
    {
        StreamService.getInstance().removeEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        this.sendGameCommand();
    };

    private onGamesCommandUpdateHandler: (event: RequestCommandEvent) => void = (event: RequestCommandEvent): void =>
    {
        if (event.fullUpdate)
        {
            if (event.data.length === 0)
            {
                storeDispatch(removeFavoriteGameId(this._gameId));
            }
        }
        else
        {
            if (event.data[0]._remove)
            {
                storeDispatch(removeFavoriteGameId(this._gameId));
            }
        }
    };
}