import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { findIndex, merge } from "lodash-es";
import { RootState } from "../store";
import BetItemData from "../../data/bet-slip/BetItemData";
import { BetItemModelList } from "../../models/bet-slip/BetItemModelList";
import { BetItemModel } from "../../models/bet-slip/BetItemModel";
import { BetTypes, GameTypes, ChangeModes } from "@platform/stream-client";
import { getExpressBonus } from "../../services/rest/bonus/BonusService";
import { IBetResultDataObject } from "../../../typings/bet-slip/IBetResult";
import { IExpressBonusData } from "../../../typings/bet-slip/IExpressBonus";
import { IUserBonusConfig } from "../../context/ConfigContext";
import { WalletTypes } from "../../utils/static/WalletTypes";
import { getBetSlipKey } from "../../utils/helpers/SportHelpers";

export interface IBetSlipState
{
    selectedTab: string;
    betItems: BetItemData[];
    betItemIds: string[];
    quickBetSlipOpened: boolean;
    isBetProcessing: boolean;
    betType: BetTypes;
    hasDublicate: boolean;
    smallViewStake: string;
    multipleBetStake: string;
    multipleBetReofferStake: string;
    multiBetReofferId: string;
    oddsChangeMode: ChangeModes;
    cashoutChangeMode: ChangeModes;
    systemBetStake: string;
    systemBetSelection: number;
    placeBetError: IBetResultDataObject;
    expressBonus: IExpressBonusData[];
    selectedBonus: IExpressBonusData;
    expressBonusPercent: number;
    expressBonusChecked: boolean;
    freeBetBonus: IUserBonusConfig;
    wageringBonus: IUserBonusConfig;
    bonusAmount: number;
    wallet: WalletTypes;
    walletForceSelected: boolean;
    isFullScreen: boolean;
    streamSource: string;
    vbttopBetSlipOpen: boolean;
}

const initialState: IBetSlipState = {
    selectedTab: "betSlip",
    betItems: [],
    betItemIds: [],
    quickBetSlipOpened: false,
    isBetProcessing: false,
    betType: BetTypes.SINGLE,
    hasDublicate: false,
    smallViewStake: "",
    multipleBetStake: "",
    multipleBetReofferStake: "",
    multiBetReofferId: null,
    oddsChangeMode: (localStorage.getItem("oddsChangeMode") as ChangeModes) || ChangeModes.NONE,
    cashoutChangeMode: (localStorage.getItem("cashoutChangeMode") as ChangeModes) || ChangeModes.NONE,
    systemBetStake: "",
    systemBetSelection: 2,
    placeBetError: { code: "", message: "" },
    expressBonus: [],
    selectedBonus: null,
    expressBonusPercent: null,
    expressBonusChecked: true,
    freeBetBonus: null,
    wageringBonus: null,
    bonusAmount: null,
    wallet: WalletTypes.CASH,
    walletForceSelected: false,
    isFullScreen: false,
    streamSource: "",
    vbttopBetSlipOpen: false
};

export const expressBonus = createAsyncThunk("expressBonus", async () =>
{
    const response = await getExpressBonus(SportWidgetOptions.externalSiteId);
    return response.data;
});

export const betSlipSlice = createSlice({
    name: "betslip",
    initialState,
    reducers: {
        setSelectedTab: (state, action) =>
        {
            state.selectedTab = action.payload;
        },
        setBetType: (state, action) =>
        {
            state.betType = action.payload;

            for (let i: number = 0; i < state.betItems.length; ++i)
            {
                state.betItems[i].dublicate = false;
                // state.betItems[i].notCompatible = false;

                if (state.betType !== BetTypes.SINGLE)
                {
                    for (let j: number = 0; j < state.betItems.length; ++j)
                    {
                        if (i !== j && state.betItems[i].gameId === state.betItems[j].gameId)
                        {
                            state.betItems[i].dublicate = true;
                        }

                        if (i !== j && (state.betItems[i].gameType === GameTypes.OUTRIGHT || state.betItems[j].gameType === GameTypes.OUTRIGHT) && state.betItems[i].tournamentId === state.betItems[j].tournamentId)
                        {
                            state.betItems[i].dublicate = true;
                        }

                        // if (i !== j && state.betItems[i].source !== state.betItems[j].source)
                        // {
                        //     state.betItems[i].notCompatible = true;
                        // }
                    }
                }
            }
        },
        addBetItem: (state, action) =>
        {
            BetItemModelList.getInstance().addItem(new BetItemModel(action.payload));

            state.selectedTab = "betSlip";

            state.betItems.push(action.payload);
            state.betItemIds.push(action.payload.uniqueId);

            state.hasDublicate = false;

            mainLoop: for (let i: number = 0; i < state.betItems.length; ++i)
            {
                for (let j: number = 0; j < state.betItems.length; ++j)
                {
                    // if (i !== j && (state.betItems[i].gameId === state.betItems[j].gameId || state.betItems[i].source !== state.betItems[j].source))
                    if (i !== j && state.betItems[i].gameId === state.betItems[j].gameId)
                    {
                        state.hasDublicate = true;
                        break mainLoop;
                    }
                }
            }

            if (state.hasDublicate && !(window as any).sportWidgetLazyOptions.vbttopView)
            {
                state.betType = BetTypes.SINGLE;
            }
            else if (state.betItems.length > 1 && state.betType !== BetTypes.SYSTEM)
            {
                state.betType = BetTypes.ACCUMULATOR;
            }

            for (let i: number = 0; i < state.betItems.length; ++i)
            {
                state.betItems[i].dublicate = false;
                // state.betItems[i].notCompatible = false;

                if (state.betType !== BetTypes.SINGLE)
                {
                    for (let j: number = 0; j < state.betItems.length; ++j)
                    {
                        if (i !== j && state.betItems[i].gameId === state.betItems[j].gameId)
                        {
                            state.betItems[i].dublicate = true;
                        }

                        if (i !== j && (state.betItems[i].gameType === GameTypes.OUTRIGHT || state.betItems[j].gameType === GameTypes.OUTRIGHT) && state.betItems[i].tournamentId === state.betItems[j].tournamentId)
                        {
                            state.betItems[i].dublicate = true;
                        }

                        // if (i !== j && state.betItems[i].source !== state.betItems[j].source)
                        // {
                        //     state.betItems[i].notCompatible = true;
                        // }
                    }
                }
            }

            localStorage.setItem(getBetSlipKey(), JSON.stringify(state.betItemIds));
        },
        setQuickBetSlipOpened: (state, action) =>
        {
            state.quickBetSlipOpened = action.payload;
        },
        removeBetItem: (state, action) =>
        {
            const index: number = state.betItemIds.indexOf(action.payload);

            if (index !== -1)
            {
                state.betItems.splice(index, 1);
                state.betItemIds.splice(index, 1);
                BetItemModelList.getInstance().removeItem(index);
            }

            if (state.betItems.length === 2 && state.betType === BetTypes.SYSTEM)
            {
                state.betType = BetTypes.ACCUMULATOR;
            }

            if (state.betItems.length === 1)
            {
                state.betType = BetTypes.SINGLE;
            }

            for (let i: number = 0; i < state.betItems.length; ++i)
            {
                state.betItems[i].dublicate = false;
                // state.betItems[i].notCompatible = false;

                if (state.betType !== BetTypes.SINGLE)
                {
                    for (let j: number = 0; j < state.betItems.length; ++j)
                    {
                        if (i !== j && state.betItems[i].gameId === state.betItems[j].gameId)
                        {
                            state.betItems[i].dublicate = true;
                        }

                        if (i !== j && (state.betItems[i].gameType === GameTypes.OUTRIGHT || state.betItems[j].gameType === GameTypes.OUTRIGHT) && state.betItems[i].tournamentId === state.betItems[j].tournamentId)
                        {
                            state.betItems[i].dublicate = true;
                        }

                        // if (i !== j && state.betItems[i].source !== state.betItems[j].source)
                        // {
                        //     state.betItems[i].notCompatible = true;
                        // }
                    }
                }
            }

            if (state.betItems.length === 0)
            {
                state.smallViewStake = "";
            }

            localStorage.setItem(getBetSlipKey(), JSON.stringify(state.betItemIds));
        },
        setBetItem: (state, action) =>
        {
            const index = findIndex(state.betItems, { id: action.payload.uniqueId });
            action.payload.dublicate = state.betItems[index].dublicate;
            // action.payload.notCompatible = state.betItems[index].notCompatible;
            state.betItems[index] = action.payload;
        },
        updateBetItem: (state, action) =>
        {
            const index = findIndex(state.betItems, { id: action.payload.betId });

            const oldBetItemData: BetItemData = state.betItems[index] as BetItemData;
            const newBetItemData: BetItemData = action.payload.betItemData;

            // if (newBetItemData.oddValue)
            // {
            //     if (state.oddsChangeMode === ChangeModes.ANY || state.oddsChangeMode === ChangeModes.HIGHER && newBetItemData.oddValue > oldBetItemData.oddValue)
            //     {
            //         newBetItemData.oddInitValue = newBetItemData.oddValue;
            //     }
            // }

            state.betItems[index] = merge(oldBetItemData, newBetItemData);
        },
        repeatStake: (state) =>
        {
            for (let i: number = 1; i < state.betItems.length; ++i)
            {
                state.betItems[i].stake = state.betItems[0].stake;
            }
        },
        removeAllBetItems: (state) =>
        {
            BetItemModelList.getInstance().removeAllItems();
            localStorage.setItem(getBetSlipKey(), JSON.stringify([]));

            return {
                ...initialState,
                oddsChangeMode: state.oddsChangeMode,
                expressBonus: state.expressBonus,
                freeBetBonus: state.freeBetBonus,
                wageringBonus: state.wageringBonus,
                bonusAmount: state.bonusAmount
            };
        },
        removeUnavailableBetItems: (state) =>
        {
            for (let i: number = state.betItems.length - 1; i >= 0; --i)
            {
                if (state.betItems[i].removed || state.betItems[i].lock)
                {
                    state.betItems.splice(i, 1);
                    state.betItemIds.splice(i, 1);
                }
            }

            localStorage.setItem(getBetSlipKey(), JSON.stringify(state.betItemIds));
        },
        changeMultipleBetStake: (state, action) =>
        {
            state.multipleBetStake = action.payload;
        },
        changeSmallViewStake: (state, action) =>
        {
            state.smallViewStake = action.payload;
        },
        changeOddsChangeMode: (state, action) =>
        {
            state.oddsChangeMode = action.payload;
            localStorage.setItem("oddsChangeMode", action.payload);
        },
        changeCashoutChangeMode: (state, action) =>
        {
            state.cashoutChangeMode = action.payload;
            localStorage.setItem("cashoutChangeMode", action.payload);
        },
        changeSystemBetStake: (state, action) =>
        {
            state.systemBetStake = action.payload;
        },
        changeSystemBetSelection: (state, action) =>
        {
            state.systemBetSelection = action.payload;
        },
        setBetProcessing: (state, action) =>
        {
            state.isBetProcessing = action.payload;

            for (let i: number = 0; i < state.betItems.length; ++i)
            {
                state.betItems[i].reofferId = null;
            }

            state.multiBetReofferId = null;
        },
        setBetError: (state, action) =>
        {
            state.placeBetError = action.payload;
        },
        setReoffer: (state, action) =>
        {
            state.multiBetReofferId = action.payload.reofferId;

            if (state.betType === BetTypes.ACCUMULATOR)
            {
                state.multipleBetStake = action.payload.stake;
            }
            else
            {
                const variants: number = factorial(state.betItems.length) / (factorial(state.systemBetSelection) * factorial(state.betItems.length - state.systemBetSelection));

                state.systemBetStake = (parseFloat(action.payload.stake) / variants).toFixed(2);
            }
        },
        setSelectedBonus: (state, action) =>
        {
            state.selectedBonus = action.payload.bonus;
            state.expressBonusPercent = action.payload.bonusPercent;
        },
        setExpressBonusChecked: (state, action) =>
        {
            state.expressBonusChecked = action.payload;
        },
        setFreeBetBonus: (state, action) =>
        {
            state.freeBetBonus = action.payload;
        },
        setWageringBonus: (state, action) =>
        {
            state.wageringBonus = action.payload;
        },
        setBonusAmount: (state, action) =>
        {
            state.bonusAmount = action.payload;
        },
        setWallet: (state, action) =>
        {
            state.wallet = action.payload.wallet;

            if (action.payload.hasOwnProperty("forceSelected"))
            {
                state.walletForceSelected = action.payload.forceSelected;
            }
        },
        setIsFullScreen: (state, action) =>
        {
            state.isFullScreen = action.payload;
        },
        setStreamSource: (state, action) =>
        {
            state.streamSource = action.payload;
        },
        setVbttopBetSlipOpen: (state, action) =>
        {
            state.vbttopBetSlipOpen = action.payload;
        }
    },
    extraReducers: (builder) =>
    {
        builder.addCase(expressBonus.fulfilled, (state, action) =>
        {
            state.expressBonus = action.payload;
        });
    }
});

export const betItemsSelector = (state: RootState) => state.betSlip.betItems;

export const multipleBetTotalOdds: (state: RootState) => number = createSelector(betItemsSelector, (betItems: BetItemData[]) =>
{
    let odds: number = 1;

    for (let i: number = 0; i < betItems.length; ++i)
    {
        if (betItems[i].eachWaySelected)
        {
            odds *= (parseFloat(betItems[i].oddValue) + 1 + (parseFloat(betItems[i].oddValue) - 1) / betItems[i].extraInfo?.race_place_factor) * 0.5;
        }
        else
        {
            odds *= parseFloat(betItems[i].oddValue);
        }
    }

    return odds;
});

function factorial(value: number): number
{
    let result = 1;

    for (let i: number = 1; i <= value; ++i)
    {
        result *= i;
    }

    return result;
}

const systemBetSelection = (state: RootState) => state.betSlip.systemBetSelection;

export const systemBetVariantsCount: (state: RootState) => number = createSelector(betItemsSelector, systemBetSelection, (betItems: BetItemData[], systemCount) =>
{
    const count: number = betItems.length;

    if (count < 3)
    {
        return 0;
    }

    return factorial(count) / (factorial(systemCount) * factorial(count - systemCount));
});

function sumOfOddsSubsets(betItems: BetItemData[]): number[][]
{
    const count: number = betItems.length;
    const from: number = betItems.length;

    const oddsSubsets: number[][] = [];

    for (let ind: number = 0; ind < count + 1; ind++)
    {
        oddsSubsets[ind] = [];

        for (let ind1: number = 0; ind1 < from + 1; ind1++)
        {
            oddsSubsets[ind][ind1] = 0;
        }
    }

    oddsSubsets[0][0] = 1;

    for (let i: number = 1; i <= count; ++i)
    {
        oddsSubsets[i][0] = 1;
        for (let j: number = 1; j <= from && j <= i; ++j)
        {
            let oddValue: number = parseFloat(betItems[i - 1].oddValue);

            if (betItems[i - 1].eachWaySelected)
            {
                oddValue = (oddValue + 1 + (oddValue - 1) / betItems[i - 1].extraInfo?.race_place_factor) * 0.5;
            }

            oddsSubsets[i][j] = oddsSubsets[i - 1][j] + oddsSubsets[i - 1][j - 1] * oddValue;
        }
    }

    return oddsSubsets;
}

export const systemBetTotalOdds: (state: RootState) => number = createSelector(betItemsSelector, systemBetSelection, (betItems: BetItemData[], systemCount) =>
{
    const oddsArray: number[][] = sumOfOddsSubsets(betItems);

    return oddsArray[betItems.length][systemCount];
});

export const {
    setSelectedTab,
    setBetType,
    addBetItem,
    setQuickBetSlipOpened,
    removeBetItem,
    setBetItem,
    updateBetItem,
    repeatStake,
    removeAllBetItems,
    removeUnavailableBetItems,
    changeMultipleBetStake,
    changeSmallViewStake,
    changeOddsChangeMode,
    changeCashoutChangeMode,
    changeSystemBetStake,
    changeSystemBetSelection,
    setBetProcessing,
    setBetError,
    setReoffer,
    setSelectedBonus,
    setExpressBonusChecked,
    setFreeBetBonus,
    setWageringBonus,
    setBonusAmount,
    setWallet,
    setIsFullScreen,
    setStreamSource,
    setVbttopBetSlipOpen
} = betSlipSlice.actions;

export default betSlipSlice.reducer;
