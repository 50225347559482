import {
    DateFields,
    GameChannels,
    GameFeeds,
    GameFields,
    GameRequest,
    RequestCommandEvent,
    RequestCriteriaField,
    RequestCriteriaLevel, RequestCriteriaNumericOperator,
    RequestGamesCount,
    RequestSelector,
    SportFields
} from "@platform/stream-client";
import StreamService from "../../services/sport-data/StreamService";
import { StreamServiceEvent } from "../../services/sport-data/events/StreamServiceEvent";
import SportData from "../../data/sport/SportData";
import itemIndexInOrderHelper from "../../utils/helpers/ItemIndexInOrderHelper";
import { getStoreState, storeDispatch } from "../../redux/store";
import { setPrematchSports, updatePrematchSport } from "../../redux/slice/prematchSportsSlice";

class PrematchSportsModel
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    private static _instance: PrematchSportsModel;
    private _sportsCommand: GameRequest;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(cl: Enforce)
    {

    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public static getInstance(): PrematchSportsModel
    {
        if (this._instance == null)
        {
            this._instance = new PrematchSportsModel(new Enforce());
        }

        return this._instance;
    }

    public run(): void
    {
        if (StreamService.getInstance().ready)
        {
            this.sendSportsCommand();
        }
        else
        {
            StreamService.getInstance().addEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        }
    }

    public clearRequest(): void
    {
        storeDispatch(setPrematchSports(null));

        this.clear();
    }

    // --------------------------------------------------------------------------
    //
    // Private methods
    //
    // --------------------------------------------------------------------------
    private sendSportsCommand(): void
    {
        this.clear();
        this._sportsCommand = new GameRequest(GameChannels.ACTIVE, true, "prematch sports");

        const sportSelector: RequestSelector = new RequestSelector(GameFields.SPORT);
        sportSelector.add(SportFields.ID, SportFields.ALIAS, SportFields.NAME, SportFields.ORDER);

        const gameCriteria: RequestCriteriaLevel = new RequestCriteriaLevel();
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.FEED, GameFeeds.PREMATCH));
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.ACTIVE, true));

        if (getStoreState().prematchSports.selectedFilter)
        {
            const dateCriteria: RequestCriteriaLevel = new RequestCriteriaLevel(GameFields.DATE);
            dateCriteria.addSubCriteria(new RequestCriteriaNumericOperator(DateFields.START, RequestCriteriaNumericOperator.LOWER_OR_EQUAL, Math.round(new Date().getTime() / 1000) + getStoreState().prematchSports.selectedFilter * 60 * 60));
            this._sportsCommand.addCriteria(dateCriteria);
        }

        this._sportsCommand.addSelector(sportSelector, new RequestGamesCount());
        this._sportsCommand.addCriteria(gameCriteria);
        this._sportsCommand.addEventListener(RequestCommandEvent.DATA, this.onSportsCommandUpdateHandler);

        StreamService.getInstance().sendRequest(this._sportsCommand);
    }

    private onStreamServiceReadyHandler: (event: StreamServiceEvent) => void = (event: StreamServiceEvent): void =>
    {
        StreamService.getInstance().removeEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        this.sendSportsCommand();
    };

    private onSportsCommandUpdateHandler: (event: RequestCommandEvent) => void = (event: RequestCommandEvent): void =>
    {
        if (event.fullUpdate)
        {
            const sports: SportData[] = [];
            let sportData: SportData;

            for (const aggregatedData of event.data)
            {
                sportData = new SportData(aggregatedData.sport);
                sportData.gamesCount = aggregatedData.game;
                sports.splice(itemIndexInOrderHelper(sportData.order, sports), 0, sportData);
            }

            storeDispatch(setPrematchSports(sports));
        }
        else
        {
            if (event.data[0])
            {
                storeDispatch(updatePrematchSport(event.data[0]));
            }
        }
    };

    private clear(): void
    {
        if (this._sportsCommand)
        {
            StreamService.getInstance().removeRequest(this._sportsCommand);
            this._sportsCommand.removeEventListener(RequestCommandEvent.DATA, this.onSportsCommandUpdateHandler);
            this._sportsCommand = null;
        }
    }
}

class Enforce
{

}

export default PrematchSportsModel;