import { immerable } from "immer";
import { findIndex, merge } from "lodash-es";
import MarketRowData from "./MarketRowData";
import OddData from "./OddData";
import { IMarketData } from "../../../typings/sport-data/IMarketData";
import GameData from "./GameData";
import { IOddData } from "../../../typings/sport-data/IOddData";
import itemIndexInObjectHelper from "../../utils/helpers/ItemIndexInObjectHelper";
import { GameFeeds, GameTypes } from "@platform/stream-client";
import outrightOddSortHelper from "../../utils/helpers/OutrightOddSortHelper";
import marketRowSortHelper from "../../utils/helpers/MarketRowSortHelper";
import oddSortHelper from "../../utils/helpers/OddSortHelper";

class MarketData
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    [immerable] = true;

    private _id: string;
    private _alias: string;
    private _name: string;
    private _order: number;
    private _column: number;
    private _lock: boolean;
    private _originId: string;
    private _priority: string;
    private _gameId: string;
    private _odds: OddData[];
    private _marketRows: MarketRowData[];
    private _types: string[];
    private _side: boolean;
    private _line: GameFeeds;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(market: IMarketData, game: GameData)
    {
        this._id = market.id;
        this._alias = market.alias;
        this._name = market.name;
        this._order = market.order;
        this._column = market.column;
        this._lock = !!market.lock;
        this._originId = market.origin_id;
        this._priority = market.priority;
        this._side = market.side;
        this._line = game?.line;
        this._types = market.types || [];
        this._gameId = game.id;

        this._odds = [];
        this._marketRows = [];

        for (const key in market.odds)
        {
            const odd: IOddData = market.odds[key];
            const marketRowIndex: number = itemIndexInObjectHelper(this._marketRows, odd.specs || {});
            let marketRowData: MarketRowData;

            if (marketRowIndex === -1)
            {
                marketRowData = new MarketRowData();
                marketRowData.id = odd.specs || {};

                marketRowData.pushOdd(new OddData(odd, this, game.line, game.source));
                this.pushMarketRow(marketRowData);
            }
            else
            {
                marketRowData = this.marketRows[marketRowIndex];
                marketRowData.pushOdd(new OddData(odd, this, game.line, game.source));
            }

            marketRowData.odds.sort(game.type === GameTypes.OUTRIGHT ? outrightOddSortHelper : oddSortHelper);
        }

        this.marketRows.sort(marketRowSortHelper);
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    public get id(): string
    {
        return this._id;
    }

    public set id(value: string)
    {
        this._id = value;
    }

    public get alias(): string
    {
        return this._alias;
    }

    public set alias(value: string)
    {
        this._alias = value;
    }

    public get name(): string
    {
        return this._name;
    }

    public set name(value: string)
    {
        this._name = value;
    }

    public get order(): number
    {
        return this._order;
    }

    public set order(value: number)
    {
        this._order = value;
    }

    public get column(): number
    {
        return this._column;
    }

    public set column(value: number)
    {
        this._column = value;
    }

    public get lock(): boolean
    {
        return this._lock;
    }

    public set lock(value: boolean)
    {
        this._lock = value;
    }

    public get originId(): string
    {
        return this._originId;
    }

    public set originId(value: string)
    {
        this._originId = value;
    }

    public get priority(): string
    {
        return this._priority;
    }

    public set priority(value: string)
    {
        this._priority = value;
    }

    public get gameId(): string
    {
        return this._gameId;
    }

    public set gameId(value: string)
    {
        this._gameId = value;
    }

    public get odds(): OddData[]
    {
        return this._odds;
    }

    public set side(value: boolean)
    {
        this._side = value;
    }

    public get side(): boolean
    {
        return this._side;
    }

    public set line(value: GameFeeds)
    {
        this._line = value;
    }

    public get line(): GameFeeds
    {
        return this._line;
    }

    public set types(value: string[])
    {
        this._types = value;
    }

    public get types(): string[]
    {
        return this._types;
    }

    public set odds(value: OddData[])
    {
        this._odds = value;
    }

    public get marketRows(): MarketRowData[]
    {
        return this._marketRows;
    }

    public set marketRows(value: MarketRowData[])
    {
        this._marketRows = value;
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------

    public pushMarketRow(marketRow: MarketRowData): void
    {
        this._marketRows.push(marketRow);
    }

    public update(market: IMarketData, game: GameData): void
    {
        if (market.odds)
        {
            for (const oddKey in market.odds)
            {
                const odd: IOddData = market.odds[oddKey];
                const marketRowIndex: number = itemIndexInObjectHelper(this.marketRows, odd.specs || {});
                let marketRowData: MarketRowData;
                let oddData: OddData;
                let oddIndex: number;

                if (odd._new)
                {
                    if (marketRowIndex === -1)
                    {
                        marketRowData = new MarketRowData();
                        marketRowData.id = odd.specs || {};
                        marketRowData.pushOdd(new OddData(odd, this, game.line, game.source));
                        marketRowData.odds.sort(game.type === GameTypes.OUTRIGHT ? outrightOddSortHelper : oddSortHelper);
                        this.marketRows.push(marketRowData);
                    }
                    else
                    {
                        marketRowData = this.marketRows[marketRowIndex];
                        marketRowData.odds.push(new OddData(odd, this, game.line, game.source));
                        marketRowData.odds.sort(game.type === GameTypes.OUTRIGHT ? outrightOddSortHelper : oddSortHelper);
                    }
                }
                else if (odd._remove)
                {
                    if (marketRowIndex !== -1)
                    {
                        marketRowData = this.marketRows[marketRowIndex];
                        oddIndex = findIndex(marketRowData.odds, { id: oddKey });
                        marketRowData.odds.splice(oddIndex, 1);

                        if (marketRowData.odds.length === 0)
                        {
                            this.marketRows.splice(marketRowIndex, 1);
                        }
                    }
                }
                else
                {
                    marketRowData = this.marketRows[marketRowIndex];
                    oddIndex = findIndex(marketRowData.odds, { id: oddKey });

                    oddData = marketRowData.odds[oddIndex];
                    oddData.update(odd);
                    marketRowData.odds.sort(game.type === GameTypes.OUTRIGHT ? outrightOddSortHelper : oddSortHelper);
                }
            }

            this.marketRows.sort(marketRowSortHelper);

            delete market.odds;
        }

        for (const key in market)
        {
            if (market[key] && typeof market[key] === "object")
            {
                this[key] = merge(this[key], market[key]);
            }
            else
            {
                this[key] = market[key];
            }
        }
    }
}

export default MarketData;