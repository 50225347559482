import axios from "axios";
import { DOMAIN, HOST } from "../../../utils/constants/Config";

const urlPrefix: string = `${DOMAIN}/${HOST}/user/api/v1`;

export function getUserConfig(siteId: string, userId: string)
{
    return axios.get(`${urlPrefix}/user/${siteId}-${userId}/limit/sportsbook`, {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": SportWidgetOptions.token
        }
    }).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}
