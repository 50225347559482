import React, { ReactNode } from "react";
import styles from "./error-boundary.module.scss";

interface IErrorBoundaryProps
{
    children?: ReactNode;
}

interface IErrorBoundaryState
{
    hasError: boolean;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState>
{
    constructor(props)
    {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error)
    {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo)
    {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);

        let reloadCount: number = localStorage.hasOwnProperty("reloadCount") ? parseInt(localStorage.getItem("reloadCount")) : 0;
        reloadCount++;
        localStorage.setItem("reloadCount", reloadCount.toString());

        if (reloadCount < 3 && error.message.indexOf("Failed to fetch dynamically imported module") !== -1)
        {
            location.reload();
        }
    }

    render()
    {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError)
        {
            // You can render any custom fallback UI
            return <h1 className={styles.text}>Something went wrong.</h1>;
        }

        return children;
    }
}

export default ErrorBoundary;
