function itemIndexInObjectHelper(arr: {}[], obj): number
{
    let index: number = -1;

    for (let i: number = 0; i < arr.length; ++i)
    {
        if (JSON.stringify(obj) === JSON.stringify(arr[i]["id"]))
        {
            index = i;

            break;
        }
    }

    return index;
}

export default itemIndexInObjectHelper;