enum SportTypes
{
    SOCCER = "sr:sport:1",
    BASKETBALL = "sr:sport:2",
    BASEBALL = "sr:sport:3",
    ICE_HOCKEY = "sr:sport:4",
    TENNIS = "sr:sport:5",
    HANDBALL = "sr:sport:6",
    FLOORBALL = "sr:sport:7",
    GOLF = "sr:sport:9",
    BOXING = "sr:sport:10",
    MOTOR_SPORT = "sr:sport:11",
    RUGBY = "sr:sport:12",
    AUSSIE_RULES = "sr:sport:13",
    WINTER_SPORTS = "sr:sport:14",
    BANDY = "sr:sport:15",
    AMERICAN_FOOTBALL = "sr:sport:16",
    CYCLING = "sr:sport:17",
    SPECIALS = "sr:sport:18",
    SNOOKER = "sr:sport:19",
    TABLE_TENNIS = "sr:sport:20",
    CRICKET = "sr:sport:21",
    DARTS = "sr:sport:22",
    VOLLEYBALL = "sr:sport:23",
    FIELD_HOCKEY = "sr:sport:24",
    POOL = "sr:sport:25",
    WATERPOLO = "sr:sport:26",
    GAELIC_SPORTS = "sr:sport:27",
    CURLING = "sr:sport:28",
    FUTSAL = "sr:sport:29",
    OLYMPICS = "sr:sport:30",
    BADMINTON = "sr:sport:31",
    BOWLS = "sr:sport:32",
    CHESS = "sr:sport:33",
    BEACH_VOLLEYBALL = "sr:sport:34",
    NETBALL = "sr:sport:35",
    ATHLETICS = "sr:sport:36",
    SQUASH = "sr:sport:37",
    RINK_HOCKEY = "sr:sport:38",
    LACROSSE = "sr:sport:39",
    FORMULA1 = "sr:sport:40",
    BIKES = "sr:sport:41",
    DTM = "sr:sport:42",
    ALPINE = "sr:sport:43",
    BIATHLON = "sr:sport:44",
    BOBSLEIGH = "sr:sport:45",
    CROSS_COUNTRY = "sr:sport:46",
    NORDIC_COMBINED = "sr:sport:47",
    SKI_JUMPING = "sr:sport:48",
    SNOWBOARD = "sr:sport:49",
    SPEED_SKATING = "sr:sport:50",
    LUGE = "sr:sport:51",
    SWIMMING = "sr:sport:52",
    FINNISH_BASEBALL = "sr:sport:53",
    SOFTBALL = "sr:sport:54",
    HORSE_RACING = "sr:sport:55",
    SCHWINGEN = "sr:sport:56",
    INLINE_HOCKEY = "sr:sport:57",
    GREYHOUND = "sr:sport:58",
    RUGBY_LEAGUE = "sr:sport:59",
    BEACH_SOCCER = "sr:sport:60",
    PESAPALLO = "sr:sport:61",
    STREET_HOCKEY = "sr:sport:62",
    WORLD_CHAMPIONSHIP = "sr:sport:63",
    ROWING = "sr:sport:64",
    SNOWBOARD_AFU = "sr:sport:65",
    SNOWBOARD_ASP = "sr:sport:66",
    MOTOGP = "sr:sport:67",
    MOTO2 = "sr:sport:68",
    _125CC = "sr:sport:69",
    NASCAR = "sr:sport:70",
    PADEL = "sr:sport:71",
    CANOEING = "sr:sport:72",
    HORSEBALL = "sr:sport:73",
    AQUATICS = "sr:sport:74",
    ARCHERY = "sr:sport:75",
    EQUESTRIAN = "sr:sport:76",
    FENCING = "sr:sport:77",
    GYMNASTICS = "sr:sport:78",
    JUDO = "sr:sport:79",
    MODERN_PENTATHLON = "sr:sport:80",
    SAILING = "sr:sport:81",
    SHOOTING = "sr:sport:82",
    TAKEWONDO = "sr:sport:83",
    TRIATHLON = "sr:sport:84",
    WEIGHTLIFTING = "sr:sport:85",
    WRESTLING = "sr:sport:86",
    OLYMPCS_YOUTH = "sr:sport:87",
    CYCLING_MOUNTAIN_BIKE = "sr:sport:88",
    RIDING = "sr:sport:89",
    SURFING = "sr:sport:90",
    CYCLING_BMX = "sr:sport:91",
    CANOE_SLALOM = "sr:sport:92",
    RHYTHMIC_GYMNASTICS = "sr:sport:93",
    TRAMPOLINING = "sr:sport:94",
    SYNCHRONIZED_SWIMMING = "sr:sport:95",
    DIVING = "sr:sport:96",
    CYCLING_TRACK = "sr:sport:97",
    BEACH_TENNIS = "sr:sport:98",
    SUMO = "sr:sport:99",
    SUPERBIKE = "sr:sport:100",
    RALLY = "sr:sport:101",
    FIGURE_SKATING = "sr:sport:102",
    FREESTYLE_SKIING = "sr:sport:103",
    SKELETON = "sr:sport:104",
    SHORT_TRACK_SPEED_SKATING = "sr:sport:105",
    SOCCER_MYTHICAL = "sr:sport:106",
    E_SPORT = "sr:sport:107",
    E_SPORT_COUNTER_STRIKE = "sr:sport:109",
    E_SPORT_LEAGUE_OF_LEGENDS = "sr:sport:110",
    E_SPORT_DOTA = "sr:sport:111",
    E_SPORT_STARCRAFT = "sr:sport:112",
    E_SPORT_HEARTHSTONE = "sr:sport:113",
    E_SPORT_HEROES_OF_THE_STORM = "sr:sport:114",
    E_SPORT_WORLD_OF_TANKS = "sr:sport:115",
    MMA = "sr:sport:117",
    E_SPORT_CALL_OF_DUTY = "sr:sport:118",
    E_SPORT_VAINGLORY = "sr:sport:120",
    E_SPORT_OVERWATCH = "sr:sport:121",
    E_SPORT_WARCRAFT = "sr:sport:122",
    GAELIC_FOOTBALL = "sr:sport:135",
    GAELIC_HURLING = "sr:sport:136",
    KABADDI = "sr:sport:138",
    E_GAMES = "ls:sport:687890",
    MOTORCYCLING = "ls:sport:1149102",
    POLITICS = "ls:sport:1149109"
}

export default SportTypes;