export default [
    "sbBrand",
    "sbDarkest",
    "sbDark",
    "sbMedium",
    "sbRegular",
    "sbSemiLight",
    "sbLight",
    "sbUltraLight",
    "sbLightest",
    "sbWhite",
    "sbWarning",
    "sbSuccess",
    "sbError"
];