import { getStoreState, storeDispatch } from "../../redux/store";
import StreamService from "../../services/sport-data/StreamService";
import BetItemData from "../../data/bet-slip/BetItemData";
import {
    ExtraInfoFields,
    GameChannels,
    GameFields,
    GameRequest,
    MarketFields, OddFields, RegionFields, RequestCommandEvent, RequestCriteriaField,
    RequestCriteriaLevel,
    RequestSelector, SportFields, TournamentFields
} from "@platform/stream-client";
import { IGameData } from "../../../typings/sport-data/IGameData";
import { IMarketData } from "../../../typings/sport-data/IMarketData";
import { IOddData } from "../../../typings/sport-data/IOddData";
import { removeBetItem, setBetItem, updateBetItem } from "../../redux/slice/betSlipSlice";
import { StreamServiceEvent } from "../../services/sport-data/events/StreamServiceEvent";
import GameData from "../../data/sport/GameData";
import MarketData from "../../data/sport/MarketData";
import OddData from "../../data/sport/OddData";
import StatusTypes from "../../utils/static/StatusTypes";

export class BetItemModel
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------
    private _betItemData: BetItemData | {
        line: string,
        gameId: string,
        marketId: string,
        oddId: string,
        uniqueId: string,
        id: string
    };
    private _gameCommand: GameRequest;
    private _betItemUpdateData: {};

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------
    constructor(betItemData: BetItemData | {
        line: string,
        gameId: string,
        marketId: string,
        oddId: string,
        uniqueId: string,
        id: string
    })
    {
        this._betItemData = betItemData;

        if (StreamService.getInstance().ready)
        {
            this.sendGameCommand();
        }
        else
        {
            StreamService.getInstance().addEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        }
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------
    public get uniqueId(): string
    {
        return this._betItemData.uniqueId;
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public update(): void
    {
        if (this._betItemUpdateData)
        {
            storeDispatch(updateBetItem({ betId: this._betItemData.uniqueId, betItemData: this._betItemUpdateData }));

            this._betItemUpdateData = null;
        }
    }

    public clearRequest(): void
    {
        if (this._gameCommand)
        {
            StreamService.getInstance().removeRequest(this._gameCommand);
            this._gameCommand.removeEventListener(RequestCommandEvent.DATA, this.onGamesCommandUpdateHandler);
            this._gameCommand = null;
        }
    }

    // --------------------------------------------------------------------------
    //
    // Private methods
    //
    // --------------------------------------------------------------------------
    private sendGameCommand(): void
    {
        this._gameCommand = new GameRequest(GameChannels.ACTIVE, true, "bet item");

        const sportSelector: RequestSelector = new RequestSelector(GameFields.SPORT);
        sportSelector.add(SportFields.ID, SportFields.ALIAS, SportFields.NAME);

        const regionSelector: RequestSelector = new RequestSelector(GameFields.REGION);
        regionSelector.add(RegionFields.ID, RegionFields.ALIAS, RegionFields.NAME);

        const tournamentSelector: RequestSelector = new RequestSelector(GameFields.TOURNAMENT);
        tournamentSelector.add(TournamentFields.ID, TournamentFields.ALIAS, TournamentFields.NAME);

        const marketSelector: RequestSelector = new RequestSelector(GameFields.MARKETS);
        const oddSelector: RequestSelector = new RequestSelector(MarketFields.ODDS);
        const matchSelector: RequestSelector = new RequestSelector(GameFields.MATCH_INFO);

        const gameSelector: RequestSelector = new RequestSelector();
        gameSelector.add(GameFields.ID, GameFields.HOME_TEAM, GameFields.AWAY_TEAM, GameFields.OUTRIGHT, GameFields.LOCK, GameFields.FEED, GameFields.STATUS, GameFields.TYPE, GameFields.DATE, GameFields.SOURCE, GameFields.EXTRA_INFO);

        const gameCriteria: RequestCriteriaLevel = new RequestCriteriaLevel();
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.ID, this._betItemData.gameId));
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.FEED, this._betItemData.line));
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.ACTIVE, true));

        const marketCriteria: RequestCriteriaLevel = new RequestCriteriaLevel(GameFields.MARKETS);
        marketCriteria.addSubCriteria(new RequestCriteriaField(MarketFields.ID, this._betItemData.marketId));

        const oddCriteria: RequestCriteriaLevel = new RequestCriteriaLevel(MarketFields.ODDS);
        oddCriteria.addSubCriteria(new RequestCriteriaField(OddFields.ID, this._betItemData.oddId));

        this._gameCommand.addSelector(sportSelector, regionSelector, tournamentSelector, gameSelector, marketSelector, oddSelector, matchSelector);
        this._gameCommand.addCriteria(gameCriteria, marketCriteria, oddCriteria);

        this._gameCommand.addEventListener(RequestCommandEvent.DATA, this.onGamesCommandUpdateHandler);

        StreamService.getInstance().sendRequest(this._gameCommand);
    };

    private onGamesCommandUpdateHandler: (event: RequestCommandEvent) => void = (event: RequestCommandEvent): void =>
    {
        const game: IGameData = event.data[0];

        if (game)
        {
            if (event.fullUpdate)
            {
                const gameData: GameData = new GameData(game);

                if (game.markets && Object.values(game.markets)[0])
                {
                    const marketData: MarketData = new MarketData(Object.values(game.markets)[0], gameData);

                    if (Object.values(game.markets)[0].odds && Object.values(Object.values(game.markets)[0].odds)[0])
                    {
                        const oddData: OddData = new OddData(Object.values(Object.values(game.markets)[0].odds)[0], marketData, gameData.line, game.source);

                        const arr: string[] = this._betItemData.uniqueId.split("/");

                        if (arr.length > 5 && arr[5] === "sp")
                        {
                            oddData.isSP = true;
                        }

                        storeDispatch(setBetItem(new BetItemData(gameData, marketData, oddData, false)));
                    }
                    else
                    {
                        storeDispatch(removeBetItem(this._betItemData.uniqueId));
                    }
                }
                else
                {
                    storeDispatch(removeBetItem(this._betItemData.uniqueId));
                }
            }
            else
            {
                const market: IMarketData = game && game.markets && game.markets[this._betItemData.marketId] ? game.markets[this._betItemData.marketId] : null;

                this._betItemUpdateData = {};

                if (game && game._remove)
                {
                    this._betItemUpdateData["removed"] = true;
                }
                else if (market)
                {
                    if (!market.hasOwnProperty("settlement"))
                    {
                        const odd: IOddData = market && market.odds && market.odds[this._betItemData.oddId] ? market.odds[this._betItemData.oddId] : null;

                        if (game.home && game.home.name)
                        {
                            this._betItemUpdateData["team1Name"] = game.home.name;
                        }

                        if (game.away && game.away.name)
                        {
                            this._betItemUpdateData["team2Name"] = game.away.name;
                        }

                        if (game.outright && game.outright.name)
                        {
                            this._betItemUpdateData["outrightName"] = game.outright.name;
                        }

                        if (game.hasOwnProperty("lock"))
                        {
                            this._betItemUpdateData["lock"] = game.lock;
                        }

                        if (market && market.name)
                        {
                            this._betItemUpdateData["marketName"] = market.name;
                        }

                        if (odd && odd.name)
                        {
                            this._betItemUpdateData["oddName"] = odd.name;
                        }

                        if (odd && odd.value)
                        {
                            this._betItemUpdateData["oddValue"] = odd.value;

                            if (event.fullUpdate)
                            {
                                this._betItemUpdateData["oddInitValue"] = odd.value;
                            }
                        }

                        if (odd && odd.hasOwnProperty("suspend"))
                        {
                            this._betItemUpdateData["lock"] = odd.suspend;
                        }

                        if ((market && market._remove) || (odd && odd._remove))
                        {
                            this._betItemUpdateData["removed"] = true;
                        }

                        if (game && game._new || market && market._new || odd && odd._new)
                        {
                            this._betItemUpdateData["removed"] = false;
                            this._betItemUpdateData["lock"] = market.hasOwnProperty("lock") ? game.lock || market.lock : false;
                        }

                        if (market && market.hasOwnProperty("lock"))
                        {
                            this._betItemUpdateData["lock"] = game.lock || market.lock;
                        }
                    }
                }

                if (game.match_info)
                {
                    this._betItemUpdateData["matchInfo"] = game.match_info;
                }

                if (game.status)
                {
                    this._betItemUpdateData["status"] = game.status;

                    if ([StatusTypes.HALFTIME, StatusTypes.AWAITING_EXTRA_TIME, StatusTypes.EXTRA_TIME_HALFTIME].indexOf(game.status.origin_id) !== -1)
                    {
                        if (this._betItemUpdateData["matchInfo"])
                        {
                            this._betItemUpdateData["matchInfo"].clock_stoppage_time = undefined;
                        }
                    }
                }

                if (game.extra_info)
                {
                    this._betItemUpdateData["extraInfo"] = game.extra_info;
                }

                // if (!getStoreState().betSlip.isBetProcessing)
                // {
                this.update();
                // }
            }
        }
        else
        {
            storeDispatch(removeBetItem(this._betItemData.uniqueId));
        }
    };

    private onStreamServiceReadyHandler: (event: StreamServiceEvent) => void = (event: StreamServiceEvent): void =>
    {
        StreamService.getInstance().removeEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        this.sendGameCommand();
    };
}