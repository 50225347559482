import { FavoriteTournamentModel } from "./FavoriteTournamentModel";

export class FavoriteTournamentModelList
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------
    private static _instance: FavoriteTournamentModelList;
    private _items: FavoriteTournamentModel[] = [];

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------
    constructor(cl: Enforce)
    {

    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public static getInstance(): FavoriteTournamentModelList
    {
        if (this._instance == null)
        {
            this._instance = new FavoriteTournamentModelList(new Enforce());
        }

        return this._instance;
    }

    public addItem(favoriteTournamentModel: FavoriteTournamentModel): void
    {
        this._items.push(favoriteTournamentModel);
    }

    public removeItem(index: number): void
    {
        this._items[index].clearRequest();
        this._items.splice(index, 1);
    }

    public removeAllItems(): void
    {
        for (const item of this._items)
        {
            item.clearRequest();
        }

        this._items = [];
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    // --------------------------------------------------------------------------
    //
    // Private properties
    //
    // --------------------------------------------------------------------------
}

class Enforce
{

}
