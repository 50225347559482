import { IMatchInfoData } from "../../../typings/sport-data/IMatchInfoData";
import SportTypes from "../static/SportTypes";
import StatusTypes from "../static/StatusTypes";
import SiteDirection from "../static/SiteDirection";
import GameData from "../../data/sport/GameData";
import StreamService from "../../services/sport-data/StreamService";

export const getSportIconName = (alias: string) =>
{
    switch (alias)
    {
        case "Ice Hockey":
            return "Hockey-Icon";
        case "Table Tennis":
            return "Table-Tennis-Icon";
        case "American Football":
            return "American-Football-Icon";
        case "Rugby":
            return "Rugby-league-Icon";
        case "Futsal":
            return "Futsall-Icon";
        case "Boxing":
            return "Box-Icon";
        case "Gaelic Football":
        case "Gaelic sports":
            return "Gaelic-football-Icon";
        case "Gaelic Hurling":
            return "Gaelic-Hurling-Icon";
        case "Waterpolo":
            return "Water-polo-Icon";
        case "Aussie Rules":
            return "Australian-Rules-Icon";
        case "Beach Volley":
            return "Beach-Volleyball-Icon";
        case "League of Legends":
            return "League-of-Legends-Icon";
        case "Field hockey":
            return "Field-Hockey-Icon";
        case "Horse racing":
            return "Horse-racing-Icon";
        case "E-Games":
            return "E-Sports-Icon";
        case "Rugby League":
            return "Rugby-league-Icon";
        case "Formula 1":
            return "Formula-1-Icon";
        case "Pesapallo":
            return "Pasepallo-Icon";
        case "Beach Soccer":
            return "Beach-Soccer-iconn";
        case "TV-Games":
            return "TV-Icon";
        case "Padel":
            return "Paddel-Tennis-Icon";
        case "Ski Jumping":
            return "Ski-jumping-Icon";
        case "Nascar Cup Series":
        case "Stock Car Racing":
            return "Nascar-Icon";
        case "Motorbikes":
        case "Motorcycle Racing":
            return "Motocycling-Icon";
        case "Basketball 3x3":
            return "Basketball-Icon";
        case "Alpine Skiing":
            return "Alpine-skiing-Icon";
        case "NetBallShots":
            return "NetBallShots";
        default:
            return `${alias}-Icon`;
    }
};

export const getSportHasDraw = (sportId: string) =>
{
    return [SportTypes.SOCCER, SportTypes.ICE_HOCKEY, SportTypes.HANDBALL, SportTypes.FUTSAL, SportTypes.WATERPOLO, SportTypes.KABADDI, SportTypes.GAELIC_FOOTBALL, SportTypes.GAELIC_HURLING, SportTypes.GAELIC_SPORTS, SportTypes.RUGBY, SportTypes.RUGBY_LEAGUE].indexOf(sportId as SportTypes) !== -1;
};

export const showTimer = (sportId: SportTypes, statusId: StatusTypes, matchInfo: IMatchInfoData) =>
{
    switch (sportId)
    {
        case SportTypes.SOCCER:
            if (!matchInfo?.time)
            {
                return false;
            }

            switch (statusId)
            {
                case StatusTypes.FIRST_HALF:
                case StatusTypes.SECOND_HALF:
                case StatusTypes.FIRST_EXTRA:
                case StatusTypes.SECOND_EXTRA:
                    return true;
            }

            switch (`ls_${statusId}`)
            {
                case StatusTypes.LS_FIRST_HALF:
                case StatusTypes.LS_SECOND_HALF:
                case StatusTypes.LS_FIRST_EXTRA:
                case StatusTypes.LS_SECOND_EXTRA:
                    return true;
            }
            return false;
        case SportTypes.BASKETBALL:
            if (!(matchInfo?.time || matchInfo?.clock_remaining_time_in_period))
            {
                return false;
            }

            switch (statusId)
            {
                case StatusTypes.FIRST_QUARTER:
                case StatusTypes.SECOND_QUARTER:
                case StatusTypes.THIRD_QUARTER:
                case StatusTypes.FOURTH_QUARTER:
                    return true;
            }

            switch (`ls_${statusId}`)
            {
                case StatusTypes.LS_FIRST_QUARTER:
                case StatusTypes.LS_SECOND_QUARTER:
                case StatusTypes.LS_THIRD_QUARTER:
                case StatusTypes.LS_FOURTH_QUARTER:
                    return true;
            }
            return false;
        case SportTypes.ICE_HOCKEY:
            if (!(matchInfo?.time || matchInfo?.clock_remaining_time_in_period))
            {
                return false;
            }

            switch (statusId)
            {
                case StatusTypes.FIRST_PERIOD:
                case StatusTypes.SECOND_PERIOD:
                case StatusTypes.THIRD_PERIOD:
                    return true;
            }

            switch (`ls_${statusId}`)
            {
                case StatusTypes.LS_FIRST_PERIOD:
                case StatusTypes.LS_SECOND_PERIOD:
                case StatusTypes.LS_THIRD_PERIOD:
                    return true;
            }
            return false;
        case SportTypes.FUTSAL:
            if (!(matchInfo?.time || matchInfo?.clock_remaining_time_in_period))
            {
                return false;
            }

            switch (statusId)
            {
                case StatusTypes.FIRST_HALF:
                case StatusTypes.SECOND_HALF:
                    return true;
            }

            switch (`ls_${statusId}`)
            {
                case StatusTypes.LS_FIRST_HALF:
                case StatusTypes.LS_SECOND_HALF:
                    return true;
            }
            return false;
        case SportTypes.HANDBALL:
            if (!(matchInfo?.time || matchInfo?.clock_remaining_time_in_period))
            {
                return false;
            }

            switch (statusId)
            {
                case StatusTypes.FIRST_HALF:
                case StatusTypes.SECOND_HALF:
                    return true;
            }

            switch (`ls_${statusId}`)
            {
                case StatusTypes.LS_FIRST_HALF:
                case StatusTypes.LS_SECOND_HALF:
                    return true;
            }
            return false;
        default:
            return false;
    }
};

export const getSiteLanguage: () => string = () =>
{
    if (SportWidgetOptions.locale)
    {
        if (/[a-zA-Z]{2}-[a-zA-Z]{2}$/.test(SportWidgetOptions.locale))
        {
            return SportWidgetOptions.locale;
        }

        switch (SportWidgetOptions.locale)
        {
            case "en":
                return "en-GB";
            case "ru":
                return "ru-RU";
            case "tr":
                return "tr-TR";
            case "ko":
                return "ko-KR";
            case "fa":
                return "fa-IR";
            case "uz":
                return "uz-UZ";
            case "es":
                return "es-ES";
            case "fr":
                return "fr-FR";
            case "ka":
                return "ka-GE";
            case "el":
                return "el-GR";
            case "ja":
                return "ja-JP";
            case "sw":
                return "sw-KE";
            case "pt":
                return "pt-PT";
            case "ar":
                return "ar-SA";
            case "hi":
                return "hi-IN";
            case "br":
                return "pt-BR";
            case "az":
                return "az-AZ";
            case "he":
                return "he-IL";
            case "iw":
                return "iw-IL";
            case "de":
                return "de-DE";
            case "se":
                return "sv-SE";
            default:
                return "en-GB";
        }
    }
    else
    {
        return "en-GB";
    }
};

export const getSiteDirection: () => SiteDirection = () =>
{
    if (SportWidgetOptions.locale)
    {
        switch (SportWidgetOptions.locale)
        {
            case "fa":
            case "ar":
            case "he":
            case "iw":
                return SiteDirection.RIGHT;
            default:
                return SiteDirection.LEFT;
        }
    }
    else
    {
        return SiteDirection.LEFT;
    }
};

export const hasSportBackground: (sportId: string) => boolean = (sportId: string) =>
{
    if ((window as any).sportWidgetLazyOptions.vbttopView)
    {
        switch (sportId)
        {
            case SportTypes.SOCCER:
            case SportTypes.BASKETBALL:
            case SportTypes.BASEBALL:
            case SportTypes.ICE_HOCKEY:
            case SportTypes.TENNIS:
            case SportTypes.HANDBALL:
            case SportTypes.GOLF:
            case SportTypes.RUGBY:
            case SportTypes.AMERICAN_FOOTBALL:
            case SportTypes.TABLE_TENNIS:
            case SportTypes.VOLLEYBALL:
            case SportTypes.FUTSAL:
            case SportTypes.BADMINTON:
            case SportTypes.BEACH_VOLLEYBALL:
            case SportTypes.CRICKET:
            case SportTypes.AUSSIE_RULES:
            case SportTypes.SNOOKER:
            case SportTypes.DARTS:
            case SportTypes.E_GAMES:
            case SportTypes.BOWLS:
            case SportTypes.BOXING:
            case SportTypes.WATERPOLO:
            case SportTypes.CYCLING:
            case SportTypes.GAELIC_SPORTS:
            case SportTypes.MOTORCYCLING:
            case SportTypes.NASCAR:
            case SportTypes.POLITICS:
            case SportTypes.RUGBY_LEAGUE:
                return true;
            default:
                return false;
        }
    }
    else
    {
        switch (sportId)
        {
            case SportTypes.SOCCER:
            case SportTypes.BASKETBALL:
            case SportTypes.BASEBALL:
            case SportTypes.ICE_HOCKEY:
            case SportTypes.TENNIS:
            case SportTypes.HANDBALL:
            case SportTypes.GOLF:
            case SportTypes.RUGBY:
            case SportTypes.AMERICAN_FOOTBALL:
            case SportTypes.TABLE_TENNIS:
            case SportTypes.VOLLEYBALL:
            case SportTypes.FUTSAL:
            case SportTypes.BADMINTON:
            case SportTypes.BEACH_VOLLEYBALL:
            case SportTypes.CRICKET:
                return true;
            default:
                return false;
        }
    }
};

export const isVideoEnabled: (game: GameData, videoEnabled: boolean) => boolean = (game: GameData, videoEnabled: boolean): boolean =>
{
    return !!(videoEnabled && game.video);
};

const sirWidgetEnabled: (sportId: string) => boolean = (sportId: string) =>
{
    switch (sportId)
    {
        case SportTypes.SOCCER:
        case SportTypes.BASKETBALL:
        case SportTypes.TENNIS:
            return true;
        default:
            return false;
    }
};

export const widgetEnabled: (game: GameData, statsScoreEnabled: boolean, betRadarWidgetEnabled: boolean, liveAnimationEnabled: boolean, decimalWidgetEnabled: boolean) => boolean = (game, statsScoreEnabled, betRadarWidgetEnabled, liveAnimationEnabled, decimalWidgetEnabled): boolean =>
{
    if (betRadarWidgetEnabled && sirWidgetEnabled(game.sport.id))
    {
        return true;
    }

    if (statsScoreEnabled && game.stats_score?.live)
    {
        return true;
    }

    if (liveAnimationEnabled && game.animation?.livescore?.stream)
    {
        return true;
    }

    if (decimalWidgetEnabled && game.animation?.decimal?.stream)
    {
        return true;
    }

    return false;
};

export const oddNameHelper: (name: string, marketAlias?: string) => string = (name: string, marketAlias): string =>
{
    const ignoreMarketAliases: string[] = ["Goal Before", "Goal After"];

    if (getSiteDirection() === SiteDirection.LEFT || ignoreMarketAliases.indexOf(marketAlias) !== -1)
    {
        return name;
    }

    const regExpException = /\d+-\d+/;
    const regExpScore = /(?<score>\d+:\d+)/g;
    const regExpScore1 = /(?<score>\d+-\d+)/g;
    const regExpCount = /(?<count>\d+(?:.\d+)?(?<sign>-|\+))$/;
    const regExpHcp = /(?<hcp>(?<sign>-|\+)\d+(?:.\d+)?)/;

    if (regExpException.exec(name))
    {
        return name;
    }

    let match = regExpScore.exec(name);

    if (match)
    {
        const str: string = match.groups.score;

        if (str.split(":").indexOf("00") === -1)
        {
            const revert = str.split(":").reverse().join(":");
            name = name.replace(str, revert);

            match = regExpScore.exec(name);

            if (match)
            {
                const str: string = match.groups.score;

                if (str.split(":").indexOf("00") === -1)
                {
                    const revert = str.split(":").reverse().join(":");
                    name = name.replace(str, revert);
                }
            }
        }
    }

    match = regExpScore1.exec(name);

    if (match)
    {
        const str: string = match.groups.score;
        const revert = str.split("-").reverse().join("-");
        name = name.replace(str, revert);
    }

    match = regExpCount.exec(name);

    if (match)
    {
        const str: string = match.groups.count;
        const revert = match.groups.sign.concat(str.replace(match.groups.sign, ""));

        name = name.replace(str, revert);
    }
    else
    {
        match = regExpHcp.exec(name);

        if (match)
        {
            const str: string = match.groups.hcp;
            const revert = str.replace(match.groups.sign, "").concat(match.groups.sign);

            name = name.replace(str, revert);
        }
    }

    return name;
};

export const numberSignHelper: (value: number) => string = (value): string =>
{
    if (!value)
    {
        return "";
    }

    if (getSiteDirection() === SiteDirection.LEFT)
    {
        return (value > 0 ? "+" : "") + Math.round(value);
    }

    let resultStr: string = (value > 0 ? "+" : "") + Math.round(value);
    const regExpAmerican = /(?<type>(?<sign>-|\+)\d+(?:.\d+)?)/;
    const match = regExpAmerican.exec(resultStr);

    if (match)
    {
        const str: string = match.groups.type;
        const revert = str.replace(match.groups.sign, "").concat(match.groups.sign);

        resultStr = resultStr.replace(str, revert);
        return resultStr;
    }
};

export const marketNameHelper: (name: string) => string = (name: string): string =>
{
    if (getSiteDirection() === SiteDirection.LEFT)
    {
        return name;
    }

    const regExpScore = /(?<score>\d+:\d+)/g;

    let marketName = regExpScore.exec(name);

    if (marketName)
    {
        const str: string = marketName.groups.score;

        if (str.split(":").indexOf("00") === -1)
        {
            const revert = str.split(":").reverse().join(":");
            name = name.replace(str, revert);

            marketName = regExpScore.exec(name);

            if (marketName)
            {
                const str: string = marketName.groups.score;

                if (str.split(":").indexOf("00") === -1)
                {
                    const revert = str.split(":").reverse().join(":");
                    name = name.replace(str, revert);
                }
            }
        }
    }

    return name;
};

export const scoreHelper: (value: string) => string = (value: string): string =>
{
    if (getSiteDirection() === SiteDirection.LEFT)
    {
        return value;
    }

    const arr: string[] = value.split(":");

    if (arr.length === 2)
    {
        return `${arr[1]}:${arr[0]}`;
    }
    else
    {
        return value;
    }
};

export const sportAliasFormatter: (value: string) => string = (value: string): string =>
{
    return value.split(" ").join("-").toLowerCase();
};

export const getBetSlipKey: () => string = (): string =>
{
    let betItemsKey: string = "betItems";

    if (StreamService.getInstance().source)
    {
        betItemsKey = `${betItemsKey}-${StreamService.getInstance().source}`;
    }

    return betItemsKey;
};