import { immerable } from "immer";
import OddData from "./OddData";

class MarketRowData
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    [immerable] = true;

    private _id: {};
    private _odds: OddData[];

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor()
    {
        this._odds = [];
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    public get id(): {}
    {
        return this._id;
    }

    public set id(value: {})
    {
        this._id = value;
    }

    public get odds(): OddData[]
    {
        return this._odds;
    }

    public set odds(value: OddData[])
    {
        this._odds = value;
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------

    public pushOdd(odd: OddData): void
    {
        this._odds.push(odd);
    }
}

export default MarketRowData;