import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash-es";
import GameData from "../../data/sport/GameData";
import { GameFields } from "@platform/stream-client";
import gameSortHelper from "../../utils/helpers/GameSortHelper";

interface ICalendarCurrentSportState
{
    games: GameData[];
}

const initialState: ICalendarCurrentSportState = {
    games: null
};

export const calendarCurrentSportSlice = createSlice({
    name: "calendarCurrentSport",
    initialState,
    reducers: {
        setCalendarGames: (state, action) =>
        {
            state.games = action.payload;
        },
        updateCalendarGame: (state, action) =>
        {
            const gameIndex = findIndex(state.games, { id: action.payload._id });
            const gameData = state.games[gameIndex] as GameData;
            gameData.update(action.payload);
        },
        removeCalendarGame: (state, action) =>
        {
            const gameIndex = findIndex(state.games, { id: action.payload._id });

            if (gameIndex !== -1)
            {
                state.games.splice(gameIndex, 1);
            }
        },
        setCalendarGameMarkets: (state, action) =>
        {
            for (const game of action.payload)
            {
                const gameIndex = findIndex(state.games, { id: game._id });
                const gameData = state.games[gameIndex] as GameData;

                gameData.addMarkets(game);
                gameData.markets_count = game.markets_count;
                gameData.odds_count = game.odds_count;
            }
        },
        addCalendarGameMarket: (state, action) =>
        {
            const gameIndex = findIndex(state.games, { id: action.payload._id });

            if (gameIndex !== -1)
            {
                const gameData = state.games[gameIndex] as GameData;
                gameData.addMarkets(action.payload);
            }
        },
        updateCalendarGameMarket: (state, action) =>
        {
            const gameIndex = findIndex(state.games, { id: action.payload._id });

            if (gameIndex !== -1)
            {
                const gameData = state.games[gameIndex] as GameData;
                gameData.update(action.payload);

                if (action.payload.hasOwnProperty(GameFields.FEATURED))
                {
                    state.games.sort(gameSortHelper);
                }
            }
        }
    }
});

export const {
    setCalendarGames,
    updateCalendarGame,
    removeCalendarGame,
    setCalendarGameMarkets,
    addCalendarGameMarket,
    updateCalendarGameMarket
} = calendarCurrentSportSlice.actions;

export default calendarCurrentSportSlice.reducer;