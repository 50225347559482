function itemIndexInOrderHelper(order: number, arr: any[]): number
{
    let index: number = arr.length;

    for (let i: number = 0; i < arr.length; ++i)
    {
        if (order < arr[i].order)
        {
            index = i;

            break;
        }
    }

    return index;
}

export default itemIndexInOrderHelper;
