import { EventDispatcher } from "../../utils/events/EventDispatcher";
import {
    DeviceTypes,
    RequestBase,
    StreamClient,
    StreamClientEvent,
    StreamConfig
} from "@platform/stream-client";
import { STREAM_DOMAIN, STREAM_PATH } from "../../utils/constants/Config";
import { getSiteLanguage } from "../../utils/helpers/SportHelpers";
import { StreamServiceEvent } from "./events/StreamServiceEvent";
import FeedDownService from "./FeedDownService";

class StreamService extends EventDispatcher
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    private static _instance: StreamService;
    private _streamClient: StreamClient;
    private _ready: boolean = false;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(cl: Enforce)
    {
        super();
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------

    public static getInstance(): StreamService
    {
        if (this._instance == null)
        {
            this._instance = new StreamService(new Enforce());
        }

        return this._instance;
    }

    public sendRequest(command: RequestBase): void
    {
        this._streamClient.sendRequest(command);
    }

    public removeRequest(command: RequestBase): void
    {
        this._streamClient.dropRequest(command);
    }

    public updateLocale(locale: string): void
    {
        this._streamClient.updateLocale(locale);
    }

    public close(): void
    {
        this._ready = false;
        this._streamClient.close();
    }

    public get ready(): boolean
    {
        return this._ready;
    }

    public get reconnecting(): boolean
    {
        return this._streamClient.reconnecting;
    }

    public get source(): string
    {
        return this._streamClient.source;
    }

    public setDataSource(dataSource: string)
    {
        const wsConnectionProtocol: string = (window.location.protocol === "https:" || window.location.hostname === "localhost" ? "wss" : "ws");

        const lang: string = getSiteLanguage();

        const domain: string = STREAM_DOMAIN;
        const streamPath: string = STREAM_PATH;

        // if (window.location.host.indexOf("localhost") === -1)
        // {
        //     domain = window.location.host;
        // }

        this._streamClient = new StreamClient(new StreamConfig(`${wsConnectionProtocol}://${domain}${streamPath}`, lang, SportWidgetOptions.externalSiteId, dataSource, true));
        this._streamClient.addEventListener(StreamClientEvent.UPDATE_START, this.onDataUpdateHandler);
        this._streamClient.addEventListener(StreamClientEvent.UPDATE_COMPLETE, this.onDataUpdateHandler);
        this._streamClient.addEventListener(StreamClientEvent.SESSION_READY, this.onSessionReadyHandler);
        this._streamClient.addEventListener(StreamClientEvent.RECONNECT_ATTEMPT, this.onClientReconnectAttemptHandler);
        this._streamClient.addEventListener(StreamClientEvent.RECONNECT_COMPLETE, this.onClientReconnectCompleteHandler);
        this._streamClient.connect();
    }

    // --------------------------------------------------------------------------
    //
    // Private methods
    //
    // --------------------------------------------------------------------------
    private onSessionReadyHandler: (event: StreamClientEvent) => void = (event: StreamClientEvent) =>
    {
        FeedDownService.getInstance();

        this._ready = true;
        this.dispatchEvent(new StreamServiceEvent(StreamServiceEvent.READY));
    };

    private onClientReconnectAttemptHandler: (event: StreamClientEvent) => void = (event: StreamClientEvent) =>
    {
        this.dispatchEvent(new StreamServiceEvent(StreamServiceEvent.RECONNECT_START));
    };

    private onClientReconnectCompleteHandler: (event: StreamClientEvent) => void = (event: StreamClientEvent) =>
    {
        this.dispatchEvent(new StreamServiceEvent(StreamServiceEvent.RECONNECT_COMPLETE));
    };

    private onDataUpdateHandler: (event: StreamClientEvent) => void = (event: StreamClientEvent) =>
    {
        if (event.type === StreamClientEvent.UPDATE_START)
        {
            this.dispatchEvent(new StreamServiceEvent(StreamServiceEvent.LOCALIZE_START));
        }
        else if (event.type === StreamClientEvent.UPDATE_COMPLETE)
        {
            this.dispatchEvent(new StreamServiceEvent(StreamServiceEvent.LOCALIZE_STREAM_COMPLETE));
        }
    };
}

class Enforce
{

}

export default StreamService;
