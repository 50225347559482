import { Event } from "../../../utils/events/Event";

export class NotificationServiceEvent extends Event
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------
    public static READY: string = "notificationServiceReady";

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------
    constructor(type: string)
    {
        super(type);
    }
}
