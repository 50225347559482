import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash-es";
import GameData from "../../data/sport/GameData";
import { GameFields } from "@platform/stream-client";
import gameSortHelper from "../../utils/helpers/GameSortHelper";

interface IHighlightsCurrentSportState
{
    games: GameData[];
}

const initialState: IHighlightsCurrentSportState = {
    games: null
};

export const highlightsCurrentSportSlice = createSlice({
    name: "highlightsCurrentSport",
    initialState,
    reducers: {
        setHighlightsGames: (state, action) =>
        {
            state.games = action.payload;
        },
        updateHighlightsGame: (state, action) =>
        {
            const gameIndex = findIndex(state.games, { id: action.payload._id });
            const gameData = state.games[gameIndex] as GameData;
            gameData.update(action.payload);
        },
        removeHighlightsGame: (state, action) =>
        {
            const gameIndex = findIndex(state.games, { id: action.payload._id });

            if (gameIndex !== -1)
            {
                state.games.splice(gameIndex, 1);
            }
        },
        setHighlightsGameMarkets: (state, action) =>
        {
            for (const game of action.payload)
            {
                const gameIndex = findIndex(state.games, { id: game._id });
                const gameData = state.games[gameIndex] as GameData;

                gameData.addMarkets(game);
                gameData.markets_count = game.markets_count;
                gameData.odds_count = game.odds_count;
            }
        },
        addHighlightsGameMarket: (state, action) =>
        {
            const gameIndex = findIndex(state.games, { id: action.payload._id });

            if (gameIndex !== -1)
            {
                const gameData = state.games[gameIndex] as GameData;
                gameData.addMarkets(action.payload);
            }
        },
        updateHighlightsGameMarket: (state, action) =>
        {
            const gameIndex = findIndex(state.games, { id: action.payload._id });

            if (gameIndex !== -1)
            {
                const gameData = state.games[gameIndex] as GameData;
                gameData.update(action.payload);

                if (action.payload.hasOwnProperty(GameFields.FEATURED))
                {
                    state.games.sort(gameSortHelper);
                }
            }
        }
    }
});

export const {
    setHighlightsGames,
    updateHighlightsGame,
    removeHighlightsGame,
    setHighlightsGameMarkets,
    addHighlightsGameMarket,
    updateHighlightsGameMarket
} = highlightsCurrentSportSlice.actions;

export default highlightsCurrentSportSlice.reducer;