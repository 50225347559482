import { immerable } from "immer";
import { BetLines, BusterTypes, GameFeeds, GameTypes } from "@platform/stream-client";
import OddData from "../sport/OddData";
import MarketData from "../sport/MarketData";
import GameData from "../sport/GameData";
import { IMatchInfoData } from "../../../typings/sport-data/IMatchInfoData";
import { IStatusData } from "../../../typings/sport-data/IStatusData";
import StreamService from "../../services/sport-data/StreamService";
import { IOddProbability } from "../../../typings/sport-data/IOddData";
import { IExtraInfoData } from "../../../typings/sport-data/IExtraInfoData";

class BetItemData
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    [immerable] = true;

    private _id: string;
    private _gameId: string;
    private _sportId: string;
    private _sportAlias: string;
    private _regionId: string;
    private _tournamentId: string;
    private _marketId: string;
    private _marketAlias: string;
    private _marketTypes: string[];
    private _oddId: string;
    private _uniqueId: string;
    private _line: GameFeeds;
    private _gameType: GameTypes;
    private _oddInitValue: string;
    private _oddValue: string;
    private _team1Name: string;
    private _team2Name: string;
    private _outrightName: string;
    private _marketName: string;
    private _oddName: string;
    private _matchInfo: IMatchInfoData;
    private _extraInfo: IExtraInfoData;
    private _status: IStatusData;
    private _startDate: number;
    private _stake: string;
    private _mtsId: string;
    private _lock: boolean;
    private _removed: boolean;
    private _dublicate: boolean;
    // private _notCompatible: boolean;
    private _source: string;
    private _banker: boolean;
    private _buster: BusterTypes;
    private _eachWaySelected: boolean;
    private _reofferStake: string;
    private _reofferId: string;
    private _errorCode: string;
    private _probability: IOddProbability;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(game: GameData, market: MarketData, odd: OddData, needToSendBetradarStats: boolean = true)
    {
        this._id = odd.uniqueId;
        this._gameId = game.id;
        this._sportId = game.sport?.id;
        this._sportAlias = game.sport?.alias;
        this._regionId = game.region?.id;
        this._tournamentId = game.tournament?.id;
        this._marketId = market.id;
        this._marketAlias = market.alias;
        this._marketTypes = market.types;
        this._oddId = odd.id;
        this._uniqueId = odd.uniqueId;
        this._line = game.line;
        this._gameType = game.type;
        this._oddInitValue = odd.value;
        this._oddValue = odd.value;
        this._team1Name = game.type === GameTypes.MATCH ? game.home.name : "";
        this._team2Name = game.type === GameTypes.MATCH ? game.away.name : "";
        this._outrightName = (game.type === GameTypes.OUTRIGHT || game.type === GameTypes.RACE) ? game.outright.name : "";
        this._marketName = market.name;
        this._oddName = odd.name;
        this._matchInfo = game.match_info;
        this._extraInfo = game.extra_info;
        this._status = game.status;
        this._startDate = game.date?.start;
        this._stake = "";
        this._lock = false;
        this._removed = false;
        this._dublicate = false;
        // this._notCompatible = false;
        this._source = game.source;
        this._banker = false;
        this._buster = odd?.isSP ? BusterTypes.START_PRICE : null;
        this._eachWaySelected = false;
        this._reofferStake = null;
        this._reofferId = null;
        this._errorCode = "";
        this._probability = odd.probability;

        if (needToSendBetradarStats && StreamService.getInstance().source === "br" && SportWidgetOptions?.onSportStatsHandler)
        {
            let mainMarket: MarketData = null;

            if (game.markets)
            {
                for (const market of Object.values(game.markets))
                {
                    if (market?.types?.indexOf("winner") !== -1)
                    {
                        mainMarket = market;

                        break;
                    }
                }
            }

            SportWidgetOptions.onSportStatsHandler(
                {
                    event: "srt.betslip",
                    payload: {
                        sportsEvent:
                            {
                                match_id: game.id?.split(":")[2],
                                sr_match_id: game.id,
                                team_home_name: game.home?.alias || "",
                                team_home_name_short: "",
                                team_home_logo: "",
                                team_away_name: game.away?.alias || "",
                                team_away_name_short: "",
                                team_away_logo: "",
                                scheduled: game.date?.start_pretty,
                                status: game.status?.alias || (GameFeeds.PREMATCH === game.line ? "Not Started" : ""),
                                tournament_name: game.tournament?.alias,
                                sport: game.sport?.alias,
                                odds_home: mainMarket?.marketRows[0]?.odds?.filter(item => item.id === "1")[0]?.value || mainMarket?.marketRows[0]?.odds?.filter(item => item.id === "4")[0]?.value || "",
                                odds_draw: mainMarket?.marketRows[0]?.odds?.filter(item => item.id === "2")[0]?.value || "",
                                odds_away: mainMarket?.marketRows[0]?.odds?.filter(item => item.id === "3")[0]?.value || mainMarket?.marketRows[0]?.odds?.filter(item => item.id === "5")[0]?.value || "",
                                language: SportWidgetOptions.locale
                            },
                    },
                });
        }
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------
    public get id(): string
    {
        return this._id;
    }

    public get gameId(): string
    {
        return this._gameId;
    }

    public get sportId(): string
    {
        return this._sportId;
    }

    public get sportAlias(): string
    {
        return this._sportAlias;
    }

    public get regionId(): string
    {
        return this._regionId;
    }

    public get tournamentId(): string
    {
        return this._tournamentId;
    }

    public get marketId(): string
    {
        return this._marketId;
    }

    public get marketAlias(): string
    {
        return this._marketAlias;
    }

    public get marketTypes(): string[]
    {
        return this._marketTypes;
    }

    public get oddId(): string
    {
        return this._oddId;
    }

    public get uniqueId(): string
    {
        return this._uniqueId;
    }

    public get line(): string
    {
        return this._line;
    }

    public get gameType(): GameTypes
    {
        return this._gameType;
    }

    public get oddInitValue(): string
    {
        return this._oddInitValue;
    }

    public set oddInitValue(value: string)
    {
        this._oddInitValue = value;
    }

    public get oddValue(): string
    {
        return this._oddValue;
    }

    public set oddValue(value: string)
    {
        this._oddValue = value;
    }

    public get team1Name(): string
    {
        return this._team1Name;
    }

    public set team1Name(value: string)
    {
        this._team1Name = value;
    }

    public get team2Name(): string
    {
        return this._team2Name;
    }

    public set team2Name(value: string)
    {
        this._team2Name = value;
    }

    public get outrightName(): string
    {
        return this._outrightName;
    }

    public set outrightName(value: string)
    {
        this._outrightName = value;
    }

    public get marketName(): string
    {
        return this._marketName;
    }

    public set marketName(value: string)
    {
        this._marketName = value;
    }

    public get oddName(): string
    {
        return this._oddName;
    }

    public set oddName(value: string)
    {
        this._oddName = value;
    }

    public get matchInfo(): IMatchInfoData
    {
        return this._matchInfo;
    }

    public set matchInfo(value: IMatchInfoData)
    {
        this._matchInfo = value;
    }

    public get extraInfo(): IExtraInfoData
    {
        return this._extraInfo;
    }

    public set extraInfo(value: IExtraInfoData)
    {
        this._extraInfo = value;
    }

    public get status(): IStatusData
    {
        return this._status;
    }

    public set status(value: IStatusData)
    {
        this._status = value;
    }

    public get startDate(): number
    {
        return this._startDate;
    }

    public get stake(): string
    {
        return this._stake;
    }

    public set stake(value: string)
    {
        this._stake = value;
    }

    public get mtsId(): string
    {
        return this._mtsId;
    }

    public get lock(): boolean
    {
        return this._lock;
    }

    public set lock(value: boolean)
    {
        this._lock = value;
    }

    public get removed(): boolean
    {
        return this._removed;
    }

    public set removed(value: boolean)
    {
        this._removed = value;
    }

    public get dublicate(): boolean
    {
        return this._dublicate;
    }

    public set dublicate(value: boolean)
    {
        this._dublicate = value;
    }

    // public get notCompatible(): boolean
    // {
    //     return this._notCompatible;
    // }
    //
    // public set notCompatible(value: boolean)
    // {
    //     this._notCompatible = value;
    // }

    public get source(): string
    {
        return this._source;
    }

    public set source(value: string)
    {
        this._source = value;
    }

    public get isChanged(): boolean
    {
        return this._oddInitValue !== this._oddValue;
    }

    public get banker(): boolean
    {
        return this._banker;
    }

    public set banker(value: boolean)
    {
        this._banker = value;
    }

    public get buster(): BusterTypes
    {
        return this._buster;
    }

    public set buster(value: BusterTypes)
    {
        this._buster = value;
    }

    public get eachWaySelected(): boolean
    {
        return this._eachWaySelected;
    }

    public set eachWaySelected(value: boolean)
    {
        this._eachWaySelected = value;
    }

    public get reofferStake(): string
    {
        return this._reofferStake;
    }

    public set reofferStake(value: string)
    {
        this._reofferStake = value;
    }

    public get reofferId(): string
    {
        return this._reofferId;
    }

    public set reofferId(value: string)
    {
        this._reofferId = value;
    }

    public get errorCode(): string
    {
        return this._errorCode;
    }

    public set errorCode(value: string)
    {
        this._errorCode = value;
    }

    public get probability(): IOddProbability
    {
        return this._probability;
    }

    public set probability(value: IOddProbability)
    {
        this._probability = value;
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public toObject(): {
        line: BetLines,
        event: string,
        market: string,
        odd: string,
        value: string,
        ways?: boolean,
        banker?: boolean,
        buster?: BusterTypes
    }
    {
        const betRequest: any = {};
        betRequest["line"] = this._line;
        betRequest["event"] = this._gameId;
        betRequest["market"] = this._marketId;
        betRequest["odd"] = this._oddId;
        betRequest["value"] = this._oddValue;
        betRequest["banker"] = this._banker;
        betRequest["ew"] = this._eachWaySelected;

        if (this._buster)
        {
            betRequest["buster"] = this._buster;
        }

        return betRequest;
    }
}

export default BetItemData;