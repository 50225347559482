import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash-es";
import SportData from "../../data/sport/SportData";
import { FavoriteGameModelList } from "../../models/sport-data/favorite/FavoriteGameModelList";
import { FavoriteGameModel } from "../../models/sport-data/favorite/FavoriteGameModel";
import { FavoriteTournamentModelList } from "../../models/sport-data/favorite/FavoriteTournamentModelList";
import { FavoriteTournamentModel } from "../../models/sport-data/favorite/FavoriteTournamentModel";
import TournamentData from "../../data/sport/TournamentData";
import GameData from "../../data/sport/GameData";

export interface ILiveSportsState
{
    favoriteGameIds: string[];
    favoriteTournamentIds: string[];
    favoriteGamesSports: SportData[];
    favoriteTournamentsSports: SportData[];
}

const storage = localStorage.hasOwnProperty("favorite") ? JSON.parse(localStorage.getItem("favorite")) : {};

const initialState: ILiveSportsState = {
    favoriteGameIds: storage?.games || [],
    favoriteTournamentIds: storage?.tournaments || [],
    favoriteGamesSports: [],
    favoriteTournamentsSports: []
};

const saveToStorage: (favoriteGameIds: string[], favoriteTournamentIds: string[]) => void = (favoriteGameIds: string[], favoriteTournamentIds: string[]): void =>
{
    localStorage.setItem("favorite", JSON.stringify({
        games: favoriteGameIds,
        tournaments: favoriteTournamentIds
    }));
};

export const favoriteSlice = createSlice({
    name: "favorite",
    initialState,
    reducers: {
        addFavoriteGameId: (state, action) =>
        {
            state.favoriteGameIds.push(action.payload);

            FavoriteGameModelList.getInstance().addItem(new FavoriteGameModel(action.payload));
            saveToStorage(state.favoriteGameIds, state.favoriteTournamentIds);
        },
        removeFavoriteGameId: (state, action) =>
        {
            const index: number = state.favoriteGameIds.indexOf(action.payload);
            state.favoriteGameIds.splice(index, 1);

            FavoriteGameModelList.getInstance().removeItem(index);
            saveToStorage(state.favoriteGameIds, state.favoriteTournamentIds);

            for (let i: number = 0; i < state.favoriteGamesSports.length; ++i)
            {
                const sport: SportData = state.favoriteGamesSports[i] as SportData;

                for (let j: number = 0; j < sport.tournaments.length; ++j)
                {
                    const tournament: TournamentData = sport.tournaments[j];

                    for (let k: number = 0; k < tournament.games.length; ++k)
                    {
                        const game: GameData = tournament.games[k];

                        if (game.id === action.payload)
                        {
                            if (tournament.gamesCount > 1)
                            {
                                tournament.games.splice(k, 1);
                                tournament.gamesCount--;
                                sport.gamesCount--;
                            }
                            else
                            {
                                if (sport.gamesCount > 1)
                                {
                                    sport.tournaments.splice(j, 1);
                                    sport.gamesCount--;
                                }
                                else
                                {
                                    state.favoriteGamesSports.splice(i, 1);
                                }
                            }
                        }
                    }
                }
            }
        },
        removeAllFavoriteGameId: (state) =>
        {
            state.favoriteGameIds = [];
            saveToStorage(state.favoriteGameIds, state.favoriteTournamentIds);
        },
        addFavoriteTournamentId: (state, action) =>
        {
            state.favoriteTournamentIds.push(action.payload);

            FavoriteTournamentModelList.getInstance().addItem(new FavoriteTournamentModel(action.payload));
            saveToStorage(state.favoriteGameIds, state.favoriteTournamentIds);
        },
        removeFavoriteTournamentId: (state, action) =>
        {
            const index: number = state.favoriteTournamentIds.indexOf(action.payload);
            state.favoriteTournamentIds.splice(index, 1);

            FavoriteTournamentModelList.getInstance().removeItem(index);
            saveToStorage(state.favoriteGameIds, state.favoriteTournamentIds);

            for (let i: number = 0; i < state.favoriteTournamentsSports.length; ++i)
            {
                const sport: SportData = state.favoriteTournamentsSports[i] as SportData;

                for (let j: number = 0; j < sport.tournaments.length; ++j)
                {
                    const tournament: TournamentData = sport.tournaments[j];

                    if (tournament.getFullId() === action.payload)
                    {
                        if (sport.tournaments.length === 1)
                        {
                            state.favoriteTournamentsSports.splice(i, 1);
                        }
                        else
                        {
                            state.favoriteTournamentsSports[i].tournaments.splice(j, 1);
                        }
                    }
                }
            }
        },
        setFavoriteGamesSports: (state, action) =>
        {
            state.favoriteGamesSports = action.payload;
        },
        removeFavoriteSportGame: (state, action) =>
        {
            const sportIndex = findIndex(state.favoriteGamesSports, { id: action.payload.sport.id });

            if (sportIndex !== -1)
            {
                const sport = state.favoriteGamesSports[sportIndex];
                const tournamentIndex = findIndex(sport.tournaments, { id: action.payload.tournament.id });

                if (tournamentIndex !== -1)
                {
                    const tournament = sport.tournaments[tournamentIndex];
                    const gameIndex = findIndex(tournament.games, { id: action.payload._id });

                    if (gameIndex !== -1)
                    {
                        if (tournament.gamesCount > 1)
                        {
                            tournament.games.splice(gameIndex, 1);
                            tournament.gamesCount--;
                            sport.gamesCount--;
                        }
                        else
                        {
                            if (sport.gamesCount > 1)
                            {
                                sport.tournaments.splice(tournamentIndex, 1);
                                sport.gamesCount--;
                            }
                            else
                            {
                                state.favoriteGamesSports.splice(sportIndex, 1);
                            }
                        }

                        const index: number = state.favoriteGameIds.indexOf(action.payload._id);
                        state.favoriteGameIds.splice(index, 1);

                        FavoriteGameModelList.getInstance().removeItem(index);
                        saveToStorage(state.favoriteGameIds, state.favoriteTournamentIds);
                    }
                }
            }
        },
        updateFavoriteSportGame: (state, action) =>
        {
            const sportIndex = findIndex(state.favoriteGamesSports, { id: action.payload.sport.id });

            if (sportIndex !== -1)
            {
                const sport = state.favoriteGamesSports[sportIndex];
                const tournamentIndex = findIndex(sport.tournaments, { id: action.payload.tournament.id });

                if (tournamentIndex !== -1)
                {
                    const tournament = sport.tournaments[tournamentIndex];
                    const gameIndex = findIndex(tournament.games, { id: action.payload._id });

                    if (gameIndex !== -1)
                    {
                        const gameData = state.favoriteGamesSports[sportIndex].tournaments[tournamentIndex].games[gameIndex] as GameData;
                        gameData.update(action.payload);
                    }
                }
            }
        },
        setFavoriteTournamentsSports: (state, action) =>
        {
            state.favoriteTournamentsSports = action.payload;
        },
        updateFavoriteSportTournament: (state, action) =>
        {

        }
    }
});

export const {
    addFavoriteGameId,
    removeFavoriteGameId,
    removeAllFavoriteGameId,
    addFavoriteTournamentId,
    removeFavoriteTournamentId,
    setFavoriteGamesSports,
    removeFavoriteSportGame,
    updateFavoriteSportGame,
    setFavoriteTournamentsSports,
    updateFavoriteSportTournament
} = favoriteSlice.actions;

export default favoriteSlice.reducer;
