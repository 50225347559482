import axios from "axios";
import { DOMAIN, HOST } from "../../../utils/constants/Config";

const urlPrefix: string = `${DOMAIN}/${HOST}/ui-translation/api/v1`;

export function getTranslations(locale: string)
{
    return axios.get(`${urlPrefix}/sportsBook/locale/${locale}`).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}