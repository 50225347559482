import { createSlice } from "@reduxjs/toolkit";
import GameData from "../../data/sport/GameData";

export interface IPrematchCurrentGameState
{
    game: GameData;
    openDefault: boolean;
}

const initialState: IPrematchCurrentGameState = {
    game: null,
    openDefault: false
};

export const prematchCurrentGameSlice = createSlice({
    name: "prematchCurrentGame",
    initialState,
    reducers: {
        setPrematchGame: (state, action) =>
        {
            state.game = action.payload;
        },
        updatePrematchGame: (state, action) =>
        {
            const gameData: GameData = state.game as GameData;
            gameData.update(action.payload);
        },
        setOpenPrematchDefault: (state, action) =>
        {
            state.openDefault = action.payload;
        }
    }
});

export const {
    setPrematchGame, updatePrematchGame, setOpenPrematchDefault
} = prematchCurrentGameSlice.actions;

export default prematchCurrentGameSlice.reducer;
