import { immerable } from "immer";
import { findIndex, merge } from "lodash-es";
import { GameFeeds, GameTypes } from "@platform/stream-client";
import SportTypes from "../../utils/static/SportTypes";
import MarketData from "./MarketData";
import { ITeamData } from "../../../typings/sport-data/ITeamData";
import { IStatusData } from "../../../typings/sport-data/IStatusData";
import { IDateData } from "../../../typings/sport-data/IDateData";
import { IMatchInfoData } from "../../../typings/sport-data/IMatchInfoData";
import { IExtraInfoData } from "../../../typings/sport-data/IExtraInfoData";
import { IGameData } from "../../../typings/sport-data/IGameData";
import { ITVData } from "../../../typings/sport-data/ITVData";
import { IVideoData } from "../../../typings/sport-data/IVideoData";
import { IOutrightData } from "../../../typings/sport-data/IOutrightData";
import { ISportData } from "../../../typings/sport-data/ISportData";
import { IRegionData } from "../../../typings/sport-data/IRegionData";
import { ITournamentData } from "../../../typings/sport-data/ITournamentData";
import { IMarketData } from "../../../typings/sport-data/IMarketData";
import marketSortHelper from "../../utils/helpers/MarketSortHelper";
import { IAnimationData } from "../../../typings/sport-data/IAnimationData";
import StatusTypes from "../../utils/static/StatusTypes";
import { ICompetitorData } from "../../../typings/sport-data/ICompetitorData";
import { IRoundData } from "../../../typings/sport-data/IRoundData";

class GameData
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    [immerable] = true;

    private _id: string;
    private _home: ITeamData;
    private _away: ITeamData;
    private _competitors: ICompetitorData[];
    private _outright: IOutrightData;
    private _status: IStatusData;
    private _date: IDateData;
    private _line: GameFeeds;
    private _match_info: IMatchInfoData;
    private _extra_Info: IExtraInfoData;
    private _round: IRoundData;
    private _tv: ITVData[];
    private _markets: MarketData[];
    private _marketsCount: number;
    private _oddsCount: number;
    private _marketTypes: { [type: string]: number };
    private _has_prematch: boolean;
    private _type: GameTypes;
    private _lock: boolean;
    private _video: { [type: string]: IVideoData };
    private _swap: boolean;
    private _sport: ISportData;
    private _region: IRegionData;
    private _tournament: ITournamentData;
    private _isMarketSet: boolean;
    private _stats_score: { [key: string]: string };
    private _animation: IAnimationData;
    private _source: string;
    private _featured: boolean;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(game: IGameData)
    {
        this._id = game._id;
        this._home = game.home;
        this._away = game.away;
        this._competitors = game.competitors;
        this._outright = game.outright;
        this._status = game.status;
        this._date = game.date;
        this._line = game.feed;
        this._match_info = game.match_info;
        this._extra_Info = game.extra_info;
        this._round = game.round;
        this._tv = game.tv;
        this._markets = [];
        this._marketsCount = game.markets_count;
        this._oddsCount = game.odds_count;
        this._has_prematch = game.has_prematch;
        this._type = game.type;
        this._lock = !!game.lock;
        this._marketTypes = {};
        this._video = game.video;
        this._swap = game.swap;
        this._sport = game.sport;
        this._region = game.region;
        this._tournament = game.tournament;
        this._isMarketSet = false;
        this._stats_score = game.stats_score;
        this._animation = game.animation;
        this._source = game.source;
        this._featured = game.featured;

        // if (this._match_info && !this._match_info.scores)
        // {
        //     this._match_info.scores = [{ period: "1", score: "-:-", type: "" }];
        // }

        if (game.sport && game.sport.id === SportTypes.BASEBALL && this.match_info && this.match_info.scores && this.match_info.scores.length >= 10)
        {
            for (let i: number = 9; i < this.match_info.scores.length; ++i)
            {
                this.match_info.scores[i].period = "EI";
            }
        }

        if (this._match_info && !this._match_info.score)
        {
            this._match_info.score = "-:-";
        }

        // if (this._match_info?.scores)
        // {
        //     for (let i: number = 0; i < 10; ++i)
        //     {
        //         this._match_info.scores.push({ period:`1${i}`, score: "25:18", type: "" });
        //     }
        // }

        for (const key in game.markets)
        {
            const market: IMarketData = game.markets[key];
            this.pushMarket(new MarketData(market, this));
        }

        for (const key in game.markets_show)
        {
            const market: IMarketData = game.markets_show[key];
            this.pushMarket(new MarketData(market, this));
        }

        this.markets.sort(marketSortHelper);
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    public get id(): string
    {
        return this._id;
    }

    public set id(value: string)
    {
        this._id = value;
    }

    public get home(): ITeamData
    {
        return this._home;
    }

    public set home(value: ITeamData)
    {
        this._home = value;
    }

    public get away(): ITeamData
    {
        return this._away;
    }

    public set away(value: ITeamData)
    {
        this._away = value;
    }

    public get competitors(): ICompetitorData[]
    {
        return this._competitors;
    }

    public set competitors(value: ICompetitorData[])
    {
        this._competitors = value;
    }

    public get outright(): IOutrightData
    {
        return this._outright;
    }

    public set outright(value: IOutrightData)
    {
        this._outright = value;
    }

    public get status(): IStatusData
    {
        return this._status;
    }

    public set status(value: IStatusData)
    {
        this._status = value;
    }

    public get date(): IDateData
    {
        return this._date;
    }

    public set date(value: IDateData)
    {
        this._date = value;
    }

    public get line(): GameFeeds
    {
        return this._line;
    }

    public set line(value: GameFeeds)
    {
        this._line = value;
    }

    public get match_info(): IMatchInfoData
    {
        return this._match_info;
    }

    public set match_info(value: IMatchInfoData)
    {
        this._match_info = value;
    }

    public set extra_info(value: IExtraInfoData)
    {
        this._extra_Info = value;
    }

    public get extra_info(): IExtraInfoData
    {
        return this._extra_Info;
    }

    public set round(value: IRoundData)
    {
        this._round = value;
    }

    public get round(): IRoundData
    {
        return this._round;
    }

    public get tv(): ITVData[]
    {
        return this._tv;
    }

    public set tv(value: ITVData[])
    {
        this._tv = value;
    }

    public get markets(): MarketData[]
    {
        return this._markets;
    }

    public set markets(value: MarketData[])
    {
        this._markets = value;
    }

    public get markets_count(): number
    {
        return this._marketsCount;
    }

    public set markets_count(value: number)
    {
        this._marketsCount = value;
    }

    public get odds_count(): number
    {
        return this._oddsCount;
    }

    public set odds_count(value: number)
    {
        this._oddsCount = value;
    }

    public get hasPrematch(): boolean
    {
        return this._has_prematch;
    }

    public get type(): GameTypes
    {
        return this._type;
    }

    public get lock(): boolean
    {
        return this._lock;
    }

    public set lock(value: boolean)
    {
        this._lock = value;
    }

    public set marketTypes(value: { [type: string]: number })
    {
        this._marketTypes = value;
    }

    public get marketTypes(): { [type: string]: number }
    {
        return this._marketTypes;
    }

    public get video(): { [type: string]: IVideoData }
    {
        return this._video;
    }

    public set video(value: { [type: string]: IVideoData })
    {
        this._video = value;
    }

    public get swap(): boolean
    {
        return this._swap;
    }

    public set swap(value: boolean)
    {
        this._swap = value;
    }

    public get sport(): ISportData
    {
        return this._sport;
    }

    public set sport(value: ISportData)
    {
        this._sport = value;
    }

    public get region(): IRegionData
    {
        return this._region;
    }

    public set region(value: IRegionData)
    {
        this._region = value;
    }

    public get tournament(): ITournamentData
    {
        return this._tournament;
    }

    public set tournament(value: ITournamentData)
    {
        this._tournament = value;
    }

    public get isMarketSet(): boolean
    {
        return this._isMarketSet;
    }

    public set isMarketSet(value: boolean)
    {
        this._isMarketSet = value;
    }

    public get stats_score(): { [type: string]: string }
    {
        return this._stats_score;
    }

    public set stats_score(value: { [type: string]: string })
    {
        this._stats_score = value;
    }

    public get animation(): IAnimationData
    {
        return this._animation;
    }

    public set animation(value: IAnimationData)
    {
        this._animation = value;
    }

    public get source(): string
    {
        return this._source;
    }

    public set source(value: string)
    {
        this._source = value;
    }

    public get featured(): boolean
    {
        return this._featured;
    }

    public set featured(value: boolean)
    {
        this._featured = value;
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------

    public pushMarket(market: MarketData)
    {
        this._markets.push(market);
    }

    public setMarketType(marketType: string, count: number)
    {
        this._marketTypes[marketType] = count;
    }

    public addMarkets(game: IGameData): void
    {
        this._isMarketSet = true;

        for (const key in game.markets)
        {
            const market: IMarketData = game.markets[key];
            this.pushMarket(new MarketData(market, this));
        }

        for (const key in game.markets_show)
        {
            const market: IMarketData = game.markets_show[key];
            this.pushMarket(new MarketData(market, this));
        }

        this.markets.sort(marketSortHelper);
    }

    public update(game: IGameData): void
    {
        delete game._id;

        if (game.markets)
        {
            for (const marketKey in game.markets)
            {
                const market: IMarketData = game.markets[marketKey];
                const marketIndex: number = findIndex(this.markets, { id: marketKey });

                if (market._new)
                {
                    this.markets.push(new MarketData(market, this));
                }
                else if (market._remove)
                {
                    if (marketIndex !== -1)
                    {
                        this.markets.splice(marketIndex, 1);
                    }
                }
                else
                {
                    this.markets[marketIndex].update(market, this);
                }
            }

            this.markets.sort(marketSortHelper);

            delete game.markets;
        }

        if (game.markets_show)
        {
            for (const marketKey in game.markets_show)
            {
                const market: IMarketData = game.markets_show[marketKey];
                const marketIndex: number = findIndex(this.markets, { id: marketKey });

                if (market._new)
                {
                    this.markets.push(new MarketData(market, this));
                }
                else if (market._remove)
                {
                    if (marketIndex !== -1)
                    {
                        this.markets.splice(marketIndex, 1);
                    }
                }
                else
                {
                    this.markets[marketIndex].update(market, this);
                }
            }

            this.markets.sort(marketSortHelper);

            delete game.markets_show;
        }

        for (const key in game)
        {
            if (game[key] && typeof game[key] === "object")
            {
                this[key] = merge(this[key], game[key]);

                if (game.status && [StatusTypes.HALFTIME, StatusTypes.AWAITING_EXTRA_TIME, StatusTypes.EXTRA_TIME_HALFTIME].indexOf(game.status.origin_id) !== -1)
                {
                    this.match_info.clock_stoppage_time = undefined;
                }
            }
            else
            {
                if (key === "lock")
                {
                    this._lock = !!game.lock;
                }
                else
                {
                    this[key] = game[key];
                }
            }
        }
    }
}

export default GameData;