import React, { useEffect, useState } from "react";
import ErrorBoundary from "../error-boundary/ErrorBoundary";
import parseShadowValues from "./functions/parseShadowValues";

import styles from "./colorpicker.module.scss";
import stringifyShadowValues from "./functions/stringifyShadowValues";
import Custom from "./components/Custom";
import Defaults from "./components/Defaults";
import { debounce } from "lodash-es";

const properties = ["sbBrand", "sbDarkest", "sbDark", "sbMedium", "sbRegular", "sbSemiLight", "sbLight", "sbUltraLight", "sbLightest", "sbWhite", "sbWarning", "sbSuccess", "sbError"];

const ColorPicker: React.FC = (): React.ReactElement =>
{

    const [open, setOpen] = useState<boolean>(true);

    const [values, setValues] = useState<string[]>([]);
    const [shadow, setShadow] = useState<any>({});
    const [innerShadow, setInnerShadow] = useState<any>({});
    const [custom, setCustom] = useState<boolean>(false);


    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const copyValues = () =>
    {
        let textToCopy = "";
        properties.map((prop, index) =>
        {
            textToCopy += `--${prop}:${values[index]};\n`;
        });

        textToCopy += `--sbShadow: ${stringifyShadowValues(shadow)};\n`;
        textToCopy += `--sbInsetShadow: ${stringifyShadowValues(innerShadow, "inset")};\n`;
        navigator.clipboard.writeText(textToCopy);
        setShowSuccess(true);
        setTimeout(() =>
        {
            setShowSuccess(false);
        }, 3000);
    };

    const updateValues: () => void = debounce(() =>
    {
        const newValues = [];
        const root = document.querySelector(":root");

        properties.forEach((prop) =>
        {
            newValues.push(getComputedStyle(root).getPropertyValue("--" + prop));
        });
        setValues(newValues);
        setShadow(parseShadowValues(getComputedStyle(root).getPropertyValue("--sbShadow")));
        setInnerShadow(parseShadowValues(getComputedStyle(root).getPropertyValue("--sbInsetShadow")));

    }, 10);

    useEffect(() =>
    {
        updateValues();
    }, []);


    return <ErrorBoundary>
        <div style={!open ? { transform: "translateY(calc(-100% + 29px)) translateX(-50%)" } : {}}
             className={styles.main}>
            <div className={styles.content}>
                <div onClick={() => setCustom(!custom)} className={styles.selectStyles}>
                    <b>Select style</b>
                </div>
                <Defaults closeCustom={() => setCustom(false)} setValues={setValues} setShadow={setShadow}
                          setInnerShadow={setInnerShadow} values={values}/>
                <div onClick={() => setCustom(!custom)} className={styles.customButton}>
                    <b>Customize</b>
                    {custom ? "△" : "▽"}
                </div>
                <div className={styles.customs}>
                    {custom && <Custom
                        values={values}
                        shadow={shadow}
                        innerShadow={innerShadow}
                        updateValues={updateValues}
                    />}
                </div>
                <p style={{ opacity: showSuccess ? "1" : "0" }} className={styles.successCopy}>Successfully copied
                    to clipboard</p>
                <button onClick={() => copyValues()}>COPY</button>
            </div>
            <div className={styles.arrow} onClick={() => setOpen(!open)}>
                {open ? "⬆" : "⬇"}
            </div>
        </div>
    </ErrorBoundary>;
};

export default ColorPicker;