import { IEvent } from "./IEvent";
import { IEventDispatcher } from "./IEventDispatcher";

type EventHandlerAlias = (event: IEvent) => void;

export class EventDispatcher implements IEventDispatcher
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------
    private _eventHandlers: {};

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------
    constructor()
    {
        this._eventHandlers = {};
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public addEventListener(event: string, handler: EventHandlerAlias): void
    {
        const attachedHandlers: EventHandlerAlias[] = this._eventHandlers[event] || new Array<EventHandlerAlias>();
        attachedHandlers.push(handler);
        this._eventHandlers[event] = attachedHandlers;
    }

    public removeEventListener(event: string, handler: EventHandlerAlias): void
    {
        const attachedHandlers: EventHandlerAlias[] = this._eventHandlers[event];

        if (attachedHandlers)
        {
            for (let i: number = attachedHandlers.length - 1; i > -1; --i)
            {
                if (attachedHandlers[i] === handler)
                {
                    attachedHandlers.splice(i, 1);
                }
            }
        }
    }

    public removeAllListeners(event: string): void
    {
        const attachedHandlers: EventHandlerAlias[] = this._eventHandlers[event];

        if (attachedHandlers)
        {
            attachedHandlers.length = 0;
        }
    }

    public dispatchEvent(event: IEvent): void
    {
        const attachedHandlers: EventHandlerAlias[] = this._eventHandlers[event.getType()];
        if (attachedHandlers)
        {
            for (let i: number = attachedHandlers.length - 1; i > -1; --i)
            {
                if (attachedHandlers[i])
                {
                    attachedHandlers[i](event);
                }
            }
        }
    }
}