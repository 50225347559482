import {
    GameChannels,
    GameFeeds,
    GameFields,
    GameRequest,
    RequestCommandEvent,
    RequestCriteriaField,
    RequestCriteriaLevel,
    RequestGamesCount,
    RequestSelector,
    SportFields,
} from "@platform/stream-client";
import StreamService from "../../services/sport-data/StreamService";
import { StreamServiceEvent } from "../../services/sport-data/events/StreamServiceEvent";
import SportData from "../../data/sport/SportData";
import itemIndexInOrderHelper from "../../utils/helpers/ItemIndexInOrderHelper";
import { storeDispatch } from "../../redux/store";
import { setLiveSports, updateLiveSport } from "../../redux/slice/liveSportsSlice";

class LiveSportsModel
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    private static _instance: LiveSportsModel;
    private _sportsCommand: GameRequest;
    private _isRunning: boolean = false;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(cl: Enforce)
    {

    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public static getInstance(): LiveSportsModel
    {
        if (this._instance == null)
        {
            this._instance = new LiveSportsModel(new Enforce());
        }

        return this._instance;
    }

    public run(): void
    {
        if (!this._isRunning)
        {
            this._isRunning = true;

            if (StreamService.getInstance().ready)
            {
                this.sendSportsCommand();
            }
            else
            {
                StreamService.getInstance().addEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
            }
        }
    }

    public clearRequest(): void
    {
        storeDispatch(setLiveSports(null));
        this._isRunning = false;

        if (this._sportsCommand)
        {
            StreamService.getInstance().removeRequest(this._sportsCommand);
            this._sportsCommand.removeEventListener(RequestCommandEvent.DATA, this.onSportsCommandUpdateHandler);
            this._sportsCommand = null;
        }
    }

    // --------------------------------------------------------------------------
    //
    // Private methods
    //
    // --------------------------------------------------------------------------
    private sendSportsCommand(): void
    {
        this._sportsCommand = new GameRequest(GameChannels.ACTIVE, true, "live sports");

        const sportSelector: RequestSelector = new RequestSelector(GameFields.SPORT);
        sportSelector.add(SportFields.ID, SportFields.ALIAS, SportFields.NAME, SportFields.ORDER);

        const gameCriteria: RequestCriteriaLevel = new RequestCriteriaLevel();
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.FEED, GameFeeds.LIVE));
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.ACTIVE, true));

        this._sportsCommand.addSelector(sportSelector, new RequestGamesCount());
        this._sportsCommand.addCriteria(gameCriteria);

        this._sportsCommand.addEventListener(RequestCommandEvent.DATA, this.onSportsCommandUpdateHandler);

        StreamService.getInstance().sendRequest(this._sportsCommand);
    }

    private onStreamServiceReadyHandler: (event: StreamServiceEvent) => void = (event: StreamServiceEvent): void =>
    {
        StreamService.getInstance().removeEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        this.sendSportsCommand();
    };

    private onSportsCommandUpdateHandler: (event: RequestCommandEvent) => void = (event: RequestCommandEvent): void =>
    {
        if (event.fullUpdate)
        {
            const sports: SportData[] = [];
            let sportData: SportData;

            for (const aggregatedData of event.data)
            {
                sportData = new SportData(aggregatedData.sport);
                sportData.gamesCount = aggregatedData.game;
                sports.splice(itemIndexInOrderHelper(sportData.order, sports), 0, sportData);
            }

            storeDispatch(setLiveSports(sports));
        }
        else
        {
            if (event.data[0])
            {
                storeDispatch(updateLiveSport(event.data[0]));
            }
        }
    };
}

class Enforce
{

}

export default LiveSportsModel;