import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash-es";
import SportData from "../../data/sport/SportData";
import { IGameData } from "../../../typings/sport-data/IGameData";
import TournamentData from "../../data/sport/TournamentData";
import GameData from "../../data/sport/GameData";
import itemIndexInOrderHelper from "../../utils/helpers/ItemIndexInOrderHelper";
import { GameFields } from "@platform/stream-client";
import gameSortHelper from "../../utils/helpers/GameSortHelper";

export interface IAllLiveSportsState
{
    sports: SportData[];
}

const initialState: IAllLiveSportsState = {
    sports: null
};

export const allLiveSportsSlice = createSlice({
    name: "allLiveSports",
    initialState,
    reducers: {
        setAllLiveSports: (state, action) =>
        {
            state.sports = action.payload;
        },
        addAllLiveSportGame: (state, action) =>
        {
            const game: IGameData = action.payload;
            const sportIndex = findIndex(state.sports, { id: game.sport.id });
            let sportData: SportData;
            let tournamentData: TournamentData;
            let gameData: GameData;

            if (sportIndex === -1)
            {
                sportData = new SportData(game.sport);
                tournamentData = new TournamentData(game.tournament);
                tournamentData.region = game.region;
                gameData = new GameData(game);

                tournamentData.pushGame(gameData);
                tournamentData.gamesCount++;

                sportData.pushTournament(tournamentData);
                sportData.gamesCount++;

                state.sports.splice(itemIndexInOrderHelper(sportData.order, state.sports), 0, sportData);
            }
            else
            {
                sportData = state.sports[sportIndex] as SportData;
                const tournamentIndex = findIndex(sportData.tournaments, { id: game.tournament.id });

                if (tournamentIndex === -1)
                {
                    tournamentData = new TournamentData(game.tournament);
                    tournamentData.region = game.region;
                    gameData = new GameData(game);

                    tournamentData.pushGame(gameData);
                    tournamentData.gamesCount++;

                    sportData.tournaments.splice(itemIndexInOrderHelper(tournamentData.order, sportData.tournaments), 0, tournamentData);
                    sportData.gamesCount++;
                }
                else
                {
                    tournamentData = sportData.tournaments[tournamentIndex];
                    gameData = new GameData(game);

                    tournamentData.pushGame(gameData);
                    tournamentData.gamesCount++;

                    sportData.gamesCount++;
                }
            }
        },
        removeAllLiveSportGame: (state, action) =>
        {
            const sportIndex = findIndex(state.sports, { id: action.payload.sport.id });

            if (sportIndex !== -1)
            {
                const sportData = state.sports[sportIndex];
                const tournamentIndex = findIndex(sportData.tournaments, { id: action.payload.tournament.id });

                if (tournamentIndex !== -1)
                {
                    const tournamentData = sportData.tournaments[tournamentIndex];
                    const gameIndex = findIndex(tournamentData.games, { id: action.payload._id });

                    if (gameIndex !== -1)
                    {
                        if (tournamentData.gamesCount > 1)
                        {
                            tournamentData.games.splice(gameIndex, 1);
                            tournamentData.gamesCount--;
                            sportData.gamesCount--;
                        }
                        else
                        {
                            if (sportData.gamesCount > 1)
                            {
                                sportData.tournaments.splice(tournamentIndex, 1);
                                sportData.gamesCount--;
                            }
                            else
                            {
                                state.sports.splice(sportIndex, 1);
                            }
                        }
                    }
                }
            }
        },
        updateAllLiveSportGame: (state, action) =>
        {
            const sportIndex = findIndex(state.sports, { id: action.payload.sport.id });
            const sportData = state.sports[sportIndex];
            const tournamentIndex = findIndex(sportData.tournaments, { id: action.payload.tournament.id });
            const tournamentData = sportData.tournaments[tournamentIndex];
            const gameIndex = findIndex(tournamentData.games, { id: action.payload._id });
            const gameData = state.sports[sportIndex].tournaments[tournamentIndex].games[gameIndex] as GameData;
            gameData.update(action.payload);

            if (action.payload.hasOwnProperty(GameFields.FEATURED))
            {
                tournamentData.games.sort(gameSortHelper);
            }
        }
    }
});

export const {
    setAllLiveSports,
    addAllLiveSportGame,
    removeAllLiveSportGame,
    updateAllLiveSportGame
} = allLiveSportsSlice.actions;

export default allLiveSportsSlice.reducer;
