const stringifyShadowValues = (shadowValues, type = "") =>
{
    if (!shadowValues.exists)
    {
        return "none";
    }
    const red = parseInt(shadowValues.color[1] + shadowValues.color[2], 16);
    const green = parseInt(shadowValues.color[3] + shadowValues.color[4], 16);
    const blue = parseInt(shadowValues.color[5] + shadowValues.color[6], 16);
    const shadowText = [shadowValues.h_offset, shadowValues.v_offset, shadowValues.blur, shadowValues.spread, ""].join("px ") + "rgba(" + [red, green, blue, shadowValues.opacity].join(", ") + ")";
    return [type, shadowText].join(" ");
};

export default stringifyShadowValues;