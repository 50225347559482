import React, { createContext, ReactNode, useEffect, useState } from "react";
import { getSiteDirection, getSiteLanguage } from "../utils/helpers/SportHelpers";
import TranslationEnum from "../utils/translate/TranslationEnum";
import SiteDirection from "../utils/static/SiteDirection";
import { getTranslations } from "../services/rest/locale/LocaleService";
import Loader from "../components/base/loader/mine/Loader";
import moment from "moment";
import "moment/locale/tr";

export interface ILocaleContext
{
    translations?: Record<TranslationEnum, string>;
    locale: string;
    type: SiteDirection;
    setLocale: (locale: string) => void;
    setType: (type: SiteDirection) => void;
}

export interface ILocaleProvider
{
    children?: ReactNode;
}

export const LocaleContext = createContext<ILocaleContext>(null);

export const LocaleProvider: React.FC<ILocaleProvider> = ({ children }): React.ReactElement =>
{
    const [translations, setTranslations] = useState(null);
    const [locale, setLocale] = useState(getSiteLanguage());
    const [type, setType] = useState(getSiteDirection());

    useEffect(() =>
    {
        if (locale === "tr-TR" && moment.locale() !== "tr")
        {
            moment.updateLocale("tr", {
                monthsShort: "Oca_Şub_Mar_Nis_May_Haz_Tem_Ağu_Eyl_Eki_Kas_Ara".split("_")
            });
        }
        else if (locale === "az-AZ" && moment.locale() !== "az")
        {
            moment.updateLocale("az", {
                monthsShort: "Yan_Fev_Mar_Apr_May_İyn_İyl_Avq_Sen_Okt_Noy_Dek".split("_")
            });
        }
        else if (moment.locale() !== "en")
        {
            moment.updateLocale("en", {
                monthsShort: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_")
            });
        }

        getTranslations(locale).then(resp =>
        {
            setTranslations(resp);
        }).catch(error =>
        {
            setTranslations({});
            console.log("error", error);
        });
    }, [locale]);

    return <LocaleContext.Provider value={{ translations, locale, type, setLocale, setType }}>
        {translations ? children : (SportWidgetOptions.externalLoaderEnabled ? null :
            <Loader style={SportWidgetOptions.desktop ? {} : { height: `${window.innerHeight - 56}px` }}/>)}
    </LocaleContext.Provider>;
};
