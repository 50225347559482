import { RequestBase, StreamClient, StreamClientEvent, StreamConfig } from "@platform/notification-client";
import { EventDispatcher } from "../../utils/events/EventDispatcher";
import { NotificationServiceEvent } from "./events/NotificationServiceEvent";
import { NOTIFICATION_PATH, STREAM_DOMAIN } from "../../utils/constants/Config";

class NotificationService extends EventDispatcher
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    private static _instance: NotificationService;
    private _notificationClient: StreamClient;
    private _ready: boolean = false;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(cl: Enforce)
    {
        super();

        const wsConnectionProtocol = (window.location.protocol === "https:" ? "wss" : "ws");
        const domain: string = STREAM_DOMAIN;
        const notificationPath: string = NOTIFICATION_PATH;

        this._notificationClient = new StreamClient(new StreamConfig(`${wsConnectionProtocol}://${domain}${notificationPath}`));
        this._notificationClient.addEventListener(StreamClientEvent.CONNECT, this.onSocketConnectHandler);
        this._notificationClient.connect();
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    public sendRequest(command: RequestBase): void
    {
        this._notificationClient.sendRequest(command);
    }

    public removeRequest(command: RequestBase): void
    {
        this._notificationClient.dropRequest(command);
    }

    public get ready(): boolean
    {
        return this._ready;
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------

    public static getInstance(): NotificationService
    {
        if (this._instance == null)
        {
            this._instance = new NotificationService(new Enforce());
        }

        return this._instance;
    }

    // --------------------------------------------------------------------------
    //
    // Private methods
    //
    // --------------------------------------------------------------------------
    private onSocketConnectHandler: (event: StreamClientEvent) => void = (event: StreamClientEvent): void =>
    {
        this._ready = true;
        this.dispatchEvent(new NotificationServiceEvent(NotificationServiceEvent.READY));
        this._notificationClient.removeEventListener(StreamClientEvent.CONNECT, this.onSocketConnectHandler);
    };
}

class Enforce
{

}

export default NotificationService;