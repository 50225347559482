import {
    GameChannels,
    GameFields,
    GameRequest, RegionFields,
    RequestCommandEvent, RequestCriteriaField,
    RequestCriteriaLevel, RequestGamesCount,
    RequestSelector, SportFields, TournamentFields
} from "@platform/stream-client";
import { StreamServiceEvent } from "../../../services/sport-data/events/StreamServiceEvent";
import StreamService from "../../../services/sport-data/StreamService";
import { storeDispatch } from "../../../redux/store";
import { removeFavoriteTournamentId } from "../../../redux/slice/favoriteSlice";

export class FavoriteTournamentModel
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------
    private _tournamentCommand: GameRequest;
    private _tournamentId: string = "";

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------
    constructor(tournamentId: string)
    {
        this._tournamentId = tournamentId;

        if (StreamService.getInstance().ready)
        {
            this.sendGameCommand();
        }
        else
        {
            StreamService.getInstance().addEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        }
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public clearRequest(): void
    {
        this._tournamentId = "";

        if (this._tournamentCommand)
        {
            StreamService.getInstance().removeRequest(this._tournamentCommand);
            this._tournamentCommand.removeEventListener(RequestCommandEvent.DATA, this.onTournamentCommandUpdateHandler);
            this._tournamentCommand = null;
        }
    }

    // --------------------------------------------------------------------------
    //
    // Private methods
    //
    // --------------------------------------------------------------------------
    private sendGameCommand(): void
    {
        this._tournamentCommand = new GameRequest(GameChannels.ACTIVE, true, "favorite tournament");

        const arr: string[] = this._tournamentId.split("-");

        const sportSelector: RequestSelector = new RequestSelector(GameFields.SPORT);
        sportSelector.add(SportFields.ID);

        const regionSelector: RequestSelector = new RequestSelector(GameFields.REGION);
        regionSelector.add(RegionFields.ID);

        const tournamentSelector: RequestSelector = new RequestSelector(GameFields.TOURNAMENT);
        tournamentSelector.add(TournamentFields.ID);

        const sportCriteria: RequestCriteriaLevel = new RequestCriteriaLevel(GameFields.SPORT);
        sportCriteria.addSubCriteria(new RequestCriteriaField(SportFields.ID, arr[0]));

        const regionCriteria: RequestCriteriaLevel = new RequestCriteriaLevel(GameFields.REGION);
        regionCriteria.addSubCriteria(new RequestCriteriaField(RegionFields.ID, arr[1]));

        const tournamentCriteria: RequestCriteriaLevel = new RequestCriteriaLevel(GameFields.TOURNAMENT);
        tournamentCriteria.addSubCriteria(new RequestCriteriaField(TournamentFields.ID, arr[2]));

        const gameCriteria: RequestCriteriaLevel = new RequestCriteriaLevel();
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.ACTIVE, true));

        this._tournamentCommand.addSelector(sportSelector, regionSelector, tournamentSelector, new RequestGamesCount());
        this._tournamentCommand.addCriteria(sportCriteria, regionCriteria, tournamentCriteria, gameCriteria);

        this._tournamentCommand.addEventListener(RequestCommandEvent.DATA, this.onTournamentCommandUpdateHandler);
        StreamService.getInstance().sendRequest(this._tournamentCommand);
    }

    private onStreamServiceReadyHandler: (event: StreamServiceEvent) => void = (event: StreamServiceEvent): void =>
    {
        StreamService.getInstance().removeEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        this.sendGameCommand();
    };

    private onTournamentCommandUpdateHandler: (event: RequestCommandEvent) => void = (event: RequestCommandEvent): void =>
    {
        if (event.fullUpdate)
        {
            if (event.data.length === 0)
            {
                storeDispatch(removeFavoriteTournamentId(this._tournamentId));
            }
        }
        else
        {
            if (event.data[0]._remove)
            {
                storeDispatch(removeFavoriteTournamentId(this._tournamentId));
            }
        }
    };
}