import { findIndex } from "lodash-es";
import {
    GameChannels, GameFeeds,
    GameFields,
    GameRequest, MatchInfoFields, RegionFields,
    RequestCommandEvent,
    RequestCriteriaField,
    RequestCriteriaLevel,
    RequestSelector,
    SportFields, TournamentFields,
} from "@platform/stream-client";
import { storeDispatch } from "../../redux/store";
import StreamService from "../../services/sport-data/StreamService";
import { StreamServiceEvent } from "../../services/sport-data/events/StreamServiceEvent";
import SportData from "../../data/sport/SportData";
import GameData from "../../data/sport/GameData";
import TournamentData from "../../data/sport/TournamentData";
import itemIndexInOrderHelper from "../../utils/helpers/ItemIndexInOrderHelper";
import {
    addAllLiveSportGame,
    removeAllLiveSportGame,
    setAllLiveSports,
    updateAllLiveSportGame
} from "../../redux/slice/allLiveSportsSlice";
import { IGameData } from "../../../typings/sport-data/IGameData";
import gameSortHelper from "../../utils/helpers/GameSortHelper";

class AllLiveSportsModel
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    private static _instance: AllLiveSportsModel;
    private _sportsCommand: GameRequest;
    private _running: boolean = false;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(cl: Enforce)
    {

    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public static getInstance(): AllLiveSportsModel
    {
        if (this._instance == null)
        {
            this._instance = new AllLiveSportsModel(new Enforce());
        }

        return this._instance;
    }

    public run(): void
    {
        if (!this._running)
        {
            this._running = true;

            if (StreamService.getInstance().ready)
            {
                this.sendSportsCommand();
            }
            else
            {
                StreamService.getInstance().addEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
            }
        }
    }

    public clearRequest(): void
    {
        storeDispatch(setAllLiveSports(null));
        this._running = false;

        if (this._sportsCommand)
        {
            StreamService.getInstance().removeRequest(this._sportsCommand);
            this._sportsCommand.removeEventListener(RequestCommandEvent.DATA, this.onSportsCommandUpdateHandler);
            this._sportsCommand = null;
        }
    }

    // --------------------------------------------------------------------------
    //
    // Private methods
    //
    // --------------------------------------------------------------------------
    private sendSportsCommand(): void
    {
        this._sportsCommand = new GameRequest(GameChannels.ACTIVE, true, "all live sports");

        const sportSelector: RequestSelector = new RequestSelector(GameFields.SPORT);
        sportSelector.add(SportFields.ID, SportFields.ALIAS, SportFields.NAME, SportFields.ORDER);

        const regionSelector: RequestSelector = new RequestSelector(GameFields.REGION);
        regionSelector.add(RegionFields.ID, RegionFields.ALIAS, RegionFields.NAME, RegionFields.ORDER, RegionFields.IMAGE);

        const tournamentSelector: RequestSelector = new RequestSelector(GameFields.TOURNAMENT);
        tournamentSelector.add(TournamentFields.ID, TournamentFields.ALIAS, TournamentFields.NAME, TournamentFields.ORDER, TournamentFields.IMAGE);

        const matchSelector: RequestSelector = new RequestSelector(GameFields.MATCH_INFO);
        matchSelector.add(MatchInfoFields.SCORE, MatchInfoFields.SCORES, MatchInfoFields.GAME_SCORE, MatchInfoFields.SERVER, MatchInfoFields.TIME,
            MatchInfoFields.CLOCK_STOPPAGE_TIME, MatchInfoFields.CLOCK_STOPPED, MatchInfoFields.CLOCK_REMAINING_TIME_IN_PERIOD);

        const gameSelector: RequestSelector = new RequestSelector();
        gameSelector.add(GameFields.ID, GameFields.DATE, GameFields.HOME_TEAM, GameFields.AWAY_TEAM, GameFields.STATUS, GameFields.MARKETS_COUNT,
            GameFields.ODDS_COUNT, GameFields.VIDEO, GameFields.STATS_SCORE, GameFields.ANIMATION, GameFields.FEATURED);

        const gameCriteria: RequestCriteriaLevel = new RequestCriteriaLevel();
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.FEED, GameFeeds.LIVE));
        gameCriteria.addSubCriteria(new RequestCriteriaField(GameFields.ACTIVE, true));

        this._sportsCommand.addSelector(sportSelector, regionSelector, tournamentSelector, gameSelector, matchSelector);
        this._sportsCommand.addCriteria(gameCriteria);

        this._sportsCommand.addEventListener(RequestCommandEvent.DATA, this.onSportsCommandUpdateHandler);

        StreamService.getInstance().sendRequest(this._sportsCommand);
    }

    private onStreamServiceReadyHandler: (event: StreamServiceEvent) => void = (event: StreamServiceEvent): void =>
    {
        StreamService.getInstance().removeEventListener(StreamServiceEvent.READY, this.onStreamServiceReadyHandler);
        this.sendSportsCommand();
    };

    private onSportsCommandUpdateHandler: (event: RequestCommandEvent) => void = (event: RequestCommandEvent): void =>
    {
        if (event.fullUpdate)
        {
            const sports: SportData[] = [];

            for (const game of event.data)
            {
                const sportIndex: number = findIndex(sports, { id: game.sport.id });
                let tournamentIndex: number;
                let gameData: GameData;
                let sportData: SportData;
                let tournamentData: TournamentData;

                if (sportIndex === -1)
                {
                    sportData = new SportData(game.sport);
                    tournamentData = new TournamentData(game.tournament);
                    tournamentData.region = game.region;
                    gameData = new GameData(game);

                    tournamentData.pushGame(gameData);
                    tournamentData.gamesCount++;

                    sportData.pushTournament(tournamentData);
                    sportData.gamesCount++;

                    sports.splice(itemIndexInOrderHelper(sportData.order, sports), 0, sportData);
                }
                else
                {
                    sportData = sports[sportIndex];
                    tournamentIndex = findIndex(sportData.tournaments, { id: game.tournament.id });

                    if (tournamentIndex === -1)
                    {
                        tournamentData = new TournamentData(game.tournament);
                        tournamentData.region = game.region;
                        gameData = new GameData(game);

                        tournamentData.pushGame(gameData);
                        tournamentData.gamesCount++;

                        sportData.tournaments.splice(itemIndexInOrderHelper(tournamentData.order, sportData.tournaments), 0, tournamentData);
                        sportData.gamesCount++;
                    }
                    else
                    {
                        tournamentData = sportData.tournaments[tournamentIndex];

                        gameData = new GameData(game);

                        tournamentData.pushGame(gameData);
                        tournamentData.games.sort(gameSortHelper);
                        tournamentData.gamesCount++;

                        sportData.gamesCount++;
                    }
                }
            }

            storeDispatch(setAllLiveSports(sports));
        }
        else
        {
            if (event.data[0])
            {
                if ((<IGameData>event.data[0])._new)
                {
                    storeDispatch(addAllLiveSportGame(event.data[0]));
                }
                else if ((<IGameData>event.data[0])._remove)
                {
                    storeDispatch(removeAllLiveSportGame(event.data[0]));
                }
                else
                {
                    storeDispatch(updateAllLiveSportGame(event.data[0]));
                }
            }
        }
    };
}

class Enforce
{

}

export default AllLiveSportsModel;