import GameData from "../../data/sport/GameData";

function gameSortHelper(g1: GameData, g2: GameData): number
{
    if (g1.featured && !g2.featured)
    {
        return -1;
    }
    else if(!g1.featured && g2.featured)
    {
        return 1;
    }

    return g1?.date?.start - g2?.date?.start;
}

export default gameSortHelper;