import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash-es";
import { IGameData } from "../../../typings/sport-data/IGameData";
import TournamentData from "../../data/sport/TournamentData";
import GameData from "../../data/sport/GameData";
import itemIndexInOrderHelper from "../../utils/helpers/ItemIndexInOrderHelper";
import gameSortHelper from "../../utils/helpers/GameSortHelper";
import { GameFields } from "@platform/stream-client";
import RegionData from "../../data/sport/RegionData";
import tournamentData from "../../data/sport/TournamentData";

interface ILiveCurrentSportState
{
    tournaments: TournamentData[];
    regions: RegionData[];
}

const initialState: ILiveCurrentSportState = {
    tournaments: null,
    regions: null
};

export const liveCurrentSportSlice = createSlice({
    name: "liveCurrentSport",
    initialState,
    reducers: {
        setLiveSportTournaments: (state, action) =>
        {
            if ((window as any).sportWidgetLazyOptions.vbttopView)
            {
                state.regions = action.payload;
            }
            else
            {
                state.tournaments = action.payload;
            }
        },
        addLiveSportGame: (state, action) =>
        {
            if ((window as any).sportWidgetLazyOptions.vbttopView)
            {
                const game: IGameData = action.payload;
                const regionIndex = findIndex(state.regions, {id: game.region.id});
                let regionData: RegionData;
                let tournamentIndex: number;
                let tournamentData: TournamentData;
                let gameData: GameData;

                if (regionIndex === -1)
                {
                    regionData = new RegionData(game.region);
                    regionData.sport = game.sport;
                    regionData.gamesCount++;

                    tournamentData = new TournamentData(game.tournament);
                    tournamentData.sport = game.sport;
                    tournamentData.region = game.region;
                    tournamentData.gamesCount++;

                    gameData = new GameData(game);

                    tournamentData.pushGame(gameData);
                    regionData.pushTournament(tournamentData);

                    state.regions.splice(itemIndexInOrderHelper(regionData.order, state.regions), 0, regionData);
                }
                else
                {
                    regionData = state.regions[regionIndex] as RegionData;
                    tournamentIndex = findIndex(regionData?.tournaments, { id: game.tournament.id });

                    if (tournamentIndex === -1)
                    {
                        tournamentData = new TournamentData(game.tournament);
                        tournamentData.sport = game.sport;
                        tournamentData.region = game.region;
                        tournamentData.gamesCount++;

                        gameData = new GameData(game);

                        tournamentData.pushGame(gameData);
                        regionData.pushTournament(tournamentData);
                        regionData.gamesCount++;
                    }
                    else
                    {
                        tournamentData = state.regions[regionIndex]?.tournaments[tournamentIndex] as TournamentData;

                        gameData = new GameData(game);

                        tournamentData.pushGame(gameData);
                        tournamentData.gamesCount++;
                        regionData.gamesCount++;
                    }
                }
            }
            else
            {
                const game: IGameData = action.payload;
                const tournamentIndex = findIndex(state.tournaments, { id: game.tournament.id });
                let tournamentData: TournamentData;
                let gameData: GameData;

                if (tournamentIndex === -1)
                {
                    tournamentData = new TournamentData(game.tournament);
                    tournamentData.sport = game.sport;
                    tournamentData.region = game.region;
                    gameData = new GameData(game);

                    tournamentData.pushGame(gameData);
                    tournamentData.gamesCount++;

                    state.tournaments.splice(itemIndexInOrderHelper(tournamentData.order, state.tournaments), 0, tournamentData);
                }
                else
                {
                    tournamentData = state.tournaments[tournamentIndex] as TournamentData;
                    gameData = new GameData(game);

                    tournamentData.pushGame(gameData);
                    tournamentData.gamesCount++;
                    tournamentData.games.sort(gameSortHelper);
                }
            }
        },
        removeLiveSportGame: (state, action) =>
        {
            if ((window as any).sportWidgetLazyOptions.vbttopView)
            {
                const regionIndex = findIndex(state.regions, {id: action.payload.region.id});

                if (regionIndex !== -1)
                {
                    const regionData = state.regions[regionIndex];
                    const tournamentIndex = findIndex(regionData?.tournaments, {id: action.payload.tournament.id});

                    if (tournamentIndex !== - 1)
                    {
                        const tournamentData = state.regions[regionIndex].tournaments[tournamentIndex];
                        const gameIndex = findIndex(tournamentData?.games, { id: action.payload._id });

                        if (gameIndex !== -1)
                        {
                            if (tournamentData.gamesCount > 1)
                            {
                                tournamentData.games.splice(gameIndex, 1);
                                tournamentData.gamesCount--;
                                regionData.gamesCount--;
                            }
                            else
                            {
                                if (regionData.gamesCount > 1)
                                {
                                    regionData.tournaments.splice(tournamentIndex, 1);
                                    regionData.gamesCount--;
                                }
                                else
                                {
                                    state.regions.splice(regionIndex, 1);
                                }
                            }
                        }
                    }
                }
            }
            else
            {
                const tournamentIndex = findIndex(state.tournaments, { id: action.payload.tournament.id });

                if (tournamentIndex !== -1)
                {
                    const tournamentData = state.tournaments[tournamentIndex];
                    const gameIndex = findIndex(tournamentData.games, { id: action.payload._id });

                    if (gameIndex !== -1)
                    {
                        if (tournamentData.gamesCount > 1)
                        {
                            tournamentData.games.splice(gameIndex, 1);
                            tournamentData.gamesCount--;
                        }
                        else
                        {
                            state.tournaments.splice(tournamentIndex, 1);
                        }
                    }
                }
            }
        },
        updateLiveSportGame: (state, action) =>
        {
            if ((window as any).sportWidgetLazyOptions.vbttopView)
            {
                const regionIndex = findIndex(state.regions, {id: action.payload.region.id});
                const regionData = state.regions[regionIndex];
                const tournamentIndex = findIndex(regionData?.tournaments, {id: action.payload.tournament.id});
                const tournamentData = state.regions[regionIndex].tournaments[tournamentIndex];
                const gameIndex = findIndex(tournamentData?.games, {id: action.payload._id});
                const gameData = state.regions[regionIndex]?.tournaments[tournamentIndex]?.games[gameIndex] as GameData;

                gameData?.update(action.payload);
            }
            else
            {
                const tournamentIndex = findIndex(state.tournaments, { id: action.payload.tournament.id });
                const tournamentData = state.tournaments[tournamentIndex];
                const gameIndex = findIndex(tournamentData.games, { id: action.payload._id });
                const gameData = state.tournaments[tournamentIndex].games[gameIndex] as GameData;
                gameData.update(action.payload);

                if (action.payload.hasOwnProperty(GameFields.FEATURED))
                {
                    tournamentData.games.sort(gameSortHelper);
                }
            }
        },
        setLiveGamesMarkets: (state, action) =>
        {
            if ((window as any).sportWidgetLazyOptions.vbttopView)
            {
                for (const game of action.payload)
                {
                    const regionIndex = findIndex(state.regions, { id: game.region.id });
                    const regionData = state.regions[regionIndex];
                    const tournamentIndex = findIndex(regionData?.tournaments, { id: game.tournament.id });
                    const tournamentData = state.regions[regionIndex].tournaments[tournamentIndex];
                    const gameIndex = findIndex(tournamentData.games, { id: game._id });
                    const gameData = state.regions[regionIndex]?.tournaments[tournamentIndex]?.games[gameIndex] as GameData;

                    gameData.addMarkets(game);
                }
            }
            else
            {
                for (const game of action.payload)
                {
                    const tournamentIndex = findIndex(state.tournaments, { id: game.tournament.id });
                    const tournamentData = state.tournaments[tournamentIndex];
                    const gameIndex = findIndex(tournamentData.games, { id: game._id });
                    const gameData = state.tournaments[tournamentIndex].games[gameIndex] as GameData;

                    gameData.addMarkets(game);
                }
            }
        },
        addLiveGameMarket: (state, action) =>
        {
            if ((window as any).sportWidgetLazyOptions.vbttopView)
            {
                const regionIndex = findIndex(state.regions, {id: action.payload.region.id});
                const regionData = state.regions[regionIndex];
                const tournamentIndex = findIndex(regionData?.tournaments, {id: action.payload.tournament.id});
                const tournamentData = state.regions[regionIndex]?.tournaments[tournamentIndex];
                const gameIndex = findIndex(tournamentData?.games, {id: action.payload._id});
                const gameData = state.regions[regionIndex]?.tournaments[tournamentIndex]?.games[gameIndex] as GameData;

                gameData?.addMarkets(action.payload);
            }
            else
            {
                const tournamentIndex = findIndex(state.tournaments, { id: action.payload.tournament.id });
                const tournamentData = state.tournaments[tournamentIndex];
                const gameIndex = findIndex(tournamentData.games, { id: action.payload._id });
                const gameData = state.tournaments[tournamentIndex].games[gameIndex] as GameData;

                gameData.addMarkets(action.payload);
            }
        },
        updateLiveGameMarket: (state, action) =>
        {
            if ((window as any).sportWidgetLazyOptions.vbttopView)
            {
                const regionIndex = findIndex(state.regions, { id: action.payload.region.id });
                const regionData = state.regions[regionIndex];
                const tournamentIndex = findIndex(regionData?.tournaments, {id: action.payload.tournament.id});
                const tournamentData = state.regions[regionIndex]?.tournaments[tournamentIndex];
                const gameIndex = findIndex(tournamentData?.games, {id: action.payload._id});
                const gameData = state.regions[regionIndex]?.tournaments[tournamentIndex]?.games[gameIndex] as GameData;

                gameData?.update(action.payload);
            }
            else
            {
                const tournamentIndex = findIndex(state.tournaments, { id: action.payload.tournament.id });
                const tournamentData = state.tournaments[tournamentIndex];
                const gameIndex = findIndex(tournamentData.games, { id: action.payload._id });
                const gameData = state.tournaments[tournamentIndex].games[gameIndex] as GameData;

                gameData.update(action.payload);
            }
        },
    }
});

export const {
    setLiveSportTournaments,
    addLiveSportGame,
    removeLiveSportGame,
    updateLiveSportGame,
    setLiveGamesMarkets,
    addLiveGameMarket,
    updateLiveGameMarket
} = liveCurrentSportSlice.actions;

export default liveCurrentSportSlice.reducer;
