export default [
    {
        name: "Default",
        values: {
            "sbBrand": "#3CEFE4",
            "sbDarkest": "#121421",
            "sbDark": "#1D2030",
            "sbMedium": "#292C3D",
            "sbRegular": "#363949",
            "sbSemiLight": "#434656",
            "sbLight": "#676B7E",
            "sbUltraLight": "#8F92A3",
            "sbLightest": "#C4C7D4",
            "sbWhite": "#ffffff",
            "sbWarning": "#EFC047",
            "sbSuccess": "#51DC88",
            "sbError": "#EF3C47",
            "sbShadow": "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
            "sbInsetShadow": "inset 0px 3px 8px 0px rgba(0, 0, 0, 0.2)"
        }
    },
    {
        name: "Dark",
        values: {
            "sbBrand": "#8EB50D",
            "sbDarkest": "#16161E",
            "sbDark": "#1A1C23",
            "sbMedium": "#1F2128",
            "sbRegular": "#282A33",
            "sbSemiLight": "#373942",
            "sbLight": "#5D636F",
            "sbUltraLight": "#9FA2AB",
            "sbLightest": "#E5E5E5",
            "sbWhite": "#FFFFFF",
            "sbWarning": "#EFC047",
            "sbSuccess": "#51DC88",
            "sbError": "#EF3C47",
            "sbShadow": "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
            "sbInsetShadow": "inset 0px 3px 8px 0px rgba(0, 0, 0, 0.2)"
        }
    },
    {
        name: "Blue",
        values: {
            "sbBrand": "#1CB0FF",
            "sbDarkest": "#000A2E",
            "sbDark": "#0C1946",
            "sbMedium": "#122054",
            "sbRegular": "#263773",
            "sbSemiLight": "#3D4F8F",
            "sbLight": "#4B5C9B",
            "sbUltraLight": "#8E9AC2",
            "sbLightest": "#DADDE6",
            "sbWhite": "#FFFFFF",
            "sbWarning": "#EFC047",
            "sbSuccess": "#51DC88",
            "sbError": "#EF3C47",
            "sbShadow": "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
            "sbInsetShadow": "inset 0px 3px 8px 0px rgba(0, 0, 0, 0.2)"
        }
    },
    {
        name: "Brown",
        values: {
            "sbBrand": "#c9a100",
            "sbDarkest": "#000000",
            "sbDark": "#0D0D0C",
            "sbMedium": "#131313",
            "sbRegular": "#1D1C17",
            "sbSemiLight": "#3B392B",
            "sbLight": "#5F5D54",
            "sbUltraLight": "#908F89",
            "sbLightest": "#CDCDCA",
            "sbWhite": "#FBFBFB",
            "sbWarning": "#EFC047",
            "sbSuccess": "#1EDC6A",
            "sbError": "#E35151",
            "sbShadow": "0px 4px 6px 0px rgba(0, 0, 0, 0.04)",
            "sbInsetShadow": "inset 0px 2px 6px 0px rgba(0, 0, 0, 0.08)"
        }
    }
];