const parseShadowValues: (shadowValuesArr) => object = (shadowValuesArr) =>
{
    if (!shadowValuesArr)
    {
        return { exists: false };
    }
    if (shadowValuesArr.length < 2)
    {
        return { exists: false };
    }
    if (shadowValuesArr[0] === "none")
    {
        return { exists: false };
    }
    shadowValuesArr = shadowValuesArr.split("px ");
    shadowValuesArr[0] = shadowValuesArr[0].split("inset ").pop();
    const colorString = shadowValuesArr.pop();
    const colorArr = colorString.split("(")[1].split(")")[0].split(",");
    const opacity = colorArr.pop().trim();
    const color = "#" + colorArr.map(value =>
    {
        const hex = parseInt(value).toString(16).toUpperCase();
        if (hex.length < 2)
        {
            return "0" + hex;
        }
        else
        {
            return hex;
        }
    }).join("");
    return {
        opacity,
        color,
        h_offset: shadowValuesArr[0]?.trim() || "0",
        v_offset: shadowValuesArr[1]?.trim() || "0",
        blur: shadowValuesArr[2]?.trim() || "0",
        spread: shadowValuesArr[3]?.trim() || "0",
        exists: true,
    };
};

export default parseShadowValues;