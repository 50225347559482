import GameData from "../../data/sport/GameData";
import { GameFeeds } from "@platform/stream-client";

function gameSortHelperWithoutFeatured(g1: GameData, g2: GameData): number
{
    if (g1.line === GameFeeds.LIVE && g2.line !== GameFeeds.LIVE)
    {
        return -1;
    }
    else if (g1.line !== GameFeeds.LIVE && g2.line === GameFeeds.LIVE)
    {
        return 1;
    }

    return g1?.date?.start - g2?.date?.start;
}

export default gameSortHelperWithoutFeatured;