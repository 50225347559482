import { createSlice } from "@reduxjs/toolkit";

export interface IMyOpenBetsCountState
{
    count: number;
}

const initialState: IMyOpenBetsCountState = {
    count: 0
};

export const myOpenBetsCountSlice = createSlice({
    name: "myOpenBetsCount",
    initialState,
    reducers: {
        setMyOpenBetsCount: (state, action) =>
        {
            state.count = action.payload;
        }
    }
});

export const {
    setMyOpenBetsCount
} = myOpenBetsCountSlice.actions;

export default myOpenBetsCountSlice.reducer;
