import { GameFeeds, RequestCommandEvent, SystemDownRequest } from "@platform/stream-client";
import { IFeedDownState, initFeedDown, setFeedDown } from "../../redux/slice/feedDownSlice";
import StreamService from "./StreamService";
import { storeDispatch } from "../../redux/store";

class FeedDownService
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    private static _instance: FeedDownService;
    private _feedDownCommand: SystemDownRequest;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(cl: Enforce)
    {
        this._feedDownCommand = new SystemDownRequest(GameFeeds.LIVE, GameFeeds.PREMATCH, "premium_cricket");
        this._feedDownCommand.addEventListener(RequestCommandEvent.DATA, this.onFeedDownCommandUpdateHandler);
        StreamService.getInstance().sendRequest(this._feedDownCommand);
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------

    public static getInstance(): FeedDownService
    {
        if (this._instance == null)
        {
            this._instance = new FeedDownService(new Enforce());
        }

        return this._instance;
    }

    // --------------------------------------------------------------------------
    //
    // Private methods
    //
    // --------------------------------------------------------------------------
    private onFeedDownCommandUpdateHandler: (event: RequestCommandEvent) => void = (event: RequestCommandEvent): void =>
    {
        if (event.fullUpdate)
        {
            const obj: IFeedDownState = {
                live: {
                    br: false,
                    ls: false,
                    dc: false
                },
                prematch: {
                    br: false,
                    ls: false,
                    dc: false
                },
                premiumCricket: false,
                betPal: false
            };

            for (const item of event.data)
            {
                if (item?.source === "br")
                {
                    obj.live.br = item.down_state.live;
                    obj.prematch.br = item.down_state.prematch;
                    obj.premiumCricket = item.down_state.premium_cricket;
                    obj.betPal = item.down_state.bet_pal;
                }
                else if (item?.source === "ls")
                {
                    obj.live.ls = item.down_state.live;
                    obj.prematch.ls = item.down_state.prematch;
                }
                else if (item?.source === "dc")
                {
                    obj.live.dc = item.down_state.live;
                    obj.prematch.dc = item.down_state.prematch;
                }
            }

            storeDispatch(initFeedDown(obj));
        }
        else
        {
            if (event.data && event.data.length)
            {
                storeDispatch(setFeedDown({
                    producer: event.data[0].producer,
                    is_down: event.data[0].is_down,
                    source: event.data[0].source
                }));
            }
        }
    };
}

class Enforce
{

}

export default FeedDownService;
