import React, { Dispatch, useEffect, useState } from "react";
import clsx from "clsx";
import ErrorBoundary from "../../error-boundary/ErrorBoundary";
import defaults from "../defaults";
import properties from "../properties";
import parseShadowValues from "../functions/parseShadowValues";
import isActive from "../functions/isActiveDefault";

import styles from "../colorpicker.module.scss";

interface IDefaultsProps
{
    setShadow: Dispatch<any>;
    setInnerShadow: Dispatch<any>;
    setValues: Dispatch<any>;
    values: any;
    closeCustom: any;
}

const root = document.querySelector(":root");

const Defaults: React.FC<IDefaultsProps> = ({
                                                setShadow,
                                                setInnerShadow,
                                                setValues,
                                                values,
                                                closeCustom
                                            }): React.ReactElement =>
{

    const [showCustomElement, setShowCustomElement] = useState(true);
    const [customValues, setCustomValues] = useState({});

    const setDefaultStyles = (element) =>
    {
        const root = document.querySelector(":root");
        const newValues = [];
        properties.forEach((prop) => newValues.push(element[prop]));
        Object.keys(element).map(style_variable =>
        {
            document.documentElement.style.setProperty("--" + style_variable, element[style_variable]);
        });
        setShadow(parseShadowValues(getComputedStyle(root).getPropertyValue("--sbShadow")));
        setInnerShadow(parseShadowValues(getComputedStyle(root).getPropertyValue("--sbInsetShadow")));
        setValues(newValues);
        closeCustom();
    };

    useEffect(() =>
    {
        let result = true;
        defaults.forEach(({ name, values }, index) =>
        {
            if (isActive(values))
            {
                result = false;
            }
        });
        if (showCustomElement !== result)
        {
            setShowCustomElement(result);
        }
    }, [values]);

    return <ErrorBoundary>
        <div className={styles.defaults}>
            {
                defaults.map(({ name, values }, index) =>
                {
                    return <div className={styles.default_item} key={index}>
                        <div onClick={() => setDefaultStyles(values)}
                             className={clsx(styles.round, isActive(values) && styles.active)}>
                            <div style={{ backgroundColor: values.sbBrand }} className={styles.color}></div>
                            <div style={{ backgroundColor: values.sbRegular }} className={styles.color}></div>
                        </div>
                        <p onClick={() => setDefaultStyles(values)}
                           style={isActive(values) ? { fontWeight: "bold" } : {}}>{name}</p>
                    </div>;
                })
            }
            {
                showCustomElement && <div className={styles.default_item}>
                    <div className={clsx(styles.round, styles.active)}>
                        <div style={{ backgroundColor: getComputedStyle(root).getPropertyValue("--sbBrand") }}
                             className={styles.color}></div>
                        <div style={{ backgroundColor: getComputedStyle(root).getPropertyValue("--sbRegular") }}
                             className={styles.color}></div>
                    </div>
                    <p style={{ fontWeight: "bold" }}>Custom</p>
                </div>
            }
        </div>
    </ErrorBoundary>;
};

export default Defaults;