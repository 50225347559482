import MarketRowData from "../../data/sport/MarketRowData";

function marketRowSortHelper(m1: MarketRowData, m2: MarketRowData): number
{
    if (m1.id && (m1.id["total"] || m1.id["hcp"] || m1.id["tpl"]))
    {
        if (m1.id["total"])
        {
            return parseFloat(m1.id["total"]) - parseFloat(m2.id["total"]);
        }
        else if (m1.id["hcp"])
        {
            return parseFloat(m1.id["hcp"]) - parseFloat(m2.id["hcp"]);
        }
        else if (m1.id["tpl"])
        {
            return parseFloat(m1.id["tpl"]) - parseFloat(m2.id["tpl"]);
        }
        else
        {
            return 0;
        }
    }
    else
    {
        return 0;
    }
}

export default marketRowSortHelper;