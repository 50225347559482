import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash-es";
import SportData from "../../data/sport/SportData";
import itemIndexInOrderHelper from "../../utils/helpers/ItemIndexInOrderHelper";

export interface ILiveSportsState
{
    sports: SportData[];
    liveSportsOpened: boolean;
}

const initialState: ILiveSportsState = {
    sports: null,
    liveSportsOpened: true
};

export const liveSportsSlice = createSlice({
    name: "liveSports",
    initialState,
    reducers: {
        setLiveSports: (state, action) =>
        {
            state.sports = action.payload;
        },
        toggleLiveSports: (state) =>
        {
            state.liveSportsOpened = !state.liveSportsOpened;
        },
        updateLiveSport: (state, action) =>
        {
            const sportIndex = findIndex(state.sports, { id: action.payload.sport.id });

            if (sportIndex === -1 && action.payload.game !== 0)
            {
                const sportData: SportData = new SportData(action.payload.sport);
                sportData.gamesCount = action.payload.game;
                state.sports.splice(itemIndexInOrderHelper(sportData.order, state.sports), 0, sportData);
            }
            else if (sportIndex !== -1)
            {
                if (action.payload.game === 0)
                {
                    state.sports.splice(sportIndex, 1);
                }
                else
                {
                    state.sports[sportIndex].gamesCount = action.payload.game;
                }
            }
        }
    }
});

export const { setLiveSports, toggleLiveSports, updateLiveSport } = liveSportsSlice.actions;

export default liveSportsSlice.reducer;
