import axios from "axios";
import { DOMAIN, HOST } from "../../../utils/constants/Config";

const urlPrefix: string = `${DOMAIN}/${HOST}/config/info`;
const currencyUrlPrefix: string = `${DOMAIN}/${HOST}/locale/api/v1/currency/config`;

export function getSiteConfig(siteId: string)
{
    return axios.get(`${urlPrefix}/light?site_id=${siteId}`).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}

export function getSportConfig(siteId: string)
{
    return axios.get(`${urlPrefix}/game?site_id=${siteId}`).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}

export function getCurrencyConfig(currencies: string)
{
    return axios.get(`${currencyUrlPrefix}/${currencies}`).then(resp =>
    {
        return Promise.resolve(resp.data);
    }).catch(error =>
    {
        return Promise.reject(error);
    });
}