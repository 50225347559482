import { Event } from "../../../utils/events/Event";

export class StreamServiceEvent extends Event
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------
    public static READY: string = "streamServiceReady";
    public static LOCALIZE_START: string = "streamLocalizeStart";
    public static LOCALIZE_STREAM_COMPLETE: string = "streamLocalizeComplete";
    public static RECONNECT_START: string = "streamServiceReconnectStart";
    public static RECONNECT_COMPLETE: string = "streamServiceReconnectComplete";

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------
    constructor(type: string)
    {
        super(type);
    }
}
