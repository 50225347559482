enum StatusTypes
{
    NOT_STARTED = "0",
    FIRST_PERIOD = "1",
    SECOND_PERIOD = "2",
    THIRD_PERIOD = "3",
    FOURTH_PERIOD = "4",
    FIFTH_PERIOD = "5",
    FIRST_HALF = "6",
    SECOND_HALF = "7",
    FIRST_SET = "8",
    SECOND_SET = "9",
    THIRD_SET = "10",
    FOURTH_SET = "11",
    FIFTH_SET = "12",
    FIRST_QUARTER = "13",
    SECOND_QUARTER = "14",
    THIRD_QUARTER = "15",
    FOURTH_QUARTER = "16",
    GOLDEN_SET = "17",
    STARTED = "20",
    IN_PROGRESS = "21",
    BREAK = "30",
    HALFTIME = "31",
    AWAITING_EXTRA_TIME = "32",
    EXTRA_TIME_HALFTIME = "33",
    AWAITING_PENALTIES = "34",
    AWAITING_PENALTIES_2 = "35",
    AWAITING_PENALTIES_3 = "36",
    OVERTIME = "40",
    FIRST_EXTRA = "41",
    SECOND_EXTRA = "42",
    PENALTIES = "50",
    PENALTIES_2 = "51",
    PENALTIES_3 = "52",
    POSTPONED = "60",
    START_DELAYED = "61",
    CANCELLED = "70",
    GAME_1 = "71",
    GAME_2 = "72",
    GAME_3 = "73",
    GAME_4 = "74",
    GAME_5 = "75",
    GAME_6 = "76",
    GAME_7 = "77",
    INTERRUPTED = "80",
    SUSPENDED = "81",
    ABANDONED = "90",
    WALKOVER = "91",
    RETIRED = "92",
    WALKOVER_PLAYER_1_WON = "93",
    WALKOVER_PLAYER_2_WON = "94",
    PLAYER_1_RETIRED_PLAYER_2_WON = "95",
    PLAYER_2_RETIRED_PLAYER_1_WON = "96",
    DEFAULTED = "97",
    DEFAULTED2 = "98",
    ONLY_RESULT = "99",
    ENDED = "100",
    AET = "110",
    AP = "120",
    AGS = "130",
    FIRST_MAP = "141",
    SECOND_MAP = "142",
    THIRD_MAP = "143",
    FOURTH_MAP = "144",
    FIFTH_MAP = "145",
    SIXTH_MAP = "146",
    SEVENTH_MAP = "147",
    FIRST_GAME = "151",
    SECOND_GAME = "152",
    THIRD_GAME = "153",
    FOURTH_GAME = "154",
    FIFTH_GAME = "155",
    FIRST_BREAK = "301",
    SECOND_BREAK = "302",
    THIRD_BREAK = "303",
    FOURTH_BREAK = "304",
    FIFTH_BREAK = "305",
    SIXTH_BREAK = "306",

    /* BaseBalll */
    FIRST_INNING_TOP = "401",
    FIRST_INNING_BOTTOM = "402",
    SECOND_INNING_TOP = "403",
    SECOND_INNING_BOTTOM = "404",
    THIRD_INNING_TOP = "405",
    THIRD_INNING_BOTTOM = "406",
    FOURTH_INNING_TOP = "407",
    FOURTH_INNING_BOTTOM = "408",
    FIFTH_INNING_TOP = "409",
    FIFTH_INNING_BOTTOM = "410",
    SIXTH_INNING_TOP = "411",
    SIXITH_INNING_BOTTOM = "412",
    SEVENTH_INNING_TOP = "413",
    SEVENTH_INNING_BOTTOM = "414",
    EIGHTH_INNING_TOP = "415",
    EIGHTH_INNING_BOTTOM = "416",
    NINETH_INNING_TOP = "417",
    NINETH_INNING_BOTTOM = "418",
    EXTRA_INNING_TOP = "419",
    EXTRA_INNING_BOTTOM = "420",
    BREAK_TOP1_BOTTOM1 = "421",
    BREAK_TOP2_BOTTOM1 = "422",
    BREAK_TOP2_BOTTOM2 = "423",
    BREAK_TOP3_BOTTOM2 = "424",
    BREAK_TOP3_BOTTOM3 = "425",
    BREAK_TOP4_BOTTOM3 = "426",
    BREAK_TOP4_BOTTOM4 = "427",
    BREAK_TOP5_BOTTOM4 = "428",
    BREAK_TOP5_BOTTOM5 = "429",
    BREAK_TOP6_BOTTOM5 = "430",
    BREAK_TOP6_BOTTOM6 = "431",
    BREAK_TOP7_BOTTOM6 = "432",
    BREAK_TOP7_BOTTOM7 = "433",
    BREAK_TOP8_BOTTOM7 = "434",
    BREAK_TOP8_BOTTOM8 = "435",
    BREAK_TOP9_BOTTOM8 = "436",
    BREAK_TOP9_BOTTOM9 = "437",
    BREAK_TOPEI_BOTTOM9 = "438",
    BREAK_TOPEI_BOTTOMEI = "439",
    /* Baseball*/

    SUDDEN_DEADTH = "440",
    SIXTH_SET = "441",
    SEVENTH_SET = "442",
    AWAITING_SUDDEN_DEADTH = "443",
    AFTER_SUDDEN_DEADTH = "444",
    BREAK2 = "445",
    FIRST_INNING_HOME_TEAM = "501",
    FIRST_INNING_AWAY_TEAM = "502",
    SECOND_INNING_HOME_TEAM = "503",
    SECOND_INNING_AWAY_TEAM = "504",
    AWAITING_SUPER_OVER = "505",
    SUPER_OVER_HOME_TEAM = "506",
    SUPER_OVER_AWAY_TEAM = "507",
    AFTER_SUPER_OVER = "508",
    INNINGS_BREAK = "509",
    SUPER_OVER_BREAK = "510",
    LUNCH_BREAK = "511",
    TEA_BREAK = "512",
    STUMPS = "513",
    EIGHTH_SET = "514",
    NINETH_SET = "515",
    TENTH_SET = "516",
    ELEVENTH_SET = "517",
    TWELFTH_SET = "518",
    THIRTEENTH_SET = "519",
    THIRD_INNINGS_HOME_TEAM = "520",
    THIRD_INNINGS_AWAY_TEAM = "521",
    FOURTH_INNINGS_HOME_TEAM = "522",
    FOURTH_INNINGS_AWAY_TEAM = "523",
    DINNER_BREAK = "524",
    DRINKS = "525",
    SUPER_OVER = "526",

    LS_FIRST_HALF = "ls_10",
    LS_SECOND_HALF = "ls_20",
    LS_FIRST_EXTRA = "ls_30",
    LS_SECOND_EXTRA = "ls_35",

    LS_FIRST_QUARTER = "ls_1",
    LS_SECOND_QUARTER = "ls_2",
    LS_THIRD_QUARTER = "ls_3",
    LS_FOURTH_QUARTER = "ls_4",

    LS_FIRST_PERIOD = "ls_1",
    LS_SECOND_PERIOD = "ls_2",
    LS_THIRD_PERIOD = "ls_3",
}

export default StatusTypes;