import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash-es";
import GameData from "../../data/sport/GameData";
import { IGameData } from "../../../typings/sport-data/IGameData";
import { GameFields, GameTypes } from "@platform/stream-client";
import gameSortHelperWithoutFeatured from "../../utils/helpers/GameSortHelperWithoutFeatured";

export interface IPrematchCurrentTournamentState
{
    games: GameData[];
}

const initialState: IPrematchCurrentTournamentState = {
    games: null
};

export const prematchCurrentGameSlice = createSlice({
    name: "prematchCurrentGame",
    initialState,
    reducers: {
        setPrematchTournamentGames: (state, action) =>
        {
            state.games = action.payload;
        },
        updatePrematchTournamentGame: (state, action) =>
        {
            const game: IGameData = action.payload;
            const gameIndex = findIndex(state.games, { id: game._id });
            let gameData: GameData;

            if (game._new)
            {
                gameData = new GameData(game);

                if (game?.type === GameTypes.MATCH || game?.type === GameTypes.OUTRIGHT)
                {
                    gameData.isMarketSet = true;
                }

                state.games.push(gameData);
                state.games.sort(gameSortHelperWithoutFeatured);
            }
            else if (game._remove)
            {
                state.games.splice(gameIndex, 1);
            }
            else
            {
                if (gameIndex !== -1)
                {
                    gameData = state.games[gameIndex] as GameData;
                    gameData.update(game);

                    if (action.payload.hasOwnProperty(GameFields.FEATURED))
                    {
                        state.games.sort(gameSortHelperWithoutFeatured);
                    }
                }
            }
        }
    }
});

export const {
    setPrematchTournamentGames,
    updatePrematchTournamentGame
} = prematchCurrentGameSlice.actions;

export default prematchCurrentGameSlice.reducer;
