import { createSlice } from "@reduxjs/toolkit";
import { OddFormats } from "../../utils/static/OddFormats";

export interface ISportOptionsState
{
    token?: string;
    balance?: number;
    currency?: string;
    userId?: string;
    oddType?: OddFormats;
    videoEnabled?: boolean;
    isDesktop?: boolean;
}

const initialState: ISportOptionsState = {
    token: null,
    balance: null,
    currency: null,
    userId: null,
    oddType: null,
    videoEnabled: false,
    isDesktop: false
};

export const sportOptionsSlice = createSlice({
    name: "sportOptions",
    initialState,
    reducers: {
        setInitOptions: (state, action) =>
        {
            state.token = action.payload.token;
            state.balance = action.payload.balance;
            state.currency = action.payload.currency;
            state.userId = action.payload.userId;
            state.oddType = action.payload.oddType;
            state.isDesktop = action.payload.isDesktop;
        },
        changeBalance: (state, action) =>
        {
            state.balance = action.payload.balance;
            state.currency = action.payload.currency;
        },
        setUserData: (state, action) =>
        {
            state.token = action.payload.token;
            state.userId = action.payload.userId;

            if (action.payload.videoEnabled)
            {
                if (!state.videoEnabled)
                {
                    if (action.payload.token && SportWidgetOptions.externalSiteId === "2qwk5m")
                    {
                        state.videoEnabled = true;
                    }
                    else if (action.payload.token && ["IRT", "TND", "TRY"].indexOf(state.currency) !== -1)
                    {
                        state.videoEnabled = true;
                    }
                }
            }
        },
        changeToken: (state, action) =>
        {
            state.token = action.payload.token;
        },
        changeOddType: (state, action) =>
        {
            state.oddType = action.payload.oddType;
        },
        changeVideoEnabled: (state, action) =>
        {
            state.videoEnabled = action.payload;
        },
        changeDesktop: (state, action) =>
        {
            state.isDesktop = action.payload;
        }
    }
});

export const {
    setInitOptions,
    changeBalance,
    setUserData,
    changeToken,
    changeOddType,
    changeVideoEnabled,
    changeDesktop
} = sportOptionsSlice.actions;

export default sportOptionsSlice.reducer;
