const isActive = (element) =>
{
    let result = true;
    const root = document.querySelector(":root");

    Object.keys(element).map(style_variable =>
    {
        if (getComputedStyle(root).getPropertyValue("--" + style_variable).trim().toLowerCase() !== element[style_variable]?.trim().toLowerCase() && !style_variable.includes("Shadow"))
        {
            result = false;
        }
    });

    return result;
};

export default isActive;