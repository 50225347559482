/// <reference path="./typings/index.d.ts" />

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./ts/App";
import { BUILD_VERSION, IS_DEV } from "./ts/utils/constants/Config";

(window as any).sportWidgetlazyComponents = [];

(window as any).renderSport = () =>
{
    if (!window.SportWidgetOptions)
    {
        window.SportWidgetOptions = {};
    }

    SportWidgetOptions.init = true;
    SportWidgetOptions.buildVersion = BUILD_VERSION;

    if (!SportWidgetOptions.externalSiteId && SportWidgetOptions["siteId"])
    {
        SportWidgetOptions.externalSiteId = SportWidgetOptions["siteId"];
    }

    if (!SportWidgetOptions.userId && SportWidgetOptions["externalUserId"])
    {
        SportWidgetOptions.userId = SportWidgetOptions["externalUserId"];
    }

    if (SportWidgetOptions.navigationEnabled === undefined)
    {
        SportWidgetOptions.navigationEnabled = true;
    }

    if (IS_DEV)
    {
        SportWidgetOptions.desktop = true;
        SportWidgetOptions.externalSiteId = "0003";
        SportWidgetOptions.userId = "externalUserId";
        SportWidgetOptions.token = "token";
        SportWidgetOptions.balance = 1000;
        SportWidgetOptions.currency = "EUR";
        // SportWidgetOptions.source = "ls";
        // SportWidgetOptions.locale = "fa";
        // SportWidgetOptions.oddType = "decimal";
        // SportWidgetOptions.hideFooterBetSlip = true;
    }

    if (SportWidgetOptions.onLoadCompleteHandler)
    {
        console.log("onLoadCompleteHandler", new Date().getTime());
        SportWidgetOptions.onLoadCompleteHandler();
    }

///////////////////////////////////////////////////

    if (SportWidgetOptions?.serverUrl)
    {
        SportWidgetOptions.serverUrl = SportWidgetOptions?.serverUrl?.toString().trim();
    }

    if (SportWidgetOptions?.locale)
    {
        SportWidgetOptions.locale = SportWidgetOptions?.locale?.toString().trim();
    }

    if (SportWidgetOptions?.oddType)
    {
        SportWidgetOptions.oddType = SportWidgetOptions?.oddType?.toString().trim();
    }

    if (SportWidgetOptions?.token)
    {
        SportWidgetOptions.token = SportWidgetOptions?.token?.toString().trim();
    }

    if (SportWidgetOptions?.currency)
    {
        SportWidgetOptions.currency = SportWidgetOptions?.currency?.toString().trim();
    }

    if (SportWidgetOptions?.externalSiteId)
    {
        SportWidgetOptions.externalSiteId = SportWidgetOptions?.externalSiteId?.toString().trim();
    }

    if (SportWidgetOptions?.userId)
    {
        SportWidgetOptions.userId = SportWidgetOptions?.userId?.toString().trim();
    }

    if (SportWidgetOptions?.externalCssPath)
    {
        SportWidgetOptions.externalCssPath = SportWidgetOptions?.externalCssPath?.toString().trim();
    }

    if (SportWidgetOptions?.theme)
    {
        SportWidgetOptions.theme = SportWidgetOptions?.theme?.toString().trim();
    }

    if (SportWidgetOptions?.footerHeight)
    {
        SportWidgetOptions.footerHeight = parseInt(SportWidgetOptions?.footerHeight?.toString().trim());
    }
    else
    {
        SportWidgetOptions.footerHeight = 0;
    }

/////////////////////////////////////////////////////

    if (!SportWidgetOptions.externalSiteId)
    {
        throw new Error("siteId is required");
    }

    if (SportWidgetOptions.token && !SportWidgetOptions.userId)
    {
        throw new Error("userId is required");
    }

    if (SportWidgetOptions.userId && SportWidgetOptions.userId !== "unauthorized" && !SportWidgetOptions.token)
    {
        throw new Error("token is required");
    }

    if (SportWidgetOptions?.currency && SportWidgetOptions?.currency?.length > 5)
    {
        throw new Error("currency max length is 5");
    }

    const sportContainer = document.getElementById("sport-container");
    let mainContainer = sportContainer.getElementsByTagName("div")[0];

    if (!mainContainer)
    {
        mainContainer = sportContainer.appendChild(document.createElement("div"));
    }

    mainContainer.setAttribute("id", "sport-main-container");
    mainContainer.setAttribute("style", `font-family: ${SportWidgetOptions.locale}-font, default-font, Inter;`);

    const root = ReactDOM.createRoot(mainContainer);

    root.render(
        <>
            <App/>
            <div id="build-version" style={{ display: "none" }}>v: {BUILD_VERSION}</div>
            <div id="popup"/>
        </>
    );
};

(window as any).renderSport();




