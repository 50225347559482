import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash-es";
import TournamentData from "../../data/sport/TournamentData";
import itemIndexInOrderHelper from "../../utils/helpers/ItemIndexInOrderHelper";

interface ITodayCurrentSportState
{
    tournaments: TournamentData[];
}

const initialState: ITodayCurrentSportState = {
    tournaments: null
};

export const todayCurrentSportSlice = createSlice({
    name: "todayCurrentSport",
    initialState,
    reducers: {
        setTodaySportTournaments: (state, action) =>
        {
            state.tournaments = action.payload;
        },
        updateTodaySportTournament: (state, action) =>
        {
            const tournamentIndex = findIndex(state.tournaments, { id: action.payload.tournament.id });

            if (tournamentIndex === -1)
            {
                const tournamentData = new TournamentData(action.payload.tournament);
                tournamentData.sport = action.payload.sport;
                tournamentData.region = action.payload.region;
                tournamentData.gamesCount = action.payload.game;

                state.tournaments.splice(itemIndexInOrderHelper(tournamentData.order, state.tournaments), 0, tournamentData);
            }
            else
            {
                if (action.payload.game === 0)
                {
                    state.tournaments.splice(tournamentIndex, 1);
                }
                else
                {
                    state.tournaments[tournamentIndex].gamesCount = action.payload.game;
                }
            }
        }
    }
});

export const {
    setTodaySportTournaments,
    updateTodaySportTournament
} = todayCurrentSportSlice.actions;

export default todayCurrentSportSlice.reducer;