import { createSlice } from "@reduxjs/toolkit";
import GameData from "../../data/sport/GameData";

export interface ILiveCurrentGameState
{
    game: GameData;
    openDefault: boolean;
}

const initialState: ILiveCurrentGameState = {
    game: null,
    openDefault: false
};

export const liveCurrentGameSlice = createSlice({
    name: "liveCurrentGame",
    initialState,
    reducers: {
        setLiveGame: (state, action) =>
        {
            state.game = action.payload;
        },
        updateLiveGame: (state, action) =>
        {
            const gameData: GameData = state.game as GameData;
            gameData.update(action.payload);
        },
        setOpenDefault: (state, action) =>
        {
            state.openDefault = action.payload;
        }
    }
});

export const {
    setLiveGame, updateLiveGame, setOpenDefault
} = liveCurrentGameSlice.actions;

export default liveCurrentGameSlice.reducer;
