import { IEvent } from "./IEvent";

export class Event implements IEvent
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------
    private _type: string;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------
    constructor(type: string)
    {
        this._type = type;
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public getType()
    {
        return this._type;
    }

    public clone()
    {
        return new Event(this._type);
    }
}