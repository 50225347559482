import { immerable } from "immer";
import { IOddData, IOddProbability } from "../../../typings/sport-data/IOddData";
import MarketData from "./MarketData";
import { GameFeeds } from "@platform/stream-client";

class OddData
{
    // --------------------------------------------------------------------------
    //
    // Fields
    //
    // --------------------------------------------------------------------------

    [immerable] = true;

    private _id: string;
    private _alias: string;
    private _name: string;
    private _shortName: string;
    private _shortAlias: string;
    private _order: number;
    private _value: string;
    private _originId: string;
    private _uniqueId: string;
    private _marketId: string;
    private _marketAlias: string;
    private _probability: IOddProbability;
    private _specs: {};
    private _side: string;
    private _line: GameFeeds;
    private _isSp: boolean;

    // --------------------------------------------------------------------------
    //
    // Constructor
    //
    // --------------------------------------------------------------------------

    constructor(odd: IOddData, market: MarketData, feed: GameFeeds, source: string)
    {
        this._id = odd.id;
        this._alias = odd.alias;
        this._name = odd.name;
        this._shortName = odd.short_name;
        this._shortAlias = odd.short_alias;
        this._order = odd.order;
        this._value = odd.value;
        this._originId = odd.origin_id;
        this._specs = odd.specs;
        this._side = odd.side;
        this._line = market.line;
        this._probability = odd.probability;
        this._marketId = market.id;
        this._marketAlias = market.alias;
        this._uniqueId = `${source}/${feed}/${market.gameId}/${market.id}/${odd.id}`;
    }

    // --------------------------------------------------------------------------
    //
    // Public properties
    //
    // --------------------------------------------------------------------------

    public get id(): string
    {
        return this._id;
    }

    public set id(value: string)
    {
        this._id = value;
    }

    public get alias(): string
    {
        return this._alias;
    }

    public set alias(value: string)
    {
        this._alias = value;
    }

    public get name(): string
    {
        return this._name;
    }

    public set name(value: string)
    {
        this._name = value;
    }

    public get shortName(): string
    {
        return this._shortName;
    }

    public set shortName(value: string)
    {
        this._shortName = value;
    }

    public get shortAlias(): string
    {
        return this._shortAlias;
    }

    public set shortAlias(value: string)
    {
        this._shortAlias = value;
    }

    public get value(): string
    {
        return this._value;
    }

    public set value(value: string)
    {
        this._value = value;
    }

    public get order(): number
    {
        return this._order;
    }

    public set order(value: number)
    {
        this._order = value;
    }

    public get originId(): string
    {
        return this._originId;
    }

    public set originId(value: string)
    {
        this._originId = value;
    }

    public get uniqueId(): string
    {
        if (this._isSp)
        {
            return this._uniqueId + "/sp";
        }
        else
        {
            return this._uniqueId;
        }
    }

    public set uniqueId(value: string)
    {
        this._uniqueId = value;
    }

    public get marketId(): string
    {
        return this._marketId;
    }

    public set marketId(value: string)
    {
        this._marketId = value;
    }

    public get marketAlias(): string
    {
        return this._marketAlias;
    }

    public get specs(): {}
    {
        return this._specs;
    }

    public set specs(value: {})
    {
        this._specs = value;
    }

    public get side(): string
    {
        return this._side;
    }

    public set side(value: string)
    {
        this._side = value;
    }

    public get line(): GameFeeds
    {
        return this._line;
    }

    public set line(value: GameFeeds)
    {
        this._line = value;
    }

    public get probability(): IOddProbability
    {
        return this._probability;
    }

    public set probability(value: IOddProbability)
    {
        this._probability = value;
    }

    public get isSP(): boolean
    {
        return this._isSp;
    }

    public set isSP(value: boolean)
    {
        this._isSp = value;
    }

    // --------------------------------------------------------------------------
    //
    // Public methods
    //
    // --------------------------------------------------------------------------
    public update(odd: IOddData): void
    {
        for (const key in odd)
        {
            this[key] = odd[key];
        }
    }
}

export default OddData;